<form (ngSubmit)="Enregistrer(myForm.form.valid, myForm._directives)" #myForm="ngForm">
  <div class="card-head" *ngIf="!ModeAjout">
    <div class="row">
      <div class="col-9" style="margin-top: 8px;">
        <p *ngIf="statut_etat_affaireInit.real_id">
          {{"languages.statut.statutnContrat" | translate}} :
          <label class="label-card-info">
            {{statut_etat_affaireInit.real_id}}
          </label>
        </p>
        <p *ngIf="statut_etat_affaireInit.etat">
          {{"languages.statut.nomStatut" | translate}} :
          <label class="label-card-info">
            {{statut_etat_affaireInit.etat}}
          </label>
        </p>
        <p *ngIf="serviceData?.libele">
          {{"languages.listing.Service" | translate}}  :
          <label class="label-card-info">
            {{serviceData?.libele}}
          </label>
        </p>
      </div>
      <div class="col-3 text-left">
        <!-- <p> Ligne de produit :
          <label class="label-card-info">
             {{contratData?.type}}  
          </label>
        </p> -->
        <p *ngIf="statusP?.etat"> {{"languages.statut.statutParent" | translate}} :
          <label class="label-card-info">
            {{statusP?.etat}}
          </label>
        </p>
      </div>
    </div>
  </div>
  <div class="card-head text-center">
    <div class="row details-header">
      <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
        <div class="icon-position">
            <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
        </div>
    </button>
      <div class="row">
        <div class=" row details-spinner">
          <div class="col">
            <ng-container *ngIf="show_loading_add">
              <div class="col-12">
                <div class="spinner-style">
                  <mat-spinner [diameter]="30"></mat-spinner>
                </div>
              </div>

            </ng-container>
          </div>
          <button type="button" class="btn-load" (click)="clearForm()" >
            <i class="fa-solid fa-rotate-right"></i>
          </button>
        </div>
        <button class="btn-dt-save" type="submit" [disabled]="disableBtn || show_loading_add">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
          {{'languages.buttons.enregistrer' | translate}}
        </button>
      </div>
    </div>
  </div>
  <div class="card" style="margin-bottom: 5rem;">
    <mat-tab-group mat-align-tabs="center" style="z-index: 0;">
      <mat-tab label="Informations Statut">
        <div class="row mt-3">

          <div class="col-12">
            <span style="color: red;">( * ) {{"languages.affaire.champsOblig" | translate}}</span>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <mat-form-field >
                <mat-label>{{'languages.listing.Statut' | translate}}</mat-label>
                <input maxlength="25" [(ngModel)]="statut_etat_affaire.etat" matInput placeholder="Status" required #etat="ngModel"
                  name="etat">

              </mat-form-field>
              <div *ngIf="etat.invalid && (etat.dirty || etat.touched)" class="message-erreur">
                <div *ngIf="etat.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                  {{'languages.statut.obligNomStatut' | translate}}
                </div>
                <div *ngIf="etat.errors.maxlength">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  {{'languages.statut.obligNomStatut' | translate}}
                </div>
              </div>
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              
              <mat-form-field >
                <mat-label>{{'languages.campagne.Couleur' | translate}}</mat-label>
                <input matInput [ngxMatColorPicker]="picker" [(ngModel)]="colori" [formControl]="colorCtr"
                  placeholder="{{'languages.campagne.Couleur' | translate}}" required name="colorCtr">
                <ngx-mat-color-toggle matSuffix [for]="picker"></ngx-mat-color-toggle>
                <ngx-mat-color-picker #picker [color]="color"></ngx-mat-color-picker>
              </mat-form-field>

              <div *ngIf="colorCtr.invalid && (colorCtr.dirty || colorCtr.touched)" class="message-erreur">
                <div *ngIf="colorCtr.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                  {{'languages.statut.obligCouleur' | translate}}
                </div>
              </div>
            </div>
          </div>


          <div class="col-4">

            <div class="form-group">
              
              <mat-form-field >
                <mat-label>{{'languages.statut.couleurPolice' | translate}} </mat-label>
                <input matInput [ngxMatColorPicker]="pickerP" [(ngModel)]="colorpo" [formControl]="colorCtrP"
                  placeholder="{{'languages.statut.couleurPolice' | translate}}" required>
                <ngx-mat-color-toggle matSuffix [for]="pickerP"></ngx-mat-color-toggle>
                <ngx-mat-color-picker #pickerP [color]="colorP"></ngx-mat-color-picker>
              </mat-form-field>

              <div *ngIf="colorCtrP.invalid && (colorCtrP.dirty || colorCtrP.touched)" class="message-erreur">
                <div *ngIf="colorCtrP.errors.required">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  {{'languages.statut.obligCouleurPolice' | translate}}
                </div>
              </div>
            </div>
          </div>

        </div>

        <div ngModelGroup="controleService">
          <div class="row">

            <div class="col-4">
              <!--       <div class="form-group">
          <h3 class="titleN mb-2">Contrat <span style="color: red;"> *</span> </h3>
          <mat-spinner *ngIf="contrats.length==0" [diameter]="15"></mat-spinner>
          <ng-autocomplete #autoCdv [data]="contrats" placeholder="Contrat" (selected)='selectCdv($event)'
            [searchKeyword]="'libele'" [(ngModel)]="contratData" required #Contrat="ngModel" name="Contrat"
            [itemTemplate]="itemListContrat" [notFoundTemplate]="notFoundListeContrat">
          </ng-autocomplete>

          <ng-template #itemListContrat let-item>
            <a [innerHTML]="item.libele"></a>
          </ng-template>

          <ng-template #notFoundListeContrat let-notFound>
            <div [innerHTML]="notFound">
            </div>
          </ng-template>

          <div style="margin-top:1px;" *ngIf="Contrat.invalid && (Contrat.dirty || Contrat.touched) && show_Contrat_erreur=='' "
            class="message-erreur">
            <div *ngIf="Cycle.errors.required">
              <i class="fa-solid fa-triangle-exclamation"></i>
              Contrat est obligatoire!
            </div>
          </div>
          <div *ngIf="show_Contrat_erreur=='Type  est obligatoire!'" class="message-erreur">
            <div *ngIf="item.id==undefined">
              <i class="fa-solid fa-triangle-exclamation"></i>
              {{show_Contrat_erreur}}
            </div>
          </div>
        </div> -->



              <!-- <mat-form-field class="example-full-width">
                                        <input type="text" required matInput [(ngModel)]="contratData" placeholder="Cycle de vie" [formControl]="affecterCycleFormControl" name="workflow" [matAutocomplete]="autoWorkflowComm">
                                          <mat-autocomplete autoActiveFirstOption [displayWith]="displayCycleVieFn" #autoWorkflowComm="matAutocomplete">
                                              <mat-option *ngFor="let option of listCycleVieFilter | async" [value]="option">
                                                  {{option.libele}}
                                              </mat-option>
                                          </mat-autocomplete>
                                      </mat-form-field> -->

              <div class="form-group">
                <h3 class="titleN mb-2">{{'languages.opportunite.label_lifecycle' | translate}}  * </h3>
                <mat-spinner *ngIf="showWorkflow" [diameter]="15"></mat-spinner>

                <ng-autocomplete #autoContrat [data]="contrats" [searchKeyword]="'libele'"
                  [itemTemplate]="itemListContrat" [(ngModel)]="contratData" required #cycle_de_Vie="ngModel"
                  name="cycle_de_Vie" (selected)='selectContrat($event)' [notFoundTemplate]="notFoundListeContrat"
                  placeholder="{{'languages.opportunite.label_lifecycle' | translate}}">
                </ng-autocomplete>

                <ng-template #itemListContrat let-item>
                  <a [innerHTML]="item.libele"></a>
                </ng-template>

                <ng-template #notFoundListeContrat let-notFound>
                  <div [innerHTML]="'languages.affaire.noDataAvailable' | translate"></div>
                </ng-template>
                <div *ngIf="contratOb  && cycle_de_Vie.invalid" class="message-erreur" style="margin-top:3px;">
                  <div *ngIf="cycle_de_Vie.errors.required">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    {{'languages.statut.cycleOblig' | translate}} 
                  </div>
                </div>
                <div
                  *ngIf="!contratOb && cycle_de_Vie.invalid && (cycle_de_Vie.dirty || cycle_de_Vie.touched) && show_Contrat_erreur==''"
                  class="message-erreur" style="margin-top:3px;">
                  <div *ngIf="cycle_de_Vie.errors.required">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    {{'languages.statut.cycleOblig' | translate}} 
                  </div>
                </div>
                <div *ngIf="show_Contrat_erreur=='Contrat est obligatoire!'" style="margin-top:3px;"
                  class="message-erreur">
                  <div *ngIf="item.idt==undefined">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    {{show_Contrat_erreur}}
                  </div>
                </div>

              </div>


            </div>


            <div class="col-4">
              <div class="form-group">
                <p class="titleN mb-2">{{'languages.listing.Service' | translate}}  * </p>
                <mat-spinner *ngIf="showVisa" [diameter]="15"></mat-spinner>
                <ng-autocomplete #autoService class="example-full-width" [data]="service_cat" [(ngModel)]="serviceData"
                  [searchKeyword]="'libele'" [itemTemplate]="itemListService" [notFoundTemplate]="notFoundListeService"
                  required #Service="ngModel" (selected)='selectService($event)' name="Service" placeholder="{{'languages.listing.Service' | translate}}">
                </ng-autocomplete>

                <ng-template #itemListService let-item>
                  <a [innerHTML]="item.libele"></a>
                </ng-template>

                <ng-template #notFoundListeService let-notFound>
                  <div [innerHTML]="'languages.affaire.noDataAvailable' | translate">
                  </div>
                </ng-template>

                <div *ngIf="serviceOb  && Service.invalid" class="message-erreur" style="margin-top:3px;">
                  <div *ngIf="Service.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                    {{'languages.statut.serviceOblig' | translate}}  
                  </div>
                </div>
                <div style="margin-top:1px;"
                  *ngIf="!serviceOb && Service.invalid && (Service.dirty || Service.touched) && show_Service_erreur=='' "
                  class="message-erreur">
                  <div *ngIf="Service.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                    {{'languages.statut.serviceOblig' | translate}}  
                  </div>
                </div>
                <div *ngIf="show_Service_erreur=='Service est obligatoire!'" class="message-erreur">
                  <div *ngIf="item.id==undefined">
                    <i class="fa-solid fa-triangle-exclamation"></i>
                    {{show_Service_erreur}}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <mat-form-field >
                  <mat-label>{{'languages.statut.urlApiExterne' | translate}}</mat-label>
                  <input name="text_api" matInput placeholder="url" #text_api="ngModel" pattern="https?://.+"
                    [(ngModel)]="statut_etat_affaire.api">
                </mat-form-field>
                <div *ngIf="text_api.invalid && (text_api.dirty || text_api.touched)" class="message-erreur">
                  <div *ngIf="text_api.errors.pattern">
                    <i class="fa-solid fa-triangle-exclamation"></i>&nbsp;
                    {{'languages.statut.urlInvalide' | translate}} 
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-4">
              <div class="form-group">

                <p class="titleN mb-2">{{'languages.statut.Visa' | translate}} </p>
                <mat-spinner *ngIf="showVisa " [diameter]="15"></mat-spinner>

                <ng-autocomplete #autoVisa [data]="visa_cat" [searchKeyword]="'libele'" [(ngModel)]="visaData"
                  placeholder="{{'languages.statut.Visa' | translate}} " #Visa="ngModel" name="Visa" [itemTemplate]="itemListService"
                  [notFoundTemplate]="notFoundListeService">

                </ng-autocomplete>

                <ng-template #itemListService let-item>
                  <a [innerHTML]="item.libele"></a>
                </ng-template>

                <ng-template #notFoundListeService let-notFound>
                  <div [innerHTML]="'languages.affaire.noDataAvailable' | translate">
                  </div>
                </ng-template>

              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <p class="titleN mb-2">{{'languages.statut.depend' | translate}} </p>

                <mat-spinner *ngIf="showParent " [diameter]="15"></mat-spinner>
                <ng-autocomplete #autoDep [data]="depand_List" [searchKeyword]="'etat'" [(ngModel)]="depand"
                  placeholder="{{'languages.statut.depend' | translate}} " [itemTemplate]="itemListDep" [notFoundTemplate]="notFoundListeDep"
                  #Depend="ngModel" name="Depend" (click)='selectDepan(myForm)'>
                </ng-autocomplete>

                <ng-template #itemListDep let-item>
                  <a [innerHTML]="item.etat"></a>
                </ng-template>

                <ng-template #notFoundListeDep let-notFound>
                  <div [innerHTML]="'languages.affaire.noDataAvailable' | translate">
                  </div>
                </ng-template>

              </div>
            </div>

            <div class="col-4">
              <div class="form-group">
                <p class="titleN mb-2">{{'languages.statut.statutParent' | translate}}  </p>
                <mat-spinner *ngIf="showParent " [diameter]="15"></mat-spinner>
                <ng-autocomplete #autoStatus [data]="statusP_List" [searchKeyword]="'etat'" [(ngModel)]="statusP"
                  placeholder=" {{'languages.statut.statutParent' | translate}} " [itemTemplate]="itemListStatus" [notFoundTemplate]="notFoundListeStatus"
                  #Statut="ngModel" name="Statut" (click)='selectEtat(myForm)'>
                </ng-autocomplete>

                <ng-template #itemListStatus let-item>
                  <a [innerHTML]="item.etat"></a>
                </ng-template>

                <ng-template #notFoundListeStatus let-notFound>
                  <div [innerHTML]="'languages.affaire.noDataAvailable' | translate">
                  </div>
                </ng-template>

              </div>
            </div>

          </div>

        </div>
        <div class="row">

          <div class="col-4">
            <div class="form-group">

              <p class="titleN mb-2">{{'languages.listing.Classe' | translate}} * </p>
              <mat-spinner *ngIf="showClasse" [diameter]="15"></mat-spinner>
              <ng-autocomplete required #autoClasse [data]="classe_List" placeholder="{{'languages.listing.Classe' | translate}}" [(ngModel)]="classeData"
                [searchKeyword]="'libele'" [itemTemplate]="itemListClasse" [notFoundTemplate]="notFoundListeClasse"
                #Classe="ngModel" name="Classe">
              </ng-autocomplete>

              <ng-template #itemListClasse let-item>
                <a [innerHTML]="item.libele"></a>
              </ng-template>

              <ng-template #notFoundListeClasse let-notFound>
                <div [innerHTML]="'languages.affaire.noDataAvailable' | translate">
                </div>
              </ng-template>
              <div *ngIf=" Classe.invalid && (Classe.dirty || Classe.touched)" class="message-erreur"
                style="margin-top:3px;">
                <div *ngIf="Classe.errors.required">
<i class="fa-solid fa-triangle-exclamation"></i>
                   {{'languages.statut.classOblig' | translate}} 
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              
              <mat-form-field >
                <mat-label>{{'languages.statut.Explication' | translate}}</mat-label>
                <input name="Explication" matInput [(ngModel)]="statut_etat_affaire.explication"
                  placeholder="{{'languages.statut.Explication' | translate}}" #Explication="ngModel">
              </mat-form-field>

            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
             
              <mat-form-field >
                <mat-label>{{'languages.statut.messageConfirmation' | translate}}</mat-label>
                <input name="Message" #Message="ngModel" [(ngModel)]="statut_etat_affaire.msg_conf" matInput
                  placeholder=" {{'languages.statut.messageConfirmation' | translate}}">
              </mat-form-field>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="row card-body ng-star-inserted mainCard d-flex justify-content-center">
            <div class="col-12">
              <div>
                <div class="row">
                  <div class="card-doc-title ml-1" style="padding-bottom: 20px;">
<i class="fa-solid fa-object-ungroup mr-2 fa-lg"></i>
                      {{'languages.affaire.Motif' | translate}} 
                  </div>
                  <button type="button" class="add-btn-en btn-details-add" (click)="addMotif()">
                    <i class="fa fa-plus-circle fa-2x" aria-hidden="true"></i>

                  </button>
                </div>
                <mat-accordion *ngFor="let motif of List_motif; let index = index">
                  <mat-expansion-panel #panel [expanded]="false">
                    <mat-expansion-panel-header class="pt-1">
                      <mat-panel-title>


                        <p class="tabName">{{motif.libelle ? motif.libelle :('languages.statut.motifNum' | translate) + (index+1) }}</p>

                      </mat-panel-title>
                      <div class="col-2  d-flex justify-content-end" (click)="$event.stopPropagation();">
          
                       <label class="switch-m space-switch ml-3" *ngIf="motif.id">
                          <input type="checkbox" [checked]="motif.active == '1'" name="active"
                            (change)="isActive($event,index)" />
                          <span class="slider-m round"></span>
                        </label> 
                      
                        <button type="button" mat-icon-button (click)="deleteMotif(index)" *ngIf="!motif.id">
                          <i class="fa-solid fa-trash iconTrash"></i>
                          <!-- <img src="assets/icons/trash-icon-red.svg" > -->
                        </button>
                      </div>
                    </mat-expansion-panel-header>
                    <app-motif-apres-vente (valisFormMotif)="getValidFormvalue($event)"
                      (motifUpdated)="getMotif($event, index)" [motif]="motif"></app-motif-apres-vente>

                  </mat-expansion-panel>

                </mat-accordion>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>



      <mat-tab label="{{'languages.SigafConfig.rules' | translate}}">

        <div class="row mt-5" style="min-height: 400px;">  <!-- Increase min-height of the row -->
          <div class="col-6"> 
            <div class="form-group">
              <p class="titleN mb-2">{{'languages.SigafConfig.Validation_rules' | translate}}</p>
              <mat-spinner *ngIf="showPost" [diameter]="15"></mat-spinner>
              <ng-autocomplete #autoPost [data]="ListeValidationRules" placeholder="{{'languages.SigafConfig.Validation_rules' | translate}}" 
              [(ngModel)]="selectedValidationRuleId" [searchKeyword]="'label'" 
              [itemTemplate]="itemListPost" [notFoundTemplate]="notFoundListePost"
              (selected)="onValidationRuleSelected($event)"

              [ngModelOptions]="{standalone: true}">
              </ng-autocomplete>
              
            
            <ng-template #itemListPost let-item>
              <a [innerHTML]="item.label"></a>
            </ng-template>
            
            <ng-template #notFoundListePost let-notFound>
              <div [innerHTML]="'languages.affaire.noDataAvailable' | translate"></div>
            </ng-template>
             
            </div>
          </div>
      
          <div class="col-6">
            <div class="form-group">
              <p class="titleN mb-2">{{'languages.SigafConfig.Post_processing_rules' | translate}}</p>
              <ng-autocomplete #autoMail [data]="ListeProcessingRules" [(ngModel)]="selectedRuleIdProcessing" [searchKeyword]="'label'"
                [itemTemplate]="itemListEmail" #Mail="ngModel" name="Mail" placeholder="{{'languages.SigafConfig.Post_processing_rules' | translate}}"
                [notFoundTemplate]="notFoundListeEmail"
                (selected)="onProcessingRuleSelected($event)"

                >
              </ng-autocomplete>
      
              <ng-template #itemListEmail let-item>
                <a [innerHTML]="item.label"></a>
              </ng-template>
      
              <ng-template #notFoundListeEmail let-notFound>
                <div [innerHTML]="'languages.affaire.noDataAvailable' | translate"></div>
              </ng-template>
            </div>
          </div>
        </div>
      
      </mat-tab>
      




      <mat-tab label="{{'languages.statut.settings' | translate}}"   >


        <div class="row mt-5">
            <div class="col-6">
              <div class="form-group">
                <p class="titleN mb-2">{{'languages.statut.modelePost' | translate}} </p>
                <mat-spinner *ngIf="showPost" [diameter]="15"></mat-spinner>
                <ng-autocomplete #autoPost [data]="ModelPost" placeholder="{{'languages.statut.modelePost' | translate}}" [(ngModel)]="postData"
                  [searchKeyword]="'nom'" #Post="ngModel" name="Post" [itemTemplate]="itemListPost"
                  [notFoundTemplate]="notFoundListePost">
                </ng-autocomplete>

                <ng-template #itemListPost let-item>
                  <a [innerHTML]="item.nom"></a>
                </ng-template>

                <ng-template #notFoundListePost let-notFound>
                  <div [innerHTML]="'languages.affaire.noDataAvailable' | translate">
                  </div>
                </ng-template>

              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <p class="titleN mb-2">{{'languages.statut.modeleEmail' | translate}} </p>
                <mat-spinner *ngIf="showMail" [diameter]="15"></mat-spinner>
                <ng-autocomplete #autoMail [data]="ModelMail" [(ngModel)]="mailData" [searchKeyword]="'nom'"
                  [itemTemplate]="itemListEmail" #Mail="ngModel" name="Mail" placeholder="{{'languages.statut.modeleEmail' | translate}}"
                  [notFoundTemplate]="notFoundListeEmail">
                </ng-autocomplete>

                <ng-template #itemListEmail let-item>
                  <a [innerHTML]="item.nom"></a>
                </ng-template>

                <ng-template #notFoundListeEmail let-notFound>
                  <div [innerHTML]="'languages.affaire.noDataAvailable' | translate">
                  </div>
                </ng-template>

              </div>
            </div>

            <div class="col-6">
              <div class="form-group">

                <p class="titleN mb-2">{{'languages.statut.modeleSMS' | translate}} </p>
                <mat-spinner *ngIf="showSms" [diameter]="15"></mat-spinner>
                <ng-autocomplete #autoSMS [data]="ModelSms" [(ngModel)]="smsData" #Sms="ngModel" name="Sms"
                  [searchKeyword]="'nom'" [itemTemplate]="itemListSms" placeholder="{{'languages.statut.modeleSMS' | translate}}"
                  [notFoundTemplate]="notFoundListeSms">
                </ng-autocomplete>

                <ng-template #itemListSms let-item>
                  <a [innerHTML]="item.nom"></a>
                </ng-template>

                <ng-template #notFoundListeSms let-notFound>
                  <div [innerHTML]="'languages.affaire.noDataAvailable' | translate">
                  </div>
                </ng-template>

              </div>
            </div>

          </div>


          <div class="row">

            <div class="col-4" style="padding-top: 2%;">
              <div class="row">

                <div class="col-6">
                  <div class="form-group">
                    <div class="checkbox-container" style="font-size: 2em;margin-left: 6px;">
                      <input class="checkbox" name='sup' type="checkbox" id="contient" [(ngModel)]="sup">
                      <div class="checkbox-visual">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <mat-label>  {{'languages.statut.Sup' | translate}} </mat-label>
                </div>
              </div>
            </div>
            <div class="col-4" style="padding-top: 2%;">
              <div class="row">

                <div class="col-6">
                  <div class="form-group">
                    <div class="checkbox-container" style="font-size: 2em;margin-left: 6px;">
                      <input class="checkbox" name='agenda' type="checkbox" id="contient" [(ngModel)]="agenda">
                      <div class="checkbox-visual" style="margin-left: 15px">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <mat-label>  {{'languages.navbar.Agenda' | translate}} </mat-label>
                </div>
              </div>
            </div>

            <div class="col-4" style="padding-top: 2%;">
              <div class="row">

                <div class="col-6">
                  <div class="form-group">
                    <div class="checkbox-container" style="font-size: 2em;margin-left: 6px;">
                      <input class="checkbox" name='alerte' type="checkbox" id="contient" [(ngModel)]="alert">
                      <div class="checkbox-visual" style="margin-left: 15px">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <mat-label>  {{'languages.statut.Alerte' | translate}} </mat-label>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-4" style="padding-top: 2%;">
              <div class="row">

                <div class="col-6">
                  <div class="form-group">
                    <div class="checkbox-container" style="font-size: 2em;margin-left: 6px;">
                      <input class="checkbox" name='ticket' type="checkbox" id="contient" [(ngModel)]="ticket">
                      <div class="checkbox-visual" style="margin-left: 15px">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <mat-label>  {{'languages.ticket.ticket' | translate}} </mat-label>
                </div>
              </div>
            </div>
          </div>
      </mat-tab>
    </mat-tab-group>

  </div>

</form>
