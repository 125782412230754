import { NotificationsNavbarService } from './../../services/notifications-navbar.service';
import { Task } from './../../entity/task';

import { Component, OnInit } from '@angular/core';
 
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { ApiTagsService } from './../../services/api-tags.service';
import { ApiTasksService } from './../../services/ApiTasks/api-tasks.service';
import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import swal from 'sweetalert2';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
})
export class TasksComponent implements OnInit {
  loader: boolean = false;
  pageAffect: number = 1;
  limit: number = 9;
  limitAffect: number = 10;
  totalPagesAffect: number = 5;
  page = 1;
  totalPages: number = 5;
  loaderAffect: boolean = false;
  search_create: string = '';
  search_affect: string = '';
   
  taskDetail: Task = new Task();
  isExpand: boolean = false;
  isExpandAffect: boolean = false;
  isModif: boolean = false;
  typeTache: string = '';
  Tasks: any;
  ListCLientProspect: any = [];
  tagsList: any[] = [];
  Tasksaffect: any;
  priorites: any;
  ListUser: any = [];
  alerts: any;
  buttons: any;
  archiveCree: boolean;
  archiveAffecte: any;
  typeFolder: string;
  typeFolderCree: string ="ENCOURS";
  typeFolderAffecte: string  ="ENCOURS";
   inputSubject = new Subject<{value: string, type: string}>();
  loaderAjout: boolean;
  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiTagsServices: ApiTagsService,
    private apiTasksService: ApiTasksService,
    private apiUsersService: ApiUsersService,
    private apiTicketsServices: ApiTicketsService,
    private NotificationsNavbarService: NotificationsNavbarService,
    private notificationsService: NotificationsService,
    private translate : TranslateService
  ) {
    this.inputSubject.pipe(
      debounceTime(1000) // Adjust debounce time as needed
    ).subscribe((data: {value: string, type: string}) => {
      this.searchTache(data.value, data.type);
    });
    
  }

  ngOnInit(): void {


    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.sharedMenuObserverService.updateMenu(object.navbar.Tasks);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.sharedMenuObserverService.updateMenu(object.navbar.Tasks);
      });
  });

    this.apiTagsServices.GetListTags('A').subscribe(
      (data) => {
        this.tagsList = data;
      },
      (error) => {
        console.log(error);
      }
    );
    this.apiTasksService.list_prospects().subscribe((data) => {
      this.ListCLientProspect = data;
    });

    this.apiTasksService.list_priorite().subscribe((data) => {
      this.priorites = data.data.reverse();
    });

    this.apiUsersService.getAllUsers().subscribe((data) => {
      data.forEach((item) => {
        const personne = {
          id: item.id,
          full_name: item.nom + ' ' + item.prenom,
        };
        this.ListUser.push(personne);
      });
    });
    this.list_tachess('');
    
  }

  onInputChange(value: string , type): void {
    this.inputSubject.next({value, type});
  }

  searchTache(event, type) {
    if (event.length > 2 || event.length === 0) {
      if (type === 'crees') {
        this.page = 1;
        this.search_create = event;
       
        this.list_tachess('CREATED');
      } else {
        this.search_affect = event;
        
        this.pageAffect = 1;
        this.list_tachess('AFFECTED');
      }
    }
  }

  list_tachess(mode) {
    if (mode === 'CREATED') {
      this.loader = true;
this.getListCree(mode)
     
    } else if (mode === 'AFFECTED') {
      this.loaderAffect = true;

      this.getListAffect(mode)
    } else {
      this.loader = true;
      this.loaderAffect = true;
      this.getListCree('CREATED')
      this.getListAffect('AFFECTED')
    }
  }

getListCree(mode){
  this.apiTasksService
  .list_taches(
    this.search_create,
    this.page,
    this.limit,
    this.typeFolderCree,
    mode
  )
  .subscribe((data) => {
    this.Tasks = data.list_tache;
    this.calculate_nb_pages(data.nb_total);
    this.loader = false;
  });
}

  getListAffect(mode){
    this.apiTasksService
        .list_taches(
          this.search_affect,
          this.pageAffect,
          this.limitAffect,
          this.typeFolderAffecte,
          mode
        )
        .subscribe((data) => {
          this.Tasksaffect = data.list_tache;
          this.calculate_nb_pagesAffect(data.nb_total);
          this.loaderAffect = false;
        });
  }

  public expandTask() {
    this.isModif = false;
    if (this.isExpand === false) {
      this.isExpand = true;
      const box = document.getElementById('expand');
      box.classList.remove('col-md-12');
      box.classList.add('col-md-8');
      this.isExpandAffect = false;
      const box2 = document.getElementById('expand2');
      box2.classList.remove('col-md-8');
      box2.classList.add('col-md-12');
    }
    this.taskDetail = new Task();
    this.typeTache = '';
  }



  
  archiveCreeFN(value) {
    this.archiveCree = value;
    this.search_create=''
    this.page=1
     if (value===false){
      this.typeFolderCree = "ENCOURS";
    }else{
      this.typeFolderCree = "ARCHIVE";
    }
    this.list_tachess('CREATED');
  }
  
  archiveAffecteFN(value){
    this.archiveAffecte = value;
    this.search_affect=''
    this.pageAffect=1
    if (value===false){
      this.typeFolderAffecte = "ENCOURS";
    }else{
      this.typeFolderAffecte = "ARCHIVE";
    }
    this.list_tachess('AFFECTED');
  }

  changerStatus(tache ,type) {
    if (tache.statut === 0) {
      tache.statut = 1;
    } else {
      tache.statut = 0;
    }
    if (!tache.entity) {
      tache.entity = '';
      tache.id_entity = '';
    }

    this.apiTicketsServices.updateTicketNotificationV2(tache.id_entity, tache.id, tache.statut, tache.entity).subscribe(
      (data) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = this.alerts.Successoperation;
        notificationMessage.message = data.message;
        this.notificationsService.sendMessage(notificationMessage);
        if (type==="cree"){
          this.list_tachess('CREATED');
        }else{
          this.list_tachess('AFFECTED');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  expandFileName(name: string): string {
    if (name.length < 20) {
      return name;
    } else {
      return name.substring(0, 19) + '...';
    }
  }

  viewModif(tache: Task, type) {
    if (type === 'cree') {
      this.isModif = true;
      this.isExpand = true;
      this.isExpandAffect = false;
      const box = document.getElementById('expand');
      box.classList.remove('col-md-12');
      box.classList.add('col-md-8');
      this.taskDetail = tache;
      this.typeTache = type;
      const box2 = document.getElementById('expand2');
      box2.classList.remove('col-md-8');
      box2.classList.add('col-md-12');
    } else {
      this.isModif = true;
      this.isExpand = false;
      this.isExpandAffect = true;
      const box2 = document.getElementById('expand2');
      box2.classList.remove('col-md-12');
      box2.classList.add('col-md-8');
      this.taskDetail = tache;
      this.typeTache = type;
      const box = document.getElementById('expand');
      box.classList.remove('col-md-8');
      box.classList.add('col-md-12');
    }
  }

  ajoutTaches(taskk) {
    this.loaderAjout=true
    if (!this.isModif) {
      this.apiTicketsServices.saveNotification(taskk).subscribe(
        (response) => {
     
            this.alertSuccess(response.message);
       
          
          this.isExpand = false;
          this.loaderAjout=false
          const box = document.getElementById('expand');
          box.classList.remove('col-md-8');
          box.classList.add('col-md-12');
          this.isExpandAffect = false;
          const box2 = document.getElementById('expand2');
          box2.classList.remove('col-md-8');
          box2.classList.add('col-md-12');
          this.NotificationsNavbarService.triggerNotificationUpdate();
        },
        (error) => { 
          this.alertError(error.message);
          this.loaderAjout=false
        }
      );
    } else {
      this.apiTasksService.EditTasks(taskk).subscribe(
        (response) => {
          if (response.error === false) {
          
              this.alertSuccess(response.message);
          
            this.isExpand = false;
            this.loaderAjout=false
            const box = document.getElementById('expand');
            box.classList.remove('col-md-8');
            box.classList.add('col-md-12');
            this.isExpandAffect = false;
            const box2 = document.getElementById('expand2');
            box2.classList.remove('col-md-8');
            box2.classList.add('col-md-12');
            this.NotificationsNavbarService.triggerNotificationUpdate();
          } else {
            this.alertError(this.alerts.errorUpdateTask);
          }
        },
        (err) => {
          console.log(err);
          this.loaderAjout=false
          this.alertError(this.alerts.errorUpdateTask);
        }
      );
    }
  }

  cancelAddOrUpdate(inexpand): void {
    if (inexpand === false) {
      this.isExpand = false;
      this.isExpandAffect = false;
      const box = document.getElementById('expand');
      box.classList.remove('col-md-8');
      box.classList.add('col-md-12');
      const box2 = document.getElementById('expand2');
      box2.classList.remove('col-md-8');
      box2.classList.add('col-md-12');
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  setPage(val: number) {
    this.page = val;

    this.list_tachess('CREATED');
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  changepage(number: number) {
    this.page = number;
    this.list_tachess('CREATED');
  }

  firstAffect() {
    this.setPageAffect(1);
  }

  prevAffect() {
    this.setPageAffect(Math.max(1, this.pageAffect - 1));
  }

  nextAffect() {
    this.setPageAffect(Math.min(this.totalPagesAffect, this.pageAffect + 1));
  }

  lastAffect() {
    this.setPageAffect(this.totalPagesAffect);
  }

  calculate_nb_pagesAffect(iTotalDisplayRecords) {
    this.totalPagesAffect = Math.floor(iTotalDisplayRecords / this.limitAffect);
    if (iTotalDisplayRecords % this.limitAffect !== 0) {
      this.totalPagesAffect += 1;
    }
  }

  setPageAffect(val: number) {
    this.pageAffect = val;
    this.list_tachess('AFFECTED');
  }

  isFirstAffect(): boolean {
    return this.pageAffect === 1;
  }

  isLastAffect(): boolean {
    return this.pageAffect === this.totalPagesAffect;
  }

  changepageAffect(number: number) {
    this.pageAffect = number;
    this.list_tachess('AFFECTED');
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      icon: 'warning',
      text: this.alerts.affectTaskToSomeone,
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e75e5e',
    });
  }

  alertSuccess(data) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonText: this.buttons.fermer,
        confirmButtonColor: '#68a45b',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.list_tachess('');
        
        }
      });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: this.alerts.badHappened,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
}
