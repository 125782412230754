<div id="loadingBar">
  <div class="d-flex justify-content-center">
    <div class="row img-logo">
      <div class="col-12 logo-full">
        <img class="image-style" src="/assets/img/sigaf/logo-sigaf-blue.png" alt="">
      </div>
      <div class="col-12">
        <div class="spinner-border" role="status" >
          <span class="sr-only" id="loading"></span>
        </div>
      </div>
    </div>
  </div>
</div>
