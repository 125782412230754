import { Component, OnInit, ChangeDetectorRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { StatusAutorise } from 'src/app/entity/Opportunity/StatusAutorise';
@Component({
  selector: 'app-assign-modal',
  template: `
    <!-- Begin Modal -->
    <div class="modal-content" style="width: 60rem; margin-left: -135px;">
      <!-- Modal Header -->
      <div class="modal-header">
        <h2 class="text-center title justify-content-center m-0 w-100" id="exampleModalLabel">
          {{ 'languages.affaire.affecterAqui' | translate }}
        </h2>
        <button type="button" class="close" aria-label="Close" (click)="modalRef.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="modal-body">
        <div class="container-modal-elements">
          <!-- Form Fields -->
          <div class="d-flex justify-content-center">
            <div class="col-12">
              <div class="form-group text-center">
                <mat-form-field class="w-50" appearance="standard">
                  <mat-label>{{ 'languages.opportunite.details.affecte' | translate }}</mat-label>
                  <input
                    type="text"
                    matInput
                    [formControl]="userAffect"
                    [matAutocomplete]="autoStatus"
                    placeholder="Chercher..."
                    name="status"
                  />
                  <mat-autocomplete
                    (optionSelected)="AffectUser($event.option.value)"
                    [displayWith]="displayFnUser"
                    autoActiveFirstOption
                    #autoStatus="matAutocomplete"
                  >
                    <mat-option *ngFor="let option of listUserFilter | async" [value]="option">
                      {{ option.nom + ' ' + option.prenom }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
            </div>
          </div>

          <!-- Buttons "Enregistrer" and "Annuler" -->
          <div class="row justify-content-center mt-4">
            <button type="button" class="btn-dt-save-popup mr-4" (click)="assignUser()">
              <i *ngIf="!loader" class="fa-solid fa-circle-check fa-lg fa-icon-style"></i>
              <span *ngIf="!loader">Enregistrer</span>
              <div *ngIf="loader" class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </button>

            <button type="button" class="btn-dt-cancel-popup" (click)="onCancel()">
              <i class="fa-solid fa-circle-xmark fa-icon-style"></i>
              Annuler
            </button>
          </div>

          <!-- Blue Div with "Affecter à moi" Button -->
          <div class="row justify-content-center mt-5">
            <div class="affecter-moi-container w-75" style="background-color: #ECF6FF">
              <button type="button" class="btn-dt-send" (click)="onAffecterUser()">
                <i *ngIf="!loader" class="fa-duotone fa-solid fa-square-user mr-2"></i>
                <span *ngIf="!loader">{{ 'languages.affaire.maffecter' | translate }}</span>
                <div *ngIf="loader" class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- End Modal -->
  `,
  styleUrls: ['./AffecterModal.css'],
})
export class AssignModalComponent implements OnInit {
  userAffect: FormControl = new FormControl();
  listUserFilter: Observable<any[]>;
  liste_userAffect: any[] = [];
  idAffectUser: string;
  selectedUser: any;
  isProcessingAction = false; // Add this property

  @Input() idUserAffected: string;
  @Input() affaire_id: string;
  @Input() status_id: string;

  loader = false;

  constructor(
    public modalRef: NgbActiveModal,
    private apiAdminBpmServices: ApiAdminBpmService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.idUserAffected) {
      this.fetchListUserAffected();
    } else {
      console.error('idUserAffected is not set in AssignModalComponent');
    }
  }

  fetchListUserAffected() {
    this.apiAdminBpmServices.getListUserAffected(this.idUserAffected).subscribe(
      (response) => {
        this.liste_userAffect = response;
        this.setupAutocomplete();
      },
      (error) => {
        console.error('Error fetching list of users:', error);
      }
    );
  }

  onCancel(): void {
    this.modalRef.dismiss('Cancel click');
  }

  setupAutocomplete() {
    this.listUserFilter = this.userAffect.valueChanges.pipe(
      startWith(''),
      map((value: any) => (typeof value === 'string' ? value : value.nom)),
      map((value) => this._filterStatus(value || ''))
    );
    this.changeDetector.detectChanges();
  }

  private _filterStatus(value: string): any[] {
    const filterValue = value.toLowerCase();
    if (!this.liste_userAffect || this.liste_userAffect.length === 0) {
      return [];
    }
    return this.liste_userAffect.filter(
      (user) =>
        (user.nom && user.nom.toLowerCase().includes(filterValue)) ||
        (user.prenom && user.prenom.toLowerCase().includes(filterValue))
    );
  }

  AffectUser(user: any) {
    if (user && user.id) {
      this.selectedUser = user;
      this.idAffectUser = user.id;
      this.userAffect.setValue(user);
    } else {
      console.error('Invalid user object.');
    }
  }

  displayFnUser(user): string {
    return user && user.nom ? `${user.nom} ${user.prenom}` : '';
  }

  getLoggedInUser(): any {
    const connectedUser = localStorage.getItem('id_user');
    if (connectedUser) {
      return connectedUser;
    }
    return null;
  }

  onAffecterUser() {
    const user = this.getLoggedInUser();
    if (user) {
      this.idAffectUser = user;
      if (this.affaire_id) {
        this.assignUserApi(this.affaire_id, this.idAffectUser);
      } else {
        console.error('No affaire ID provided.');
      }
    } else {
      console.error('No user is currently logged in.');
    }
  }

  assignUser() {
    if (this.idAffectUser && this.affaire_id) {
      this.assignUserApi(this.affaire_id, this.idAffectUser);
    } else {
      console.error('No user selected or no affaire ID provided.');
    }
  }

  assignUserApi(affaireId: string, userId: string) {
    if (userId && affaireId && this.status_id) {
      this.loader = true;
      this.isProcessingAction = true; 

      this.apiAdminBpmServices.updateAffaireAffected(userId, affaireId).subscribe(
        (response) => {
          this.loader = false;
          this.isProcessingAction = false;  
          this.modalRef.close('Save click');
        },
        (error) => {
          console.error('Error affecting affair:', error);
          this.loader = false;
          this.isProcessingAction = false;  
        }
      );
    } else {
      this.loader = false;
      console.error('Invalid userId, affaireId, or status_id:', userId, affaireId, this.status_id);
    }
  }
}
