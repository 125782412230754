<div>
    <div id="login" fxLayout="column">

      <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form">

          <div class="logo">
            <img class="logo" src="assets/img/sigaf/logo-sigaf-blue.png">
          </div>

          <div class="title"> {{'languages.user.enterAdress' | translate}}</div>
          <form name="resetForm" [formGroup]="resetForm" (ngSubmit)="reset()" novalidate>

            <mat-form-field appearance="outline">
              <mat-label> {{'languages.prospect.email' | translate}}</mat-label>
              <input matInput formControlName="email">
              <mat-icon matSuffix class="secondary-text">mail</mat-icon>
              <mat-error *ngIf="resetForm.get('email').hasError('required')">
                 {{'languages.user.emailRequis' | translate}}
              </mat-error>
              <mat-error
                *ngIf="!resetForm.get('email').hasError('required') &&
                                  resetForm.get('email').hasError('email')">
                 {{'languages.user.validAdress' | translate}}
              </mat-error>
            </mat-form-field>


            <button [color]="colors.primary" mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                    [disabled]="resetForm.invalid">
               {{'languages.buttons.changer' | translate}}
            </button>


            <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                 fxLayoutAlign="space-between center">

              <a class="forgot-password" [routerLink]="'/login'">
                 {{'languages.buttons.CONNEXION' | translate}}
              </a>
            </div>

            
            <div class="text-center">
              <ng-container *ngIf="show_loading_add">
                <div class="col-12 loader-spinner">
                  <div  class="spinner-style">
                    <mat-spinner [diameter]="30"></mat-spinner>
                  </div>
                </div>
              </ng-container>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col" style="position: fixed; bottom: 0px;background: white;">
      <app-footer></app-footer>
    </div>
  </div>

