import { Component, OnInit, Output, EventEmitter, HostListener, OnDestroy, Input, ViewChild } from '@angular/core';
import { ApiAdminBpmService } from './../../../../services/ApiAdminBpm/api-admin-bpm.service';
/* eslint-disable dot-notation */
import { InformationsComplmentaire } from './../../../../entity/Opportunity/InformationsComplmentaire';
import { InformationsSpecifique } from './../../../../entity/Opportunity/InformationsSpecifique';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AffaireDetails } from 'src/app/entity/Affaires/AffaireDetails';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiOpportunitService } from './../../../../services/ApiOpportunite/api-opportunite.service';
import swal from 'sweetalert2';

import { of, Subscription } from 'rxjs';
import { SurveyModel, StylesManager, QuestionPanelDynamicModel } from 'survey-core';
import * as moment from 'moment';
import mime from 'mime';
@Component({
  selector: 'app-validation-provisoire',
  templateUrl: './validation-provisoire.component.html',
  styleUrls: ['./validation-provisoire.component.css'],
})
export class ValidationProvisoireComponent implements OnInit, OnDestroy {
  form = new FormGroup({});
  surveyModel: SurveyModel;

  modelspecif: any = {};
  modelcomp: any = {};
  ListeofUnsbscribeservice: Subscription[] = [];
  options: FormlyFormOptions = {};
  fieldscomp = [];
  fieldsspecif = [];
  formdatacomp = new FormGroup({});
  formdataspecif = new FormGroup({});

  formComplementaire = new FormGroup({});
  formSpecifique = new FormGroup({});
  loading: boolean = false;
  @Output() formLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() informationsContrat: AffaireDetails;
  @Input() affaire_id: string = '';
  @Input() id_opportunite: string = '';
  infoComplementaire: InformationsComplmentaire;
  infoSpecifique: InformationsSpecifique;
  @Output() infoSpecifiqueexport: EventEmitter<any> = new EventEmitter();
  @Output() infoComplementaireexport: EventEmitter<any> = new EventEmitter();
  @Input() allowed_to_update_affaire: boolean;
  @Output() dataForm: EventEmitter<any> = new EventEmitter();
  @Output() allowUpdateStatus: EventEmitter<any> = new EventEmitter();
  suveyInfo: any;
  specifique = false;
  complementaire = false;
  schemaFormUpdated: boolean = false;
  nationalities: any[] = [];
  goverments: any[] = [];
  delegation: any[] = [];
  secteurs: any[] = [];
  Soussecteurs: any[] = [];
  limit = 15;
  page = 1;
  search: string = '';
  totalLength: number = 0;
  diplome_specialite: any[] = [];
  totalPages = 0;

  nationaliteFormControl = new FormControl();

  themeJson: any = {
    themeName: 'default',
    colorPalette: 'light',
    isPanelless: false,
    backgroundImage: '',
    backgroundOpacity: 1,
    backgroundImageAttachment: 'scroll',
    backgroundImageFit: 'cover',
    cssVariables: {
      '--sjs-corner-radius': '4px',
      '--sjs-base-unit': '8px',
      '--sjs-shadow-small': '0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
      '--sjs-font-questiontitle-weight': '400',
      '--sjs-font-questiontitle-size': '15px',
      '--sjs-font-questiondescription-size': '18px',
      '--sjs-shadow-inner': 'inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
      '--sjs-border-default': 'rgba(0, 0, 0, 0.16)',
      '--sjs-border-light': 'rgba(0, 0, 0, 0.09)',
      '--sjs-general-backcolor': 'rgba(255, 255, 255, 1)',
      '--sjs-general-backcolor-dark': 'rgba(248, 248, 248, 1)',
      '--sjs-general-backcolor-dim-light': 'rgba(249, 249, 249, 1)',
      '--sjs-general-backcolor-dim-dark': 'rgba(243, 243, 243, 1)',
      '--sjs-general-forecolor': 'rgba(0, 0, 0, 0.91)',
      '--sjs-general-forecolor-light': 'rgba(0, 0, 0, 0.45)',
      '--sjs-general-dim-forecolor': 'rgba(0, 0, 0, 0.91)',
      '--sjs-general-dim-forecolor-light': 'rgba(0, 0, 0, 0.45)',
      '--sjs-secondary-backcolor': 'rgba(255, 152, 20, 1)',
      '--sjs-secondary-backcolor-light': 'rgba(255, 152, 20, 0.1)',
      '--sjs-secondary-backcolor-semi-light': 'rgba(255, 152, 20, 0.25)',
      '--sjs-secondary-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-secondary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
      '--sjs-shadow-small-reset': '0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
      '--sjs-shadow-medium': '0px 2px 6px 0px rgba(0, 0, 0, 0.1)',
      '--sjs-shadow-large': '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
      '--sjs-shadow-inner-reset': 'inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)',
      '--sjs-border-inside': 'rgba(0, 0, 0, 0.16)',
      '--sjs-special-red-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-special-green': 'rgba(25, 179, 148, 1)',
      '--sjs-special-green-light': 'rgba(25, 179, 148, 0.1)',
      '--sjs-special-green-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-special-blue': 'rgba(67, 127, 217, 1)',
      '--sjs-special-blue-light': 'rgba(67, 127, 217, 0.1)',
      '--sjs-special-blue-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-special-yellow': 'rgba(255, 152, 20, 1)',
      '--sjs-special-yellow-light': 'rgba(255, 152, 20, 0.1)',
      '--sjs-special-yellow-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-article-font-xx-large-textDecoration': 'none',
      '--sjs-article-font-xx-large-fontWeight': '700',
      '--sjs-article-font-xx-large-fontStyle': 'normal',
      '--sjs-article-font-xx-large-fontStretch': 'normal',
      '--sjs-article-font-xx-large-letterSpacing': '0',
      '--sjs-article-font-xx-large-lineHeight': '64px',
      '--sjs-article-font-xx-large-paragraphIndent': '0px',
      '--sjs-article-font-xx-large-textCase': 'none',
      '--sjs-article-font-x-large-textDecoration': 'none',
      '--sjs-article-font-x-large-fontWeight': '700',
      '--sjs-article-font-x-large-fontStyle': 'normal',
      '--sjs-article-font-x-large-fontStretch': 'normal',
      '--sjs-article-font-x-large-letterSpacing': '0',
      '--sjs-article-font-x-large-lineHeight': '56px',
      '--sjs-article-font-x-large-paragraphIndent': '0px',
      '--sjs-article-font-x-large-textCase': 'none',
      '--sjs-article-font-large-textDecoration': 'none',
      '--sjs-article-font-large-fontWeight': '700',
      '--sjs-article-font-large-fontStyle': 'normal',
      '--sjs-article-font-large-fontStretch': 'normal',
      '--sjs-article-font-large-letterSpacing': '0',
      '--sjs-article-font-large-lineHeight': '40px',
      '--sjs-article-font-large-paragraphIndent': '0px',
      '--sjs-article-font-large-textCase': 'none',
      '--sjs-article-font-medium-textDecoration': 'none',
      '--sjs-article-font-medium-fontWeight': '700',
      '--sjs-article-font-medium-fontStyle': 'normal',
      '--sjs-article-font-medium-fontStretch': 'normal',
      '--sjs-article-font-medium-letterSpacing': '0',
      '--sjs-article-font-medium-lineHeight': '32px',
      '--sjs-article-font-medium-paragraphIndent': '0px',
      '--sjs-article-font-medium-textCase': 'none',
      '--sjs-article-font-default-textDecoration': 'none',
      '--sjs-article-font-default-fontWeight': '400',
      '--sjs-article-font-default-fontStyle': 'normal',
      '--sjs-article-font-default-fontStretch': 'normal',
      '--sjs-article-font-default-letterSpacing': '0',
      '--sjs-article-font-default-lineHeight': '28px',
      '--sjs-article-font-default-paragraphIndent': '0px',
      '--sjs-article-font-default-textCase': 'none',
      '--sjs-general-backcolor-dim': 'rgba(0, 0, 255, 0)',
      '--sjs-primary-backcolor': '#21A8A8',
      '--sjs-primary-backcolor-dark': 'rgba(30, 153, 153, 1)',
      '--sjs-primary-backcolor-light': 'rgba(33, 168, 168, 0.1)',
      '--sjs-primary-forecolor': 'rgba(255, 255, 255, 1)',
      '--sjs-primary-forecolor-light': 'rgba(255, 255, 255, 0.25)',
      '--sjs-special-red': 'rgba(229, 10, 62, 1)',
      '--sjs-special-red-light': 'rgba(229, 10, 62, 0.1)',
    },
    headerView: 'basic',
  };

  @Output() onUpdateinformationsSpecifique: EventEmitter<InformationsSpecifique> = new EventEmitter();
  @Output() emitForminformationsSpecifique: EventEmitter<any> = new EventEmitter();
  addedQuestions: any;

  constructor(
    private apiOpportunitService: ApiOpportunitService,
    private apiAffairesService: ApiAffairesService,
    private formlyJsonschema: FormlyJsonschema,
    private ApiAdminbpmService: ApiAdminBpmService
  ) {}

  ngOnInit(): void {
    this.getAllListSousSecteur();
    this.getNationalities();
    this.getAllListGouvernorats();
    this.getAllListDelegation();
    this.getAllListSecteur();
    this.listDiplomeSpecialite();

    this.getInformationsSpecifique();
  }

  getAllListDelegation(): void {
    this.ApiAdminbpmService.getAllListDelegation()
      .pipe(
        catchError((error) => {
          console.error('Error fetching delegations:', error);
          return of([]);
        })
      )
      .subscribe((response) => {
        if (Array.isArray(response)) {
          this.delegation = response.map((res: any) => ({
            text: res.name,
            value: res.value,
          }));
        } else if (Array.isArray(response.data)) {
          this.delegation = response.data.map((res: any) => ({
            text: res.name,
            value: res.value,
          }));
        } else {
          console.error('Unexpected response structure:', response);
          this.delegation = [];
        }
      });
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  listDiplomeSpecialite() {
    this.page = 1;
    this.limit = 10000;

    this.ApiAdminbpmService.getListDiplomeSpecialite(this.page, this.limit, this.search)
      .pipe(
        tap((response) => console.log('API Response:', response)),
        map((response: any) => {
          if (!Array.isArray(response.data)) {
            throw new Error('Unexpected API response format');
          }
          return response.data.map((res: any) => ({
            text: res.diplome,
            value: res.specialite,
          }));
        }),
        catchError((error) => {
          console.error('Error fetching diplome_specialite:', error);
          this.alertError('Failed to load data');
          return of([]);
        })
      )
      .subscribe((mappedData) => {
        this.diplome_specialite = mappedData;
        this.totalLength = mappedData.length;
        this.calculate_nb_pages(this.totalLength);
      });
  }

  getAllListSecteur(): void {
    this.ApiAdminbpmService.getAllListSecteur().subscribe(
      (response) => {
        this.secteurs = response.data.map((res: any) => ({
          text: res.name,
          value: res.value,
        }));
      },
      (error) => {
        console.error('Error fetching sectors:', error);
      }
    );
  }

  getAllListSousSecteur(): void {
    this.ApiAdminbpmService.getAllListSousSecteur().subscribe(
      (response) => {
        this.Soussecteurs = response.data.map((res: any) => ({
          text: res.name,
          value: res.value,
        }));
      },
      (error) => {
        console.error('Error fetching subsectors:', error);
      }
    );
  }

  getAllListGouvernorats(): void {
    this.ApiAdminbpmService.getAllListGouvernorats()
      .pipe(
        catchError((error) => {
          console.error('Error fetching goverments:', error);
          return of([]);
        })
      )
      .subscribe((response) => {
        if (Array.isArray(response)) {
          this.goverments = response.map((res: any) => ({
            text: res.name,
            value: res.value,
          }));
        } else if (Array.isArray(response.data)) {
          this.goverments = response.data.map((res: any) => ({
            text: res.name,
            value: res.value,
          }));
        } else {
          console.error('Unexpected response structure:', response);
          this.goverments = [];
        }
      });
  }

  getNationalities(): void {
    this.ApiAdminbpmService.getAllNationalite()
      .pipe(
        catchError((error) => {
          console.error('Error fetching nationalities:', error);
          return of([]);
        })
      )
      .subscribe((response) => {
        if (Array.isArray(response)) {
          this.nationalities = response.map((res: any) => ({
            text: res.name,
            value: res.value,
          }));
        } else if (Array.isArray(response.data)) {
          this.nationalities = response.data.map((res: any) => ({
            text: res.name,
            value: res.value,
          }));
        } else {
          console.error('Unexpected response structure:', response);
          this.nationalities = [];
        }
      });
  }

  getInformationsSpecifique() {
    this.loading = true;
    if (this.affaire_id !== '' && this.affaire_id !== null && this.apiOpportunitService.formdataaffairspecif === null) {
      this.apiAffairesService
        .getInfoSpecifiqueAffaireGet(this.affaire_id)
        .pipe(
          map((data: any) => {
            if (!data?.response) {
              throw new Error('Invalid response data');
            }

            // Integrate dynamic panel and last page data transformation
            const currentData = {
              ...data.response.data,
            };

            if (Array.isArray(data.response.key_urls)) {
              data.response.key_urls.forEach((item) => {
                const fileType = mime.getType(item.name_doc) || 'application/octet-stream';

                const key = item.key; // e.g., 'trainer_data[0].copie_cin' or 'some_other_key'
                const dynamicPanelMatch = key.match(/^(.*?)\[(\d+)\]\.(.*)$/);
                if (dynamicPanelMatch) {
                  const panelName = dynamicPanelMatch[1]; // e.g., 'trainer_data'
                  const panelIndex = parseInt(dynamicPanelMatch[2], 10);
                  const fieldName = dynamicPanelMatch[3]; // e.g., 'copie_cin'
                  if (!currentData[panelName]) {
                    currentData[panelName] = [];
                  }
                  if (!currentData[panelName][panelIndex]) {
                    currentData[panelName][panelIndex] = {};
                  }
                  currentData[panelName][panelIndex][fieldName] = [
                    {
                      content: item.url,
                      name: item.name_doc,
                      type: fileType,
                    },
                  ];
                } else {
                  currentData[key] = [
                    {
                      content: item.url,
                      name: item.name_doc,
                      type: fileType,
                    },
                  ];
                }
              });
            }

            data.response.data = currentData;
            return data;
          }),
          catchError((error) => {
            console.error('Error in getInformationsSpecifique:', error);
            this.loading = false;
            this.formLoaded.emit(true);
            return of(null);
          })
        )
        .subscribe(
          (response) => {
            if (response) {
              this.suveyInfo = response.response;
              this.surveyJsInit(this.suveyInfo?.form_valid, this.suveyInfo.data);
            }
            this.loading = false;
            this.formLoaded.emit(true);
          },
          (error) => {
            console.error('Error in subscription:', error);
            this.loading = false;
            this.formLoaded.emit(true);
          }
        );
    }
  }

  ngOnDestroy(): void {
    this.ListeofUnsbscribeservice.forEach((element) => {
      element?.unsubscribe();
    });
  }

  /*
    surveyJsInit(surveyJSON: any, data: any): void {
  
      try {
        const survey = new Model(surveyJSON);
        this.surveyModel = survey;
  
        // Apply the theme
        this.surveyModel.applyTheme(this.themeJson);
  
        this.surveyModel.data = JSON.parse(data);
  
        // Additional configurations for survey behavior (optional)
        this.surveyModel.pages.forEach((page) => {
          if(page.name !== 'last')
          page.questions.forEach((question) => {
            question.readOnly = true; // Make the questions read-only if necessary
          });
        });
  
  
        if (this.informationsContrat?.contrat?.etat_dossier_id === "024d7f84fff11dd7e8d9c510137a2381") {
          const page = this.surveyModel.getPageByName('last');
          page.visible = true;
          survey.currentPage = survey.pages[survey.pages.length - 1];
        }
  
        survey.onValueChanged.add((sender: Survey.SurveyModel, options: any) => {
          const surveyData = sender.data;
  
          // Check if there's a dynamic panel and extract its values
          const dynamicPanelData = [];
      /*     sender.pages.forEach((page) => {
            page.questions.forEach((question) => {
              if (question.getType() === 'paneldynamic') {
                dynamicPanelData.push({
                  questionName: question.name,
                  panelValues: question.value,
                });
              }
  
              if (question.name === 'regional_dir_decision_missing_pieces') {
                // eslint-disable-next-line dot-notation
                this.modelspecif['regional_dir_decision_missing_pieces'] = question.value;
  
                // eslint-disable-next-line dot-notation
                const savedValue = this.surveyModel.data['regional_dir_decision_missing_pieces'];
                if (savedValue) {
                  // Pre-fill the form field with the saved value
                  this.surveyModel.setValue('regional_dir_decision_missing_pieces', savedValue);
                }
              }
            });
          }); */

  // Extract the last page data if required
  /*  const lastPageData = sender
     .getAllQuestions()
     .filter((question) => question.page.name === sender.pages[sender.pages.length - 1].name)
     .map((question) => ({ questionName: question.name, value: question.value }));

   // Log the captured data to verify it's correct
   

   // Emit the dynamic panel and last page data
   this.dataForm.emit({
     data: surveyData,
     // dynamicPanelData: dynamicPanelData,
     // lastPageData: lastPageData,
     form: {},
   });
 });

 Survey.SurveyNG.render('surveyModel', { model: survey });

 this.loading = false;
} catch (error) {
 this.loading = false;
}
} */

  surveyJsInit(surveyJSON: any, data: any): void {
    try {
      this.surveyModel = new SurveyModel(surveyJSON);
      this.surveyModel.data = data;
      this.displayQuestionsFunction();

      this.surveyModel.getAllQuestions().forEach((question) => {
        if (question.name === 'data_validation_provisoire_search') {
          question.visible = false;
        }
      });

      const modifyFileLinks = (element: HTMLElement) => {
        const fileLinks = element.querySelectorAll('a');
        fileLinks.forEach((link) => {
          link.setAttribute('target', '_blank');
        });
      };

      const observeFileQuestion = (element: HTMLElement) => {
        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
              modifyFileLinks(element);
            }
          }
        });
        observer.observe(element, { childList: true, subtree: true });
        modifyFileLinks(element);
      };

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        const placeholderNoFile = options.htmlElement.querySelector('.sd-file__decorator');
        if (placeholderNoFile) {
          const placeholder = placeholderNoFile.querySelector('.sd-file__drag-area-placeholder');
          if (placeholder) {
            const spanElement = placeholder.querySelector('.sv-string-viewer');
            if (spanElement && spanElement.textContent === 'No file selected') {
              spanElement.textContent = 'Aucun fichier téléchargé - لم يتم تحميل أي ملف';
            }
          }
        }

        if (options.question.getType() === 'file') {
          observeFileQuestion(options.htmlElement);
        }
      });

      this.surveyModel.onAfterRenderQuestion.add((sender, options) => {
        if (options.question.getType() === 'file') {
          observeFileQuestion(options.htmlElement);
        }
      });

      this.surveyModel.onAfterRenderPanel.add((sender, options) => {
        options.panel.questions.forEach((question) => {
          if (question.getType() === 'file') {
            const questionElement = options.htmlElement.querySelector(`[name='${question.inputName}']`);
            if (questionElement) {
              observeFileQuestion(questionElement.closest('.sv_q'));
            }
          }
        });
      });

      this.surveyModel.getAllQuestions().forEach((question) => {
        const numeroEtatSecondaire = this.surveyModel.getValue('numero_etat_secondaire');

        if (numeroEtatSecondaire === '000') {
          this.surveyModel.getQuestionByName('numero_matricule_CNSS').visible = true;
          this.surveyModel.getQuestionByName('denomination_officielle_CNSS').visible = true;
          this.surveyModel.getQuestionByName('denomination_officielle_RNE_arabe').visible = true;
          this.surveyModel.getQuestionByName('denomination_officielle_RNE_latin').visible = true;
          this.surveyModel.getQuestionByName('denomination_commerciale_RNE_arabe').visible = true;
          this.surveyModel.getQuestionByName('denomination_commerciale_RNE_latin').visible = true;
          this.surveyModel.getQuestionByName('adresse_EPF').visible = true;
          this.surveyModel.getQuestionByName('adresse_epf_DGI').visible = true;
          this.surveyModel.getQuestionByName('activite_principale_DGI').visible = true;
          this.surveyModel.getQuestionByName('activite_secondaire_DGI').visible = true;
          this.surveyModel.getQuestionByName('date_expiration_protection_civile').visible = true;
          this.surveyModel.getQuestionByName('doc_rne').visible = true;
          this.surveyModel.getQuestionByName('doc_patente').visible = true;
          this.surveyModel.getQuestionByName('doc_affiliation_CNSS').visible = true;
          this.surveyModel.getQuestionByName('doc_attestation_protection_civile').visible = true;

          if (data?.investor_nationality === 'تونسية') {
            this.surveyModel.getQuestionByName('doc_b3_investisseur').visible = true;
          }

          if (data?.establishment_director_data_identity === 'مدير غير المستثمر') {
            this.surveyModel.getQuestionByName('doc_b3_directeur').visible = true;
          }

          this.surveyModel.getQuestionByName('doc_autres').visible = true;
          this.surveyModel.getQuestionByName('adresse_epf_RNE').visible = true;
          this.surveyModel.getQuestionByName('activite_principale_RNE').visible = true;
          this.surveyModel.getQuestionByName('activite_secondaire_RNE').visible = true;
        } else {
          this.surveyModel.getAllQuestions().forEach((question) => {
            question.readOnly = true;
          });

          this.surveyModel.getQuestionByName('numero_matricule_CNSS').visible = true;
          this.surveyModel.getQuestionByName('denomination_officielle_CNSS').visible = true;
          this.surveyModel.getQuestionByName('denomination_officielle_RNE_arabe').visible = true;
          this.surveyModel.getQuestionByName('denomination_officielle_RNE_latin').visible = true;
          this.surveyModel.getQuestionByName('denomination_commerciale_RNE_arabe').visible = true;
          this.surveyModel.getQuestionByName('denomination_commerciale_RNE_latin').visible = true;
          this.surveyModel.getQuestionByName('adresse_EPF').visible = true;
          this.surveyModel.getQuestionByName('adresse_epf_DGI').visible = true;
          this.surveyModel.getQuestionByName('activite_principale_DGI').visible = true;
          this.surveyModel.getQuestionByName('activite_secondaire_DGI').visible = true;
          this.surveyModel.getQuestionByName('date_expiration_protection_civile').visible = true;
          this.surveyModel.getQuestionByName('doc_rne').visible = true;
          this.surveyModel.getQuestionByName('doc_patente').visible = true;
          this.surveyModel.getQuestionByName('doc_affiliation_CNSS').visible = true;
          this.surveyModel.getQuestionByName('doc_attestation_protection_civile').visible = true;

          if (data?.investor_nationality === 'تونسية') {
            this.surveyModel.getQuestionByName('doc_b3_investisseur').visible = true;
          }

          if (data?.establishment_director_data_identity === 'مدير غير المستثمر') {
            this.surveyModel.getQuestionByName('doc_b3_directeur').visible = true;
          }

          this.surveyModel.getQuestionByName('doc_autres').visible = true;
        }

        this.surveyModel.showCompleteButton = false;

        const rneDescriptionQuestion = this.surveyModel.getQuestionByName('legal_entity_rne_description');
        if (rneDescriptionQuestion) {
          rneDescriptionQuestion.visible = false;
          rneDescriptionQuestion.visibleIf = null;
        }

        if (question.name === 'management_data') {
          const dynamicPanel = question as QuestionPanelDynamicModel;
          dynamicPanel.panels.forEach((panel, index) => {
            const managementData = data['management_data'][index];
            if (managementData) {
              // Preload files for Tunisian nationals
              if (managementData['management_data_staff_nationality'] === 'تونسية') {
                const cinCopy = managementData['management_data_staff_cin_copy'];
                if (cinCopy) {
                  const fileArray = Array.isArray(cinCopy) ? cinCopy : [cinCopy];
                  panel.getQuestionByName('management_data_staff_cin_copy').value = fileArray;
                  panel.getQuestionByName('management_data_staff_cin_copy').visible = true;
                }
              } else {
                // Preload files for non-Tunisian nationals
                const passportCopy = managementData['management_data_staff_passport_copy'];
                if (passportCopy) {
                  const fileArray = Array.isArray(passportCopy) ? passportCopy : [passportCopy];
                  panel.getQuestionByName('management_data_staff_passport_copy').value = fileArray;
                  panel.getQuestionByName('management_data_staff_passport_copy').visible = true;
                }
              }

              // Preload the personal folder file
              const personalFolder = managementData['management_data_staff_personal_folder'];
              if (personalFolder) {
                const fileArray = Array.isArray(personalFolder) ? personalFolder : [personalFolder];
                panel.getQuestionByName('management_data_staff_personal_folder').value = fileArray;
                panel.getQuestionByName('management_data_staff_personal_folder').visible = true;
              }
            }
          });
        }

        /*       if (question.name === 'trainer_data') {
          const dynamicPanel = question as QuestionPanelDynamicModel;
          dynamicPanel.panels.forEach((panel, index) => {
            const trainerData = data['trainer_data'][index];
            if (trainerData) {
              const trainerNationality = trainerData['trainer_data_nationality'];
              const isTunisian = trainerNationality === 'تونسية';

              const idNumber = isTunisian
                ? trainerData['trainer_data_cin_number']
                : trainerData['trainer_data_passeport_number'];

              this.apiAffairesService
                .getDocumentFormateurByPassAndCin(isTunisian ? null : idNumber, isTunisian ? idNumber : null)
                .subscribe(
                  (res) => {
                    const documents = res?.response;

                    if (documents && documents.length > 0) {
                      if (isTunisian) {
                        const responseFile = documents.find((e: any) => e.key === 'copie_cin');
                        if (responseFile) {
                          const file = [
                            {
                              content: responseFile.url,
                              name: responseFile.name,
                              type: mime.getType(responseFile.name) || 'application/octet-stream',
                            },
                          ];
                          panel.getQuestionByName('trainer_data_cin').value = file;
                          panel.getQuestionByName('trainer_data_cin').visible = true;
                        }
                      } else {
                        const responseFile = documents.find((e: any) => e.key === 'copie_passeport');
                        if (responseFile) {
                          const file = [
                            {
                              content: responseFile.url,
                              name: responseFile.name,
                              type: mime.getType(responseFile.name) || 'application/octet-stream',
                            },
                          ];
                          panel.getQuestionByName('trainer_data_passeport').value = file;
                          panel.getQuestionByName('trainer_data_passeport').visible = true;
                        }
                      }

                      // Handle dossier authorization
                      // if (panel.getQuestionByName('autoriser_dossier').value === 'نعم') {
                      const responseFile = documents.find((e: any) => e.key === 'dossier_file');
                      if (responseFile) {
                        const file = [
                          {
                            content: responseFile.url,
                            name: responseFile.name,
                            type: mime.getType(responseFile.name) || 'application/octet-stream',
                          },
                        ];
                        panel.getQuestionByName('trainer_data_folder').value = file;
                      }
                      // } else {
                      //   panel.getQuestionByName('dossier_autoriser').value = false;
                      // }
                    }
                  },
                  (error) => {
                    console.error('Error fetching documents:', error);
                  }
                );
            }
          });
        } */
      });

      this.surveyModel.getAllQuestions().forEach((question) => {
        if (question.name === 'management_data_staff_category_other_text') {
          question.visible = false;
        }

        if (question.name === 'trainer_data') {
          question.panels.forEach((panel) => {
            panel.getQuestions().forEach((panelQuestion) => {
              if (
                panelQuestion.name === 'trainer_data_birth_date' ||
                panelQuestion.name === 'trainer_data_cin_date_delivrance'
              ) {
                const birthDate = panelQuestion.value;
                if (birthDate) {
                  panelQuestion.value = moment(birthDate).format('YYYY-MM-DD');
                }
              }
            });
          });
        }

        const descriptionLegalQuestion = this.surveyModel.getQuestionByName('legal_entity_rne_description');
        if (descriptionLegalQuestion) {
          descriptionLegalQuestion.visible = false;
        }

        const descriptionQuestion = this.surveyModel.getQuestionByName('establishment_rne_description');
        if (descriptionQuestion) {
          descriptionQuestion.visible = false;
        }

        if (question.name.toLowerCase().includes('_nationality')) {
          question.choices = this.nationalities;
        }

        if (question.name === 'training_nature_data') {
          question.panels.forEach((panel) => {
            panel.questions.forEach((question) => {
              if (question.name === 'training_type_data_sector') {
                question.choices = this.secteurs;
              }
              if (question.name === 'training_type_data_sub_sector') {
                question.choices = this.Soussecteurs;
              }

              if (question.name === 'training_type_data_specialty') {
                question.choices = this.diplome_specialite;
              }
            });
          });
        }

        if (question.name === 'trainer_data') {
          question.panels.forEach((panel) => {
            panel.questions.forEach((question) => {
              if (question.name.toLowerCase().includes('_nationality')) {
                question.choices = this.nationalities;
              }
              if (question.name.toLowerCase().includes('_governorate')) {
                question.choices = this.goverments;
              }
              if (question.name.toLowerCase().includes('_date')) {
                this.surveyModel.setValue(question.name, moment(question.value).format('YYYY-MM-DD'));
              }
              if (question.name.toLowerCase().includes('_delegation')) {
                question.choices = this.delegation;
              }
            });
          });
        }

        if (question.name === 'trainer_data') {
          question.panels.forEach((panel) => {
            panel.questions.forEach((question) => {
              if (question.name === 'trainer_data_birth_place') {
                const birthDate = question.value;

                if (birthDate) {
                  question.value = moment(birthDate).format('YYYY-MM-DD');
                }
              }
            });
          });
        }

        if (question.name === 'management_data') {
          question.panels.forEach((panel) => {
            panel.questions.forEach((question) => {
              if (
                question.name === 'management_data_staff_birth_date' ||
                question.name === 'management_data_staff_date_delivrance'
              ) {
                const birthDate = question.value;

                if (birthDate) {
                  question.value = moment(birthDate).format('YYYY-MM-DD');
                }
              }

              if (question.name.toLowerCase().includes('_governorate')) {
                question.choices = this.goverments;
              }
              if (question.name.toLowerCase().includes('_date')) {
                this.surveyModel.setValue(question.name, moment(question.value).format('YYYY-MM-DD'));
              }
              if (question.name.toLowerCase().includes('_delegation')) {
                question.choices = this.delegation;
              }
              if (question.name.toLowerCase().includes('_nationality')) {
                question.choices = this.nationalities;
              }
            });
          });
        }

        if (
          question.name === 'file_description' ||
          question.name === 'legal_entity_rne_description' ||
          question.name === 'establishment_rne_description' ||
          question.name === 'espace_file_description'
        ) {
          question.visible = false;
        }

        if (question.name.toLowerCase().includes('_governorate')) {
          question.choices = this.goverments;
        }
        if (question.name.toLowerCase().includes('_date')) {
          this.surveyModel.setValue(question.name, moment(question.value).format('YYYY-MM-DD'));
        }
        if (question.name.toLowerCase().includes('_delegation')) {
          question.choices = this.delegation;
        }
      });

      // Apply the theme
      StylesManager.applyTheme('defaultV2');

      // Set survey data

      if (
        this.surveyModel?.data.regional_dir_decision &&
        this.surveyModel?.data?.regional_dir_decision_missing_pieces?.length > 0
      ) {
        this.allowUpdateStatus.emit(true);
      } else {
        this.allowUpdateStatus.emit(false);
      }
      // const lastPageIndex = this.surveyModel.pages.length - 1;
      // if (lastPageIndex >= 0) {
      //   this.surveyModel.pages[lastPageIndex].visible = false;
      // }

      // all pages are visible
      /* this.surveyModel.pages.forEach((page) => {
        page.visible = true;
      }); */

      // Configure survey navigation and progress bar
      // this.surveyModel.showProgressBar = 'top';
      this.surveyModel.showPageTitles = false;
      // this.surveyModel.showQuestionNumbers = 'onPage';
      this.surveyModel.showNavigationButtons = false;
      //    this.surveyModel.progressBarType = 'pages';

      this.surveyModel.showCompleteButton = false;
      this.surveyModel.editText = 'Consulter - الإطلاع';

      // Set all questions to read-only
      this.surveyModel.getAllQuestions().forEach((question) => {
        question.readOnly = true;
      });

      /*         if (this.informationsContrat?.contrat?.etat_dossier_id === environment.en_cours_traitement_statut_id) {
                const page = this.surveyModel.getPageByName('last');
       
                if (page) {
                  page.visible = true;
                  page.questions.forEach((question) => {
                    question.readOnly = false;
                  });
                 }
      
                 this.surveyModel.currentPage = this.surveyModel.pages[this.surveyModel.pages.length - 1];
      
              } */

      this.surveyModel.onValueChanged.add((sender: SurveyModel, options: any) => {
        const surveyData = sender.data;
        this.surveyModel.data = { ...surveyData };

        if (
          surveyData?.regional_dir_decision &&
          surveyData?.regional_dir_decision_missing_pieces?.length > 0 &&
          surveyData?.regional_dir_decision_missing_pieces?.every(
            (piece) =>
              piece?.regional_dir_decision_missing_piece && piece?.regional_dir_decision_missing_piece.trim() !== ''
          )
        ) {
          this.allowUpdateStatus.emit(true);
        } else {
          this.allowUpdateStatus.emit(false);
        }

        this.dataForm.emit({
          data: surveyData,

          model: sender.toJSON(),
        });
      });

      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  displayQuestionsFunction = () => {
    const directorSearchQuestion = this.surveyModel.getQuestionByName('director_search');
    if (directorSearchQuestion) {
      directorSearchQuestion.visible = false;

      directorSearchQuestion.visibleIf = null;

      this.surveyModel.render;
    }

    const managementDataSearchQuestion = this.surveyModel.getQuestionByName('management_data_search');
    if (managementDataSearchQuestion) {
      managementDataSearchQuestion.visible = false;
      managementDataSearchQuestion.visibleIf = 'false';
    }

    this.surveyModel.getAllQuestions().forEach(async (question) => {
      //

      if (question.name === 'management_data') {
        const dynamicPanel = question;
        dynamicPanel.panels.forEach(async (panel) => {
          panel.getQuestions().forEach((question) => {
            if (
              question.name !== 'management_data_staff_cin_number' &&
              question.name !== 'management_data_staff_passport_number' &&
              question.name !== 'management_data_staff_date_delivrance' &&
              question.name !== 'management_data_staff_cin_copy' &&
              question.name !== 'management_data_staff_passport_copy' &&
              question.name !== 'management_data_staff_valid' &&
              question.name !== 'management_data_check_spinner' &&
              question.name !== 'management_data_search'
            ) {
              question.visible = true;
            }

            if (question.name === 'management_data_search') {
              question.visible = false;
              question.visibleIf = 'false';
            }

            if (panel.getQuestionByName('management_data_staff_nationality').value === 'تونسية') {
              if (
                question.name === 'management_data_staff_cin_copy' ||
                question.name === 'management_data_staff_date_delivrance'
              ) {
                question.visible = true;
              }
            } else if (panel.getQuestionByName('management_data_staff_nationality').value !== 'تونسية') {
              if (question.name === 'management_data_staff_passport_copy') {
                question.visible = true;
              }
            }
          });
        });
      }
      if (question.name === 'trainer_data') {
        const dynamicPanel = question;

        dynamicPanel.panels.forEach(async (panel) => {
          panel.getQuestions().forEach(async (question) => {
            if (
              question.name !== 'trainer_data_cin' &&
              question.name !== 'trainer_data_cin_number' &&
              question.name !== 'trainer_data_cin_date_delivrance' &&
              question.name !== 'trainer_data_passeport' &&
              question.name !== 'trainer_data_folder' &&
              question.name !== 'trainer_data_test_valid' &&
              question.name !== 'trainer_active' &&
              question.name !== 'trainer_data_passport' &&
              question.name !== 'cin_check_spinner' &&
              question.name !== 'dossier_autoriser'
            ) {
              question.visible = true;
            }

            if (panel.getQuestionByName('dossier_autoriser').value === true) {
              if (question.name === 'trainer_data_folder') {
                question.visible = true;
              }
            }

            if (panel.getQuestionByName('trainer_data_nationality').value === 'تونسية') {
              if (question.name === 'trainer_data_cin' || question.name === 'trainer_data_cin_date_delivrance') {
                question.visible = true;
              }
            } else if (panel.getQuestionByName('trainer_data_nationality').value !== 'تونسية') {
              if (question.name === 'trainer_data_passeport' || question.name === 'trainer_data_passeport') {
                question.visible = true;
              }
            }
          });
        });
      }

      const page = this.surveyModel.getPageByName('Données de Directeur de l’Établissement');

      if (page) {
        const directorSearchQuestion = this.surveyModel.getQuestionByName('director_search');
        if (directorSearchQuestion) {
          directorSearchQuestion.visible = null;
          directorSearchQuestion.visible = false;
        }

        if (this.surveyModel.getValue('establishment_director_data_identity') === 'المستثمر نفسه') {
          page.questions.forEach((question) => {
            if (
              question.name !== 'establishment_director_data_test_valid' &&
              question.name !== 'establishment_director_data_passeport_copie' &&
              question.name !== 'copie_autorisation_ministre' &&
              question.name !== 'establishment_director_data_cin_copie' &&
              question.name !== 'establishment_director_data_date_delivrance' &&
              question.name !== 'establishment_director_data_ministerial_auth_date' &&
              question.name !== 'establishment_director_data_passeport' &&
              question.name !== 'establishment_director_data_cin_number' &&
              question.name !== 'establishment_director_data_spinner' &&
              question.name !== 'director_search'
            ) {
              question.visible = true;
            }
            if (this.surveyModel.getQuestionByName('investor_nationality').value === 'تونسية') {
              if (
                question.name === 'establishment_director_data_cin_copie' ||
                question.name === 'establishment_director_data_date_delivrance'
              ) {
                question.visible = true;
              }
            } else if (this.surveyModel.getQuestionByName('investor_nationality').value !== 'تونسية') {
              if (
                question.name === 'establishment_director_data_test_valid' ||
                question.name === 'establishment_director_data_passeport_copie' ||
                question.name === 'copie_autorisation_ministre' ||
                question.name === 'establishment_director_data_ministerial_auth_date'
              ) {
                question.visible = true;
              }
            }
          });
        } else {
          if (question.name === 'establishment_director_data_nationality') {
            const isTunisian = question.value === 'تونسية';

            const tunisianVisibleQuestions = [
              'establishment_director_data_nationality',
              'establishment_director_data_identity',
              'establishment_director_data_cin_number',
              'establishment_director_data_birth_date',
              // "director_search",
            ];

            const nonTunisianQuestions = [
              'establishment_director_data_test_valid',
              'establishment_director_data_passeport_copie',
              'copie_autorisation_ministre',
              'establishment_director_data_ministerial_auth_date',
              'establishment_director_data_passeport',
            ];

            const alwaysVisibleQuestions = [
              'establishment_director_data_test_valid',
              'establishment_director_data_passeport_copie',
              'copie_autorisation_ministre',
              'establishment_director_data_cin_copie',
              'establishment_director_data_date_delivrance',
              'establishment_director_data_ministerial_auth_date',
              'establishment_director_data_passeport',
              'establishment_director_data_cin_number',
              'establishment_director_data_spinner',
              // "director_search",
            ];

            const page = this.surveyModel.getPageByName('Données de Directeur de l’Établissement');

            page.questions.forEach((question) => {
              question.visible = false;

              if (isTunisian) {
                question.visible = tunisianVisibleQuestions.includes(question.name);
              } else {
                question.visible = nonTunisianQuestions.includes(question.name);
              }

              if (!alwaysVisibleQuestions.includes(question.name) && !isTunisian) {
                question.visible = true;
              }
            });
          }

          page.questions.forEach((question) => {
            if (
              question.name !== 'establishment_director_data_test_valid' &&
              question.name !== 'establishment_director_data_passeport_copie' &&
              question.name !== 'copie_autorisation_ministre' &&
              question.name !== 'establishment_director_data_cin_copie' &&
              question.name !== 'establishment_director_data_date_delivrance' &&
              question.name !== 'establishment_director_data_ministerial_auth_date' &&
              question.name !== 'establishment_director_data_passeport' &&
              question.name !== 'establishment_director_data_cin_number' &&
              question.name !== 'establishment_director_data_spinner' &&
              question.name !== 'director_search'
            ) {
              question.visible = true;
            }
            if (this.surveyModel.getQuestionByName('establishment_director_data_nationality').value === 'تونسية') {
              if (
                question.name === 'establishment_director_data_cin_copie' ||
                question.name === 'establishment_director_data_date_delivrance'
              ) {
                question.visible = true;
              }
            } else if (
              this.surveyModel.getQuestionByName('establishment_director_data_nationality').value !== 'تونسية'
            ) {
              if (
                question.name === 'establishment_director_data_test_valid' ||
                question.name === 'establishment_director_data_passeport_copie' ||
                question.name === 'copie_autorisation_ministre' ||
                question.name === 'establishment_director_data_ministerial_auth_date'
              ) {
                question.visible = true;
              }
            }
          });
        }
      }
    });
  };

  alertSuccess(data) {
    swal.fire({
      title: 'Opération Réussie!',
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#68a45b',
    });
  }

  alertWarning(data) {
    swal.fire({
      title: data,
      text: 'Il faut remplir tout les champs!',
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#e75e5e',
    });
  }

  alertError(data) {
    swal.fire({
      title: data,
      text: "Quelque chose s'est mal passé!",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }
}

// last page and dynamic pannel
