import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AffairesList } from 'src/app/entity/Affaires/AffairesList';
import { ApiDemandesService } from 'src/app/services/ApiDemandes/api-demandes.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';

@Component({
  selector: 'app-list-favoris',
  templateUrl: './list-favoris.component.html',
  styleUrls: ['./list-favoris.component.css'],
})
export class ListFavorisComponent implements OnInit {
  favorites: any[] = [];
  totalPages = 0;
  affairesList: AffairesList = new AffairesList();
  sort_field: string = 'affaire_date_creation';
  masterSelected: boolean;
  checkedList: any;
  actionButton: boolean = false;
  inputSubject = new Subject<string>();
  sSearch: string = '';
  alerts: any;
  buttons: any;

  constructor(private apiDemandeService: ApiDemandesService,
    private translate: TranslateService,
    private sharedMenuObserverService: SharedMenuObserverService
  ) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.sharedMenuObserverService.updateMenu(object.navbar.Favoris);
    });
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.sharedMenuObserverService.updateMenu(object.navbar.Favoris);
      });
    });
    this.suivie_favoris();
  }

  suivie_favoris() {
    this.showloader()
    this.apiDemandeService.GetListFavorite().subscribe(
      (response) => {
        this.favorites = response;
        this.hideloader()
      },
      () => {
        this.hideloader()
      }
    );
  }

  add_suivi_affaire(affaire_id: string, id_contrat: string) {
    this.showloader()
    this.apiDemandeService.AddToFavorite(affaire_id, id_contrat).subscribe(
      (response) => {
        this.suivie_favoris();
        this.hideloader()
      },
      () => {
        this.hideloader()
      }
    );
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }


  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  checkUncheckAll() {
    for (let i = 0; i < this.affairesList?.aaData?.length; i++) {
      this.affairesList.aaData[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    for (let i = 0; i < this.affairesList?.aaData?.length; i++) {
      if (this.affairesList.aaData[i].isSelected) this.checkedList.push(this.affairesList.aaData[i].affaire_id);
    }
    if (this.checkedList.length > 0) {
      this.actionButton = true;
    } else {
      this.actionButton = false;
    }
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }
}
