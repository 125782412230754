<div class="card-headd text-center">
    <div class="row details-header">
        <button type="button" class="btn-dt-retourr mt-1" (click)="returnToList()">
            <div class="icon-position">
                <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
            </div>
        </button>
        <div class="row">
            <div class=" row details-spinner">
                <div class="col"> </div>
                <div class="mt-2 mr-2" *ngIf="loader">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
         
            </div>


       <!--      <app-shared-button-enrg data-toggle="modal" data-target="#Actions" (click)="getStatus()" class="ml-2"
                [type]="'o'" [label]="Enregistrer"  class="ml-2"></app-shared-button-enrg> -->
        </div>
    </div>

</div>


<div class="row custem-margin">
    <div class="col-4 w-100">
        <div class="card-head w-100">
            <div class="row justify-content-center">
              
               
                <div class="text-nowrap col-12">
                    {{'languages.ticket.numTicket' | translate}} :
                    <label class="label-card-info">
                        {{informationTicket.id_ticket}}
                    </label>
                </div>

                <div class="text-nowrap col-12" *ngIf="informationTicket.id_affaire">
                    {{'languages.affaire.nContrat' | translate}} :
                    <label class="label-card-info" >
                        <a target="_blank" class="a-style row" (click)="NaviagateToAffaireDetials(informationTicket.id_affaire)" style="cursor: pointer;">
                            {{informationTicket.real_id_affaire}}
                                    </a>
                    </label>
                </div>
                <div class="text-nowrap col-12" *ngIf="informationTicket.real_id_opp">
                    {{'languages.opportunite.opportuniteNum' | translate}} :
                    <label class="label-card-info">
                        <a target="_blank" class="a-style row" (click)="NaviagateToOPpDetials(informationTicket.id_opp)" style="cursor: pointer;">
                            {{informationTicket.real_id_opp}}
                                    </a>
                    </label>
                </div>
                <div class="text-nowrap col-12" *ngIf="informationTicket?.id_ticket_parent">
                    {{'languages.ticket.ticketParentNum' | translate}}
                    <label class="label-card-info" >
                        <a target="_blank" class="a-style row" (click)="NaviagateToTicketDetails(informationTicket.id_ticket_parent)" style="cursor: pointer;">
                            {{informationTicket?.real_id_ticket_parent}}
                                    </a>
                    </label>
                </div>

            

            </div>
        </div>

    </div>

    <div class="col-4 w-100 ">
        <div class="card-head mr-2 w-100">
            <div class="row justify-content-center">
                <div class="col-12 text-nowrap ">
                    {{'languages.opportunite.createdBy' | translate}} :
                    <label class="label-card-info">
                        {{informationTicket.createur}} 
                    </label>
                </div>
                <!-- <div class="text-nowrap col-12 ">
                    {{'languages.ticket.dateLimit' | translate}}:
                    <label class="label-card-info">
                        {{informationTicket.date_limit ? informationTicket.date_limit :'languages.ticket.noLimit' | translate}}  
                    </label>
                </div> -->

               <!--  <div class="text-nowrap col-12">
                    {{'languages.ticket.Category' | translate}}
                    <label class="label-card-info">
                        {{informationTicket.categorie}}
                    </label>
                </div> -->


            </div>
        </div>

    </div>

    <div class="col-4 w-100 ">
        <div class="card-head mr-2 w-100">
            <div class="row">
                <div class="text-nowrap col-12">
                    <div class="row text-nowrap">
                     <div class="col-2 mrgin  ">
                            <label class="label-card-info"> </label>
                     </div>
                     <div class="col-6"></div>
             <!--         <div class="col">
                        <svg data-toggle="modal"  data-target="#Actions" (click)="openModalUpdate()"
                         class=" float-right cursor-pointer" width="25" height="25"
                        viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12.5" cy="12.5" r="12.5" fill="#D9D9D9" />
                        <path
                            d="M14.0857 6.91356L18.0862 10.9143L9.39929 19.6016L5.83251 19.9953C5.35502 20.0482 4.95159 19.6444 5.00471 19.1669L5.40158 15.5975L14.0857 6.91356ZM20.5606 6.31793L18.6822 4.43946C18.0962 3.85351 17.146 3.85351 16.56 4.43946L14.7929 6.20668L18.7934 10.2074L20.5606 8.44015C21.1465 7.85389 21.1465 6.90388 20.5606 6.31793Z"
                            fill="#474747" />
                    </svg>
                     </div> -->

                    </div>

                </div>

                <div class="text-nowrap col-12">
                  <label>   {{'languages.opportunite.statusActuel' | translate}} :</label>
                  <label class="VAD text-center border-0 ml-3"
                  [ngStyle]="{'background':informationTicket.statut_details?.background_color}">
                    <span [ngStyle]="{'color':informationTicket.statut_details?.color}" *ngIf="informationTicket.statut_details?.icon">
                    <i class=" {{informationTicket?.statut_details?.icon}} mr-2" ></i>

                    {{informationTicket.statut_details?.libelle.split('(')[0].trim()}}
                    </span>
              </label>
                </div>
                <div class="text-nowrap col-12" *ngIf="!isDisabled">

                        {{'languages.ticket.delaiConventionnel' | translate}} :
                          <label class="label-card-info" *ngIf="informationTicket.delai_traitement !== null">
                              {{delai_convent}}
                        </label>
                        <label class="label-card-info" *ngIf="informationTicket.delai_traitement == null">
                            {{traitementDate}}
                      </label>
                </div>
                <div class="text-nowrap col-12" *ngIf="isDisabled">

                         {{'languages.ticket.closeDate' | translate}}
                        <label class="label-card-info">{{DateFermeture}}</label>


                </div>

                </div>
        </div>

    </div>
</div>
















<div class="row">
    <div class="col ml-3" style="max-width: 248px;">
        <div class="sidebar-ticket" id="scrollbar-ticket">
            <div class="card-ticket bg-white text-center border-0">
<!-- 
                <div class="col py-1 ">
                    <button (click)="scroll('infoTicket')"
                        class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <i class="fa-solid fa-list-check fa-lg"></i>
                        </label>
                        <span> {{'languages.buttons.details' | translate}}</span>
                    </button>
                </div>
 -->
             <!--    <div class="col py-1 " *ngIf="informationTicket.id_prospect!==null">
                    <button (click)="scroll('infoProspect')"
                        class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <i class="fa-solid fa-user-tie fa-lg"></i>
                        </label>
                        <span *ngIf="informationTicket.id_prospect!==null && informationTicket.id_affaire===null">
                             {{'languages.prospect.Informationsprospect' | translate}}</span>
                        <span *ngIf="informationTicket.id_affaire!==null && informationTicket.id_prospect!==null">
                            {{'languages.prospect.Informationsclient' | translate}}</span>
                    </button>
                </div> -->
            <!--     <div class="col py-1 " *ngIf="informationTicket?.id_affaire &&  informationTicket?.claim">
                    <button (click)="scroll('garantie')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                
                                <i class="fa-solid fa-umbrella fa-lg"></i>
                            </label>
                            <span>{{'languages.affaire.garanties' | translate}}</span>
                        </button>
                </div> -->
     <div class="col py-1 ">
                    <button (click)="scroll('infoSpecifique')"
                        class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <i class="fa-solid fa-circle-info fa-lg"></i>
                        </label>
                        <span> {{'languages.opportunite.details.infoSpecific' | translate}}</span>
                    </button>
                </div>  
                
                <div class="col py-1 ">
                    <button (click)="scroll('porteDocument')"
                        class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <i class="fa-solid fa-file-import fa-lg"></i>
                        </label>
                        <span> {{'languages.document.PorteDocuments' | translate}}</span>
                    </button>
                </div>
<!-- 
                <div class="col py-1 ">
                    <button (click)="scroll('comment')" class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <i class="fa-solid fa-comment-dots fa-lg"></i>
                        </label>
                        <span> {{'languages.opportunite.details.commentaire_label' | translate}}</span>
                    </button>
                </div> -->
                
              <!--   <button (click)="scroll('sousTickets')"
                            class=" focus-style d-block border-0 full-width text-left pl-3">
                            <label class="align-details-icon">
                                <i class="fa-solid fa-tickets-simple mt-1"></i>
                            </label>
                            <span> {{'languages.ticket.sousTicket' | translate}}</span>
                        </button> -->

                <div class="col py-1 ">
                    <button (click)="scroll('history')" class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <i class="fa-solid fa-clock-rotate-left fa-lg"></i>
                        </label>
                        <span>{{'languages.opportunite.details.label_history' | translate}}</span>
                    </button>
                </div>
            </div>

         <!--    <div class="text-left"> 
                <shared-tags [tickets_id]="tickets_id"></shared-tags>
            </div> -->
        </div>
    </div>

    <div class="col overflow-auto">

        <div class="row w-100">
            <div class="col-12 p-0 w-100">
           <!--      <div class=" pt-1 mb-2 custem-R" id="infoTicket">
                    <app-informations-ticket (emitUpdateTicket)="alertConfirmUpdate($event)"></app-informations-ticket>
                </div>
                <div class=" pt-1 mb-2 customMargin custem-R" id="infoProspect" *ngIf="informationTicket.id_prospect || informationTicket.id_affaire ">
                    <app-ticket-coordonnees-prospect></app-ticket-coordonnees-prospect>

                </div>

                <div class=" pt-1 mb-2 custem-R" id="garantie" *ngIf="informationTicket?.id_affaire &&  informationTicket?.claim">
                    <app-garantie  [prospect_id]="informationTicket.id_prospect" [ticket_id]="tickets_id" [affaire_id]="informationTicket?.id_affaire" ></app-garantie>
                </div> -->


                <div class=" pt-1 mb-2 custem-R" id="infoSpecifique">
                    <app-ticket-informations-specifique 
                        (changeFormInfoSpecifique)="getChangeForm($event)" (emitFormTicket)="geEmitInfoSpecifique($event)"></app-ticket-informations-specifique>
                </div>


                <div class=" pt-1 mb-2 custem-R" id="porteDocument">
                    <ng-container *ngIf="informationTicket.id_affaire">
                        <app-liste-documents [id_affaire]="informationTicket.id_affaire"></app-liste-documents>
                      </ng-container>
                                      </div>

              <!--   <div class=" pt-1 customMargin pb-2 custem-R" id="comment">
                    <app-commentaires-client-ticket></app-commentaires-client-ticket>
                </div> -->

             <!--    <div class=" pt-1 mb-2 custem-R" id="sousTickets" *ngIf="informationTicket.id_ticket_md5">
                    <app-sous-ticket   [ticket_id]="tickets_id" [prospect_id]="informationTicket.id_prospect" [opportunite_id]="informationTicket?.id_opp" [affaire_id]="informationTicket?.id_affaire"></app-sous-ticket>
                </div> -->

                <div class=" pt-1 mb-5 custem-R" id="history">
                    <app-ticket-historique></app-ticket-historique>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="appearModel">
    <div class="modal fade" id="Actions" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  actions-dialog" role="document">
            <div class="modal-content modal-first w-100">
                <div class="modal-header text-center">
                    <div class="row w-100">
                        <div class="col-3">

                        </div>
                        <div class="col-6">
                            <h2 class="text-center title justify-content-center text-nowrap">
                                {{'languages.ticket.updateStatut' | translate}}
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="resetStatus()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body" >
                    <div class="row text-center justify-content-center" *ngIf="!appear && !step">
                        <div class="row">
                            <p class="custemPara">
                                {{'languages.ticket.messageConfirmChangeStatut' | translate}} </p>
                        </div>
                        <div class="row d-flex justify-content-center mt-2 mb-3">
                            <div class="col-6">
                                <button type="button" class="btn-dt-save-popup sizeC mr-3"
                                    [allowed_to_update]="allowed_to_update_affaire" (click)="updateTicket()"
                                    data-dismiss="modal" aria-label="Close">
<i class="fa-solid fa-circle-check fa-lg mr-1 mt-1 fa-icon-style"></i>
                                    {{'languages.affaire.saveQuit' | translate}}
                                </button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="changeStatus" (click)="appearListStatut()">
                                    <span class="mb-1">{{'languages.affaire.changeStatut' | translate}}</span>
                                    <i class="fa-solid fa-arrow-right fa-icon-style   ml-3 fa-lg"></i>
                                </button>
                            </div>



                        </div>
                    </div>
                    <div class="row text-center justify-content-center paddingBlock" *ngIf="appear ">
                        <ng-container *ngIf="isLoading">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <div
                                        style="width: min-content; margin: auto; margin-bottom: 50px; margin-top: 50px">
                                        <mat-spinner [diameter]="30"></mat-spinner>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="row text-center justify-content-center w-100" *ngIf="!isLoading ">

                            <div class="col w-100 paddingBlock scrollAp">
                                <div class="row w-100">

                                    <div class="col-12 w-100">
                                        <div class="row w-100 justify-content-center mt-3">
                                            <ng-container >
                                                <div class="col-3 w-100 justify-content-center" *ngFor="let status of statusList">

                                                    <label class="ticket-status cursor-pointer ml-2  "
                                                        (click)="changeStatus(status.libelle,status.color,status.id,status.icon)"
                                                        [ngStyle]="{'background':status?.background_color}"
                                                        data-dismiss="modal">
                                                        <span [ngStyle]="{'color':status?.color}"
                                                            class="pt-1 text-center"
                                                            Style="font-family: 'Nunito Sans'; margin: auto;">
<i *ngIf="status.icon && status.icon !== 'None'" class=" {{status?.icon}} " ></i>
                                                           {{status.libelle.split('(')[0].trim()}}
                                                        </span>
                                                    </label>
                                                </div>
                                            </ng-container>

                                        </div>
                                        <div class="row w-100 mt-3 justify-content-center">

                                            <button type="reset" class="btn-dt-cancel-popup" data-dismiss="modal"
                                                aria-label="Close" (click)="resetStatus()">
                                                <i class="fa-solid fa-circle-xmark fa-icon-style mr-1  fa-lg"></i>
                                                 {{'languages.buttons.annuler' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
