<div class="card bg-white p-3 border-0">
    <div class="card-doc-title ml-3 row" [ngClass]="{'cover': !allowed_to_update_affaire}">
        <i class="fa-solid fa-circle-info mr-2 fa-lg mt-2"></i>
        {{'languages.SigafConfig.infoValidProv' | translate}}
    </div>
    <!-- <div class="row p-3">
        <div class="col-6 pr-0">
            <button class="button-info left-btn w-100" [ngClass]="{'button-style text-white': complementaire}" (click)="getInfoComp()">
                {{'languages.opportunite.details.infoCompCampagne' | translate}} :
            </button>
        </div>
        <div class="col-6 pl-0">
            <button class="button-info right-btn w-100" [ngClass]="{'button-style text-white': specifique}" (click)="getInfoSpec()">
                {{'languages.opportunite.details.infoCompLigne' | translate}} :
            </button>
        </div>
    </div> -->
    <div class="col-12 mt-3">
        <div class="survey-container">
            <survey [model]="surveyModel"></survey>
        </div>
        <ng-container *ngIf="loading">
            <div class="row mb-3 mt-3">
                <div class="text-center m-auto ">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>
        <!-- <ng-container *ngIf="fieldsspecif.length>0  && !loading">
            <form [formGroup]="form" [ngClass]="{'cover': !allowed_to_update_affaire}">
                <div class="row">
                    <div class="col-12">
                        <formly-form (click)="submitInfoSpecifique()" [model]="modelspecif" [fields]="fieldsspecif" [options]="options" [form]="formSpecifique"></formly-form>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="loading">
            <div class="row mb-3 mt-3">
                <div class="text-center m-auto ">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="fieldsspecif.length<1  && !loading">
            <p class="text-center mt-2">{{'languages.opportunite.details.Noinformations' | translate}}</p>
        </ng-container> -->

    </div>

<!--     <button  type="button" class="btn-dt-save mt-2 "    (click)="getMotifByStatutActuel()" >
             
 
        {{'languages.buttons.decisionFinal' | translate}}
    
        
    </button> -->
    <!-- <div class="col-12 mt-3" *ngIf="complementaire">
        <ng-container *ngIf="fieldscomp.length>0 && !loading">
            <form [formGroup]="form" [ngClass]="{'cover': !allowed_to_update_affaire}">
                <div class="row">
                    <div class="col-12">
                        <formly-form class="info-complementaire-affaire-disabled" [model]="modelcomp" [fields]="fieldscomp" [options]="options" [form]="formComplementaire"></formly-form>
                    </div>
                </div>
            </form>
        </ng-container>
        <ng-container *ngIf="loading">
            <div class="d-flex justify-content-center">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="fieldscomp.length<1 && !loading">
            <p class="text-center mt-2"><strong>{{'languages.opportunite.details.Noinformations' | translate}} </strong></p>
        </ng-container>
    </div> -->
</div>
