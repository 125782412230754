<div class="row" style="margin-top: 15px">
    <!-- <div class="col-6">
        <div class="form-group" style="margin-bottom: 0px;">

            <mat-form-field  class="full-width">
                <mat-label> {{'languages.campagne.Organisme' | translate}}</mat-label>
                <ng-container class="p-3" *ngIf="listOrganisme?.length === 0">
                    <div class="row">
                        <div class="text-center m-auto">
                            <mat-spinner [diameter]="15"></mat-spinner>
                        </div>
                    </div>
                </ng-container>
                <input type="text" matInput [formControl]="organismeFormControl"  (change)="getOrganisme($event.target.value)" [matAutocomplete]="auto">
                <mat-autocomplete autoActiveFirstOption [displayWith]="displayOrganismeFn" #auto="matAutocomplete"  (optionSelected)="getOrganisme($event.option.value)">
                    <mat-option *ngFor="let option of allOrganisme | async" [value]="option">
                        {{option.nom}}
                    </mat-option>
                </mat-autocomplete>

            </mat-form-field>
        </div>

    </div> -->
    <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field class="w-100">
                    <mat-label>{{'languages.opportunite.label_listcreators' | translate}}</mat-label>
                    <ng-container class="p-3" *ngIf="allCreateur?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #ListeCreateur>
                        <mat-chip *ngFor="let createur of ListCreateur" [selectable]="selectable" [removable]="removable" (removed)="removeCreateur(createur)" (removed)="EmitFilterUtilisateur()">
                            {{createur}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>

                        </mat-chip>
                        <input (click)="EmitFilterUtilisateur()" placeholder="{{'languages.opportunite.label_listcreators_select' | translate}}" #CreateurInput [formControl]="CreateurFromCtrl" [matAutocomplete]="autoCreateur" [matChipInputFor]="ListeCreateur" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoCreateur="matAutocomplete" (optionSelected)="selectedCreateur($event)">
                        <mat-option *ngFor="let createur of filteredCreateur | async" [value]="createur.id">
                            {{createur.nom +" "+createur.prenom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<div class="row" style="margin-top: -12px;">
    <!-- <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field class="w-100">
                    <mat-label>Services</mat-label>
                    <ng-container class="p-3" *ngIf="allService?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #Services>
                        <mat-chip *ngFor="let service of ListService" [selectable]="selectable" [removable]="removable" (removed)="removeService(service)" (removed)="EmitFilterUtilisateur()">
                            {{service}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                        </mat-chip>
                        <input (click)="EmitFilterUtilisateur()" placeholder="{{'languages.opportunite.label_services_select' | translate}}" #serviceInput [formControl]="serviceFromCtrl" [matAutocomplete]="autoService" [matChipInputFor]="Services" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoService="matAutocomplete" (optionSelected)="selectedService($event)">
                        <mat-option *ngFor="let service of filteredService | async" [value]="service.id">
                            {{service.libele}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div> -->
    <!-- <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field class="w-100">
                    <mat-label>Zone</mat-label>
                    <ng-container class="p-3" *ngIf="allZones?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #Zone>
                        <mat-chip *ngFor="let Zone of ListZones" [selectable]="selectable" [removable]="removable" (removed)="removeZones(Zone)" (removed)="EmitFilterUtilisateur()">
                            {{Zone}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                        </mat-chip>
                        <input (click)="EmitFilterUtilisateur()" placeholder="{{'languages.opportunite.label_zone_select' | translate}}" #ZonesInput [formControl]="zoneFromCtrl" [matAutocomplete]="autoZones" [matChipInputFor]="Zone" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoZones="matAutocomplete" (optionSelected)="selectedZones($event)">
                        <mat-option *ngFor="let Zone of filteredZones | async" [value]="Zone.id">
                            {{Zone.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div> -->

</div>
<!-- <div class="row" style="margin-top: -11px;">
    <div class="col-2">
        <div class="form-group" style="margin-bottom: 0px;">
            <label></label>
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -10px;">
                <mat-select (selectionChange)="EmitFilterUtilisateur()"
                    [(ngModel)]="filterUtilisateur.operateur_recherche" placeholder="Opérateur">
                    <mat-option value="OU">
                        Ou
                    </mat-option>
                    <mat-option value="ET">
                        Et
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="col-10">
        <div class="form-group">
            <ng-container *ngIf="allGroupe.length > 0">
                <mat-form-field class="w-100">
                    <mat-label>Groupes</mat-label>
                    <mat-chip-list #Groupes>
                        <mat-chip *ngFor="let groupe of listGroupe" [selectable]="selectable" [removable]="removable"
                            (removed)="removeGroupe(groupe)" (removed)="EmitFilterUtilisateur()">
                            {{groupe}}
                            <i class="fa-solid fa-circle-xmark" matChipRemove *ngIf="removable" style="color: gray;"></i>
                        </mat-chip>
                        <input (click)="EmitFilterUtilisateur()" placeholder="Selectionner Groupes ..." #groupeInput
                            [formControl]="groupeFromCtrl" [matAutocomplete]="autoGroupe" [matChipInputFor]="Groupes"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoGroupe="matAutocomplete" (optionSelected)="selectedGroupe($event)">
                        <mat-option *ngFor="let groupe of filteredGroupe | async" [value]="groupe.id">
                            {{groupe.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div> -->
