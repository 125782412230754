
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Optional } from '@angular/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Configuration } from '../configuration';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BASE_PATH } from '../variables';


@Injectable({
  providedIn: 'root'
})
export class WebmailService {
  protected basePath = environment.api_url;
  protected PathAdresses = environment.PathAdresses;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  tab :number;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }



  /**
* get list Email

* @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
* @param reportProgress flag to report request and response progress.
*/
  public getListEmail(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (this.configuration.apiKeys['idSession'] === null || this.configuration.apiKeys['idSession'] === undefined) {
      throw new Error('Required parameter idSession was null or undefined when calling getListEmail.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/webmail/all_mails?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }


  /**
* get list Folder Email

* @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
* @param reportProgress flag to report request and response progress.
*/
public isConfigured(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

  let headers = this.defaultHeaders;

  // authentication (Acess_Token) required
  if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
    headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
  }

  // to determine the Accept header
  let httpHeaderAccepts: string[] = [];
  const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
  if (httpHeaderAcceptSelected != undefined) {
    headers = headers.set('Accept', httpHeaderAcceptSelected);
  }


  const consumes: string[] = [];

  return this.httpClient.request<any>('get', `${this.basePath}/webmail/configured?`,
    {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    }
  );
}


  /**
* get list Folder Email

* @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
* @param reportProgress flag to report request and response progress.
*/
/* public getListFolfder(observe: any = 'body', reportProgress: boolean = false): Observable<any> {



  let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
  let headers = this.defaultHeaders;

  // authentication (Acess_Token) required
  if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
    headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
  }

  // to determine the Accept header
  let httpHeaderAccepts: string[] = [];
  const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
  if (httpHeaderAcceptSelected != undefined) {
    headers = headers.set('Accept', httpHeaderAcceptSelected);
  }


  const consumes: string[] = [];

  return this.httpClient.request<any>('get', `${this.basePath}/webmail/mail_folders?`,
    {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    }
  );
} */



  /**
   * Permet de faire la correspandance des dossiers
   *
   * @param Object dossiers
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
   public correspandanceDossiers(dossiers: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/webmail/folders_correspondence?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: dossiers
      }
    );
  }






}
