<form (keyup.enter)="submitFilterEnter($event)">
  <div class="filter-bubble">

        <div class="filter-bubble-icon" (click)="toggleFilterCorp()">
            <i class="fa-light fa-filter fa-lg mt-2" *ngIf="collapseFilterBox"></i>
            <i class="fa-light fa-xmark fa-lg mt-2" *ngIf="!collapseFilterBox"></i>
        </div>
      </div>
  <div class="filter-corp" [ngClass]="{'collapse_filter_corp':collapseFilterBox}">
    <div class="row m-0">

      <button type="button" class="filtrerBtnHeader mb-2 w-100">
        <!-- <img (click)="toggleFilterCorp()" class="fa fa-lg float-left ml-2"
          src="assets/icons/filter-circle-xmark-solid.svg" />&nbsp; -->
        {{'languages.buttons.filterSearch' | translate}}
      </button>
    </div>

    <div class="ml-1">

      <div class="row">
        <div class="col-6">
          <div class="row  align-self-center  float-left">
            <button type="button" class="btn-load" type="button" (click)="resetForm()">
                <i class="fa-solid fa-rotate-right  fa-lg mt-2"></i>
            </button>
             <app-list-filter-saved [entity]="'A'" (filterOpportunite)="getFilterFromCollection($event)"  (reloadParentEvent)="closefilter($event)"></app-list-filter-saved>

          </div>
        </div>
        <div class="col-6">
          <div class="row align-self-center float-right">


            <app-save-filtre [filterOpportunite]="filterAffaires" [entity]="'A'"></app-save-filtre>
            <button type="submit" class="ml-2 btn-dt-save-Filtre" (click)="submitFilter()">
                <i class="fa-solid fa-magnifying-glass fa-lg mt-2"></i>
              {{'languages.buttons.startseach' | translate}}
            </button>
          </div>
        </div>
      </div>
      <hr>
            <div class="scrolling"  id="style-1">
                <mat-tab-group mat-align-tabs="center" style="z-index: 0;">
                    <mat-tab label="{{'languages.navbar.Affaires' | translate}}">
                        <div class="row mb-2 mt-3" >

                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="row w-100 d-flex justify-content-center" >
                                                        <div class="row filtre-spn">
<i class="fa-solid fa-briefcase fa-lg mr-2 custem-margin"></i>
                                                                <span>  {{'languages.navbar.Affaires' | translate}}</span>
                                                        </div>
                                                  </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filtre-affaire  class="w-100" (onPushFilterAffaire)="EmitFilterAffaire($event)"
                                                (returnToparent)="getResetAffaire($event)" [Reset]="onResetAffaire"
                                            >
                                            </app-filtre-affaire>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>

                        <div class="row mb-2" >
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="row w-100 d-flex justify-content-center">
                                                    <div class="row filtre-spn" >
<i class="fa-solid fa-user fa-lg custem-margin mr-2"></i>
                                                        <span> {{'languages.affaire.label_client' | translate}}</span>
                                                    </div>
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3">
                                            <app-filtre-user class="w-100" (onPushFilterUtilisateur)="EmitFilterUtilisateur($event)"
                                                (returnToparent)="getResetUtilisateur($event)"
                                                [Reset]="onResetUtilisateur" >
                                            </app-filtre-user>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>

                  <!--       <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                    <div class="row filtre-spn" >
<i class="fa-solid fa-user-tie fa-lg mr-2 custem-margin"></i>
                                                        <span> {{'languages.affaire.label_client' | translate}}</span>
                                                    </div>
                                                </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filtre-prospect class="w-100"  (onPushFilterProspect)="EmitFilterProspect($event)"
                                                (returnToparent)="getResetProspect($event)" [Reset]="onResetProspect"
                                              >
                                            </app-filtre-prospect>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div> -->


                        <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                   <div class="row filtre-spn" >
<i class="fa-solid fa-lightbulb fa-lg custem-margin mr-2"></i>
                                                      <span>{{'languages.opportunite.statusactuels' | translate}}</span>
                                                    </div>
                                              </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filtre-statut-actuel
                                                (onPushFilterStatutActuel)="EmitFilterStatutActuel($event)" (returnToparent)="getResetStatutActuel($event)" [Reset]="onResetStatutActuel"
                                                 class="w-100">
                                            </app-filtre-statut-actuel>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>
                        <!-- <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                    <div class="row filtre-spn" >
<i class="fa-solid fa-medal fa-lg custem-margin mr-2"></i>
                                                          <span>{{'languages.settings.infoBlocQualite' | translate}}</span>
                                                    </div>
                                                </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-bloc-qualite class="w-100"  (onPushFilterBlocQualite)="EmitFilterQualite($event)"
                                            (returnToparent)="getResetQualite($event)" [Reset]="onResetQualite"
                                          >
                                            </app-bloc-qualite>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div> -->
                    </mat-tab>
                    <!-- <mat-tab label="{{'languages.opportunite.opportunites' | translate}}">
                        <div class="row mb-2 mt-3" >

                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                <div class="row filtre-spn" >
<i class="fa-solid fa-bullhorn fa-lg mt-2 mr-2"></i>
                                                    <span>  {{'languages.opportunite.opportunites' | translate}}</span>
                                                </div>
                                              </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filter-opportunity-affaire
                                                (onPushFilterOpportunityAffaire)="EmitFilterOpportunityAffaire($event)"
                                                (returnToparent)="getResetOpportunityAffaire($event)"
                                                [Reset]="onResetOpportunityAffaire" class="w-100">
                                            </app-filter-opportunity-affaire>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>


                        <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                      <div class="row filtre-spn" >
<i class="fa-solid fa-user fa-lg mt-2 mr-2"></i>
                                                              <span>{{'languages.opportunite.utilisateurs' | translate}}</span>
                                                      </div>
                                                </div>


                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3">
                                            <app-filter-user-opp style="width: 100%;" class="w-100" (returnToparent)="getResetUtilisateurOpp($event)"
                                                [Reset]="onResetUtilisateurOpp"
                                                (onPushFilterUtilisateurOpp)="EmitFilterUtilisateurOpp($event)"
                                               class
                                               >
                                            </app-filter-user-opp>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>

                        <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                    <div class="row filtre-spn" >
<i class="fa-solid fa-lightbulb fa-lg mt-2 mr-2"></i>
                                                          <span>{{'languages.opportunite.statusActuel' | translate}}</span>
                                                    </div>
                                                </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filter-statut-actuel-opp class="w-100"
                                            (returnToparent)="getResetStatutActuelOpp($event)"
                                                [Reset]="onResetStatutActuelOpp"
                                                (onPushFilterStatutActuelOpp)="EmitFilterStatutActuelOpp($event)">
                                            </app-filter-statut-actuel-opp>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>
                    </mat-tab> -->
                </mat-tab-group>
            </div>
        </div>
    </div>
</form>
