import { environment } from 'src/environments/environment';
import { FilterAffaires, Opp, global } from './../../../entity/Affaires/FilterAffaires';
import {
  Component,
  OnInit,
  LOCALE_ID,
  HostListener,
  ViewChild,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiAffairesService } from '../../../services/ApiAffaires/api-affaires.service';
import { AffairesList } from '../../../entity/Affaires/AffairesList';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { ApiVoIPService } from 'src/app/services/ApiVoIP/api-voip.service';
import { ApiProspectsService } from 'src/app/services/ApiProspects/api-prospects.service';
import {
  formatDateToLocal,
  UniformiserDateToLocal,
  UniformiserDateToLocalSansHeure,
  parseDate,
} from 'src/app/shared/Utils/sharedFunctions';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbdmodalContent } from 'src/app/components/add-affaire-modal/add-affaire-modal.component';
import { ActionsGroupeeComponent } from 'src/app/components/actions-groupee/actions-groupee.component';
import { max } from 'moment';
import { AccessList } from 'src/app/entity/accessList';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
import { isEqual, differenceWith } from 'lodash';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiDemandesService } from 'src/app/services/ApiDemandes/api-demandes.service';
class BodyAffaireUpdated {
  action_statut: boolean;
  statut: any;
  list_affaires: any;
}
@Component({
  selector: 'app-affaires',
  templateUrl: './affaires.component.html',
  styleUrls: ['./affaires.component.css'],
  providers: [DatePipe],
})
export class AffairesComponent implements OnInit, AfterViewInit {
  is_tasks: boolean;
  show_loading_add: boolean = false;
  all_filtres: global = new global();
  all_filtresInitial: global = new global();
  affairesList: AffairesList = new AffairesList();
  limit = 15;
  page = 1;
  intervale = 1;
  sSearch: string = '';
  totalPages = 0;
  pages = 1;
  // qsort_field: string = 'default';
  sort_field: string = 'affaire_date_creation';
  sort_type: string = 'desc';
  dateForm: FormGroup;
  date_debut: any = '';
  date_fin: any = '';
  myDate = new Date();
  list_tel = {
    mobile: '',
    tel: '',
    tel2: '',
  };

  @ViewChild('Effet') Effet: MatDateRangePicker<any>;
  filtre: any = null;
  isEdit: boolean = false;
  selected = 'effet';
  date_fin_init: string;
  date_debut_init: string;
  isGlobalFilterChange: boolean = false;
  currency: string = environment.currentCurrency;
  formatCurrency: string = environment.formatCurrency;
  checklist: any[] = [];
  checkedList: any;
  checkedListAffaire:any
  masterSelected: boolean;
  actionButton: boolean = false;
  accessList: AccessList;
  act_grp_communication: boolean;
  act_grp_negociateur: boolean;
  act_grp_pubpostage: boolean;
  act_grp_statut: boolean;
  act_grp_tags: boolean;
  actionGroupeAccess: boolean;
  alerts: any;
  buttons: any;
  navbar: any;
  totalLength: number = 0;
  filterSsearch: boolean = false;
  readonly rangeDateEffet = RangeDateComponent;
  inputSubject = new Subject<string>();
  roleId = localStorage.getItem('id_role') || null;
  userId = localStorage.getItem('id_user') || null;
  TotaleDemande: any;
  showFiltreDetaille: boolean = false;
  natureDemande: string = '';
  TypeDemdande: any;
  isEPF: any;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private apiAffaireService: ApiAffairesService,
    private apiProspectsService: ApiProspectsService,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiVoIPService: ApiVoIPService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private apiAuthentificationService: ApiAuthentificationService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private apiDemandeService: ApiDemandesService
  ) {
    this.inputSubject.pipe(debounceTime(1000)).subscribe((inputValue: string) => {
      this.getDataValuee(inputValue);
    });
  }

  onInputChange(value: string): void {
    this.inputSubject.next(value);
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event: Event) {
    localStorage.removeItem('filtreAffaire');
    localStorage.removeItem('page');
    localStorage.removeItem('sort_field');
    localStorage.removeItem('sort_type');
    localStorage.removeItem('sSearch');
    localStorage.removeItem('natureDemande');
  }

  collapseFromParent: boolean;
  @ViewChild('myDiv') myDiv: ElementRef;
  ngAfterViewInit() {
    this.renderer.listen(this.myDiv.nativeElement, 'click', () => {
      this.collapseFromParent = true;
      // Ajoutez votre logique ici
    });
  }

  getCollapseValue(event) {
    this.collapseFromParent = event;
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.sharedMenuObserverService.updateMenu(object.navbar.Affaires);
        this.navbar = object.navbar;
      });
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.sharedMenuObserverService.updateMenu(object.navbar.Affaires);
      this.navbar = object.navbar;
    });
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {
      this.accessList = value;
      this.act_grp_communication = this.accessList.act_grp_communication;
      this.act_grp_pubpostage = this.accessList.act_grp_pubpostage;
      this.act_grp_statut = this.accessList.act_grp_statut;
      this.act_grp_tags = this.accessList.act_grp_tags;

      if (this.act_grp_tags && this.act_grp_statut && this.act_grp_pubpostage) {
        this.actionGroupeAccess = true;
      } else {
        this.actionGroupeAccess = false;
      }
    });
    this.selected = 'Creation';
    this.all_filtres.aff.affaire.type_date = 'Creation';
    this.date_debut = this.datePipe.transform(
      new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate()),
      'yyyy-MM-dd'
    );
    this.date_fin = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');

    this.filtre = localStorage.getItem('filtreAffaire');
    const page = localStorage.getItem('page');
    const sort_fields = localStorage.getItem('sort_field');
    const sort_types = localStorage.getItem('sort_type');
    const sSearchs = localStorage.getItem('sSearch');
    if (localStorage.getItem('natureDemande')) {
      this.natureDemande = localStorage.getItem('natureDemande');
    }

    if (sort_fields !== null) {
      this.sort_field = sort_fields;
    }
    if (sort_types !== null) {
      this.sort_type = sort_types;
    }

    if (page !== null) {
      this.page = parseInt(page);
    }
    if (sSearchs) {
      this.sSearch = sSearchs;

      this.getListAffaireSearch();
    } else if (this.filtre !== null) {
      this.all_filtres = JSON.parse(this.filtre);
      if (!this.all_filtres.aff.affaire.pose_du_creation && this.all_filtres.aff.affaire.date_effet_dossier_du) {
        this.selected = 'effet';
        this.all_filtres.aff.affaire.type_date = 'effet';
        this.date_debut = this.all_filtres.aff.affaire.date_effet_dossier_du;
        this.date_fin = this.all_filtres.aff.affaire.date_effet_dossier_au;
      } else {
        this.selected = 'Creation';
        this.all_filtres.aff.affaire.type_date = 'Creation';
        this.date_debut = this.all_filtres.aff.affaire.pose_du_creation;
        this.date_fin = this.all_filtres.aff.affaire.pose_au_creation;
      }
      this.getListAffaires();
      // const date_debutt = new Date(this.all_filtres?.aff?.affaire?.date_effet_dossier_du);
      // const date_finn = new Date(this.all_filtres?.aff?.affaire?.date_effet_dossier_au);
      // if (date_debutt.getDate()) this.date_debut = this.datePipe.transform(date_debutt, 'yyyy-MM-dd');
      // if (date_finn.getDate()) this.date_fin = this.datePipe.transform(date_finn, 'yyyy-MM-dd');
    } else {
      this.getDataValue('init');
    }
  }

  openDateeffet() {
    if (this.Effet) {
      this.Effet.open();
    }
  }

  clearDateeffet() {
    this.date_debut = null;
    this.date_fin = null;
  }

  ngOnDestroy() {
    if (this.isEdit === false) {
      localStorage.removeItem('filtreAffaire');
      localStorage.removeItem('page');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('sort_type');
      localStorage.removeItem('sSearch');

      localStorage.removeItem('natureDemande');
      localStorage.removeItem('savedFilter');
      localStorage.removeItem('savedCollection');
    } else {
      localStorage.setItem('filtreAffaire', JSON.stringify(this.all_filtres));
      localStorage.setItem('sSearch', this.sSearch);
      localStorage.setItem('natureDemande', this.natureDemande);
    }

    if (this.router.url.includes('tasks-list')) this.all_filtres.aff.statut_actuel.status = [];
  }

  getDataValue(value) {
    localStorage.removeItem('page');
    this.page = 1;
    this.date_debut_init = this.datePipe.transform(
      new Date(this.myDate.getFullYear(), this.myDate.getMonth() - 1, this.myDate.getDate()),
      'yyyy-MM-dd'
    );
    this.filterSsearch = false;
    this.all_filtresInitial.aff.affaire.pose_du_creation = null;
    this.all_filtresInitial.aff.affaire.pose_au_creation = null;
    this.date_fin_init = this.datePipe.transform(this.myDate, 'yyyy-MM-dd');
    this.sSearch = '';
    if (value === 'init') {
      this.setDates(null, null, this.date_debut, this.date_fin);
    } else if (value === 'submit') {
      if (this.isObjectEqual()) {
        this.setDates(null, null, this.date_debut_init, this.date_fin_init);
      } else if (
        this.all_filtres.aff.affaire.pose_du_creation &&
        this.all_filtres.aff.affaire.pose_au_creation &&
        (this.all_filtres.aff.affaire.pose_du_creation !== this.date_debut_init ||
          this.all_filtres.aff.affaire.pose_au_creation !== this.date_fin_init)
      ) {
        this.date_debut = this.all_filtres.aff.affaire.pose_du_creation;
        this.date_fin = this.all_filtres.aff.affaire.pose_au_creation;
      } else if (
        this.all_filtres.aff.affaire.date_effet_dossier_du &&
        this.all_filtres.aff.affaire.date_effet_dossier_au
      ) {
        this.date_debut = this.all_filtres.aff.affaire.date_effet_dossier_du;
        this.date_fin = this.all_filtres.aff.affaire.date_effet_dossier_au;
      } else {
        this.setDates(null, null, null, null);
      }
    } else if (value === 'affaire') {
      if (this.isObjectEqual()) {
        this.setDates(null, null, this.date_debut_init, this.date_fin_init);
      } else {
        this.setDates(
          this.all_filtres.aff.affaire.date_effet_dossier_du,
          this.all_filtres.aff.affaire.date_effet_dossier_au,
          this.all_filtres.aff.affaire.pose_du_creation,
          this.all_filtres.aff.affaire.pose_au_creation
        );
      }

      if (!this.all_filtres.aff.affaire.pose_du_creation && this.all_filtres.aff.affaire.date_effet_dossier_du) {
        this.selected = 'effet';
        this.all_filtres.aff.affaire.type_date = 'effet';
        this.date_debut = this.all_filtres.aff.affaire.date_effet_dossier_du;
        this.date_fin = this.all_filtres.aff.affaire.date_effet_dossier_au;
      } else {
        this.selected = 'Creation';
        this.all_filtres.aff.affaire.type_date = 'Creation';
        this.date_debut = this.all_filtres.aff.affaire.pose_du_creation;
        this.date_fin = this.all_filtres.aff.affaire.pose_au_creation;
      }
    } else if (value === 'reset') {
      this.all_filtres = new global();
      this.selected = 'Creation';
      this.all_filtres.aff.affaire.type_date = 'Creation';
      this.setDates(null, null, this.date_debut_init, this.date_fin_init);
    } else {
      if (this.selected === 'Creation') {
        this.setDates(null, null, this.date_debut, this.date_fin);
        this.all_filtres.aff.affaire.type_date = 'Creation';
      } else {
        this.all_filtres.aff.affaire.type_date = 'effet';

        this.setDates(this.date_debut, this.date_fin, null, null);
      }
    }
    this.getListAffaires();
  }

  setDates(debut, fin, debutCreation, finCreation) {
    this.all_filtres.aff.affaire.pose_du_creation = this.datePipe.transform(debutCreation, 'yyyy-MM-dd');
    this.all_filtres.aff.affaire.pose_au_creation = this.datePipe.transform(finCreation, 'yyyy-MM-dd');

    this.all_filtres.aff.affaire.date_effet_dossier_du = this.datePipe.transform(debut, 'yyyy-MM-dd');
    this.all_filtres.aff.affaire.date_effet_dossier_au = this.datePipe.transform(fin, 'yyyy-MM-dd');
  }

  isObjectEqual() {
    const differences = differenceWith([this.all_filtres, this.all_filtresInitial]);
    return isEqual(this.all_filtresInitial, this.all_filtres);
  }

  formatDate(date: Date): string {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }

  isNouvelles:any
  exportDataToCsv() {
    const now = new Date();
    const datenow = this.formatDate(now);
    this.apiAffaireService.exportToCsv(this.affairesList.aaData, `${this.TypeDemdande}_${datenow}.csv`);
  }

  getListAffaires() {
    const isExistant = this.router.url.includes('epf-existant');

    if (isExistant) {
      this.all_filtres.aff.affaire.contrat.push(environment.existant);
      this.sharedMenuObserverService.updateMenu(this.navbar.epfExistant);
      this.TypeDemdande = this.navbar.epfExistant;
    }
    const isAnnexe = this.router.url.includes('epf-annexes');

    if (isAnnexe) {
      this.all_filtres.aff.affaire.contrat.push(environment.annexe);
      this.sharedMenuObserverService.updateMenu(this.navbar.epfannexes);
      this.TypeDemdande = this.navbar.epfannexes;
    }

    const isDemandeModif = this.router.url.includes('demande-modification');

    if (isDemandeModif) {
      this.all_filtres.aff.affaire.contrat.push(
        environment.demande_modification_EPF_affectant_BO,
        environment.demande_modification_EPF_sans_validation,
        environment.demande_modification_EPF_sans_impact_sur_le_BO
      );
      this.sharedMenuObserverService.updateMenu(this.navbar.demandemodification);
      this.TypeDemdande = this.navbar.demandemodification;
    }
    const isAffaire = this.router.url.includes('affaires');

    if (isAffaire) {
      this.all_filtres.aff.affaire.contrat.push(environment.ligne_prod_creation_epf);
      this.sharedMenuObserverService.updateMenu(this.navbar.Affaires);
      this.TypeDemdande = this.navbar.Affaires;
      this.showFiltreDetaille = true;
    }
    this.isNouvelles = this.router.url.includes('nouvelles-demandes');

    if (this.isNouvelles) {
      this.all_filtres.aff.affaire.contrat.push(environment.ligne_prod_creation_epf);
      this.showFiltreDetaille = false;
      this.all_filtres.aff.statut_actuel.status.push(environment.soumise_statut_id);
      this.sharedMenuObserverService.updateMenu(this.navbar.Affaires + ' ➜ ' + this.navbar.nouvellesdemandes);
      this.TypeDemdande = this.navbar.nouvellesdemandes;
    }

    const isEnCoursTraitement = this.router.url.includes('en-cours-traitement');

    if (isEnCoursTraitement) {
      this.all_filtres.aff.affaire.contrat.push(environment.ligne_prod_creation_epf);

      this.all_filtres.aff.statut_actuel.status.push(
        environment.en_cours_traitement_statut_id,
        environment.validation_provisoir_statut_id,
        environment.en_cours_de_val,
        environment.validation,
        environment.complement_dossier_statut_id,
        environment.complement_soumis_statut_id
      );
      this.sharedMenuObserverService.updateMenu(this.navbar.Affaires + ' ➜ ' + this.navbar.encourstraitement);
      this.TypeDemdande = this.navbar.encourstraitement;
    }

    const isValidee = this.router.url.includes('demandes-validees');

    if (isValidee) {
      this.all_filtres.aff.affaire.contrat.push(environment.ligne_prod_creation_epf);
      this.showFiltreDetaille = false;
      this.all_filtres.aff.statut_actuel.status.push(environment.valide_statut_id);
      this.sharedMenuObserverService.updateMenu(this.navbar.Affaires + ' ➜ ' + this.navbar.demandesvalidees);
      this.TypeDemdande = this.navbar.demandesvalidees;
    }

    this.isEPF = this.router.url.includes('liste-EPF');

    if (this.isEPF) {
      this.all_filtres.aff.statut_actuel.status.push(
        environment.valide_statut_id,
        environment.demande_valide_modif,
        environment.demande_valide_modif_auto
      );

      this.sharedMenuObserverService.updateMenu(this.navbar.listeEPF);
      this.TypeDemdande = this.navbar.listeEPF;
      this.showFiltreDetaille = false;
    }

    const isTasks = this.router.url.includes('tasks-list');
    if (isTasks && localStorage.getItem('id_role') === '45fbc6d3e05ebd93369ce542e8f2322d') {
      this.all_filtres.aff.statut_actuel.status.push(
        environment.affectation_statut_id,
        environment.complement_soumis_statut_id,
        environment.en_cours_traitement_statut_id,
        environment.soumise_statut_id
      );
      this.sharedMenuObserverService.updateMenu(this.navbar.taskslist);
      this.TypeDemdande = this.navbar.taskslist;
      this.showFiltreDetaille = false;
    } else if (isTasks && localStorage.getItem('id_role') === '979d472a84804b9f647bc185a877a8b5') {
      this.all_filtres.aff.statut_actuel.status.push(
        environment.soumise_statut_id,
        environment.validation_provisoir_statut_id,
        environment.valide_statut_id,
        environment.complement_soumis_statut_id,
        environment.en_cours_traitement_statut_id
      );
      this.showFiltreDetaille = false;
    }

    if (isTasks) {
      this.is_tasks = true;
    } else {
      this.is_tasks = false;
    }

    if (this.roleId === 'e96ed478dab8595a7dbda4cbcbee168f' && this.userId) {
      this.all_filtres.aff.utilisateur.recheche_create_user.push(this.userId);
    }

    this.showloader();

    // if (this.selected==="Creation"){
    // this.date_debut =this.all_filtres.aff.affaire.pose_du_creation
    // this.date_fin =this.all_filtres.aff.affaire.pose_au_creation

    // }
    this.apiAffaireService
      .listAffairesGet(
        this.page,
        this.limit,
        this.sort_field,
        this.sort_type,
        this.sSearch,
        this.all_filtres,
        this.natureDemande,
        this.is_tasks
      )
      .subscribe((data: AffairesList) => {
        this.affairesList = data;

        this.TotaleDemande = data?.iTotalDisplayRecords;
        if (this.selected === 'effet') {
          this.date_debut = parseDate(data.min_date_deff);
          this.date_fin = parseDate(data.max_date_deff);
        } else {
          this.date_debut = parseDate(data.min_date_creation);
          this.date_fin = parseDate(data.max_date_creation);
        }

        this.totalLength = data.iTotalDisplayRecords;
        this.calculate_nb_pages(data.iTotalDisplayRecords);
        this.hideloader();
      });
  }

  getDataValuee(event) {
    if (event.length > 0) {
      this.all_filtresInitial.aff.affaire.pose_du_creation = null;
      this.all_filtresInitial.aff.affaire.pose_au_creation = null;
      this.page = 1;
      this.filterSsearch = true;
      this.sSearch = event;
      this.getListAffaireSearch();
    } else if (event.length === 0) {
      this.all_filtresInitial.aff.affaire.pose_du_creation = this.date_debut_init;
      this.all_filtresInitial.aff.affaire.pose_au_creation = this.date_fin_init;
      this.page = 1;
      this.filterSsearch = true;
      this.sSearch = event;
      this.getListAffaireSearch();
    }
  }

  getListAffaireSearch() {
    this.router.navigate([], {
      queryParams: {
        filter: null,
        collection: null,
      },
      queryParamsHandling: 'merge',
    });
    const isExistant = this.router.url.includes('epf-existant');

    if (isExistant) {
      this.all_filtresInitial.aff.affaire.contrat.push(environment.existant);
      this.sharedMenuObserverService.updateMenu(this.navbar.epfExistant);
      this.TypeDemdande = this.navbar.epfExistant;
    }
    const isAnnexe = this.router.url.includes('epf-annexes');

    if (isAnnexe) {
      this.all_filtresInitial.aff.affaire.contrat.push(environment.annexe);
      this.sharedMenuObserverService.updateMenu(this.navbar.epfannexes);
      this.TypeDemdande = this.navbar.epfannexes;
    }

    const isDemandeModif = this.router.url.includes('demande-modification');

    if (isDemandeModif) {
      this.all_filtresInitial.aff.affaire.contrat.push(
        environment.demande_modification_EPF_affectant_BO,
        environment.demande_modification_EPF_sans_validation,
        environment.demande_modification_EPF_sans_impact_sur_le_BO
      );
      this.sharedMenuObserverService.updateMenu(this.navbar.demandemodification);
      this.TypeDemdande = this.navbar.demandemodification;
    }
    const isAffaire = this.router.url.includes('affaires');

    if (isAffaire) {
      this.all_filtresInitial.aff.affaire.contrat.push(environment.ligne_prod_creation_epf);
      this.sharedMenuObserverService.updateMenu(this.navbar.Affaires);
      this.TypeDemdande = this.navbar.Affaires;
      this.showFiltreDetaille = true;
    }
    this.isNouvelles = this.router.url.includes('nouvelles-demandes');

    if (this.isNouvelles) {
      this.all_filtresInitial.aff.affaire.contrat.push(environment.ligne_prod_creation_epf);
      this.showFiltreDetaille = false;
      this.all_filtresInitial.aff.statut_actuel.status.push(environment.soumise_statut_id);
      this.sharedMenuObserverService.updateMenu(this.navbar.Affaires + ' ➜ ' + this.navbar.nouvellesdemandes);
      this.TypeDemdande = this.navbar.nouvellesdemandes;
    }

    const isEnCoursTraitement = this.router.url.includes('en-cours-traitement');

    if (isEnCoursTraitement) {
      this.all_filtresInitial.aff.affaire.contrat.push(environment.ligne_prod_creation_epf);

      this.all_filtresInitial.aff.statut_actuel.status.push(
        environment.en_cours_traitement_statut_id,
        environment.validation_provisoir_statut_id,
        environment.en_cours_de_val,
        environment.validation,
        environment.complement_dossier_statut_id,
        environment.complement_soumis_statut_id
      );
      this.sharedMenuObserverService.updateMenu(this.navbar.Affaires + ' ➜ ' + this.navbar.encourstraitement);
      this.TypeDemdande = this.navbar.encourstraitement;
    }

    const isValidee = this.router.url.includes('demandes-validees');

    if (isValidee) {
      this.all_filtresInitial.aff.affaire.contrat.push(environment.ligne_prod_creation_epf);
      this.showFiltreDetaille = false;
      this.all_filtresInitial.aff.statut_actuel.status.push(environment.valide_statut_id);
      this.sharedMenuObserverService.updateMenu(this.navbar.Affaires + ' ➜ ' + this.navbar.demandesvalidees);
      this.TypeDemdande = this.navbar.demandesvalidees;
    }

    const isEPF = this.router.url.includes('liste-EPF');

    if (isEPF) {
      this.all_filtresInitial.aff.statut_actuel.status.push(
        environment.valide_statut_id,
        environment.demande_valide_modif,
        environment.demande_valide_modif_auto
      );
      this.sharedMenuObserverService.updateMenu(this.navbar.listeEPF);
      this.TypeDemdande = this.navbar.listeEPF;
      this.showFiltreDetaille = false;
    }

    const isTasks = this.router.url.includes('tasks-list');
    if (isTasks && localStorage.getItem('id_role') === '45fbc6d3e05ebd93369ce542e8f2322d') {
      this.all_filtresInitial.aff.statut_actuel.status.push(
        environment.affectation_statut_id,
        environment.complement_soumis_statut_id,
        environment.en_cours_traitement_statut_id,
        environment.soumise_statut_id
      );
      this.sharedMenuObserverService.updateMenu(this.navbar.taskslist);
      this.TypeDemdande = this.navbar.taskslist;
      this.showFiltreDetaille = false;
    } else if (isTasks && localStorage.getItem('id_role') === '979d472a84804b9f647bc185a877a8b5') {
      this.all_filtresInitial.aff.statut_actuel.status.push(
        environment.soumise_statut_id,
        environment.validation_provisoir_statut_id,
        environment.valide_statut_id,
        environment.complement_soumis_statut_id,
        environment.en_cours_traitement_statut_id
      );
      this.showFiltreDetaille = false;
    }

    if (isTasks) {
      this.is_tasks = true;
    } else {
      this.is_tasks = false;
    }

    if (this.roleId === 'e96ed478dab8595a7dbda4cbcbee168f' && this.userId) {
      this.all_filtres.aff.utilisateur.recheche_create_user.push(this.userId);
    }

    this.showloader();
    this.apiAffaireService
      .listAffairesGet(
        this.page,
        this.limit,
        this.sort_field,
        this.sort_type,
        this.sSearch,
        this.all_filtresInitial,
        this.natureDemande,
        this.is_tasks
      )
      .subscribe((data: AffairesList) => {
        this.affairesList = data;

        this.TotaleDemande = data?.iTotalDisplayRecords;
        if (this.selected === 'effet') {
          this.date_debut = parseDate(data.min_date_deff);
          this.date_fin = parseDate(data.max_date_deff);
        } else {
          this.date_debut = parseDate(data.min_date_creation);
          this.date_fin = parseDate(data.max_date_creation);
        }

        this.totalLength = data.iTotalDisplayRecords;
        this.calculate_nb_pages(data.iTotalDisplayRecords);
        this.hideloader();
      });
  }

  quickFilterChange(): void {
    this.isGlobalFilterChange = false;
    this.all_filtres = new global();
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  getDate() {
    this.date_debut = '';
    this.date_fin = '';
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  setSortField(sort_field: string) {
    if (this.sort_field === sort_field) {
      if (this.sort_type === 'desc') {
        this.sort_type = 'asc';
      } else {
        this.sort_type = 'desc';
      }
    } else {
      this.sort_type = 'asc';
    }
    this.sort_field = sort_field;
    localStorage.setItem('sort_field', this.sort_field);
    localStorage.setItem('sort_type', this.sort_type);
    if (this.filterSsearch === true) {
      this.getListAffaireSearch();
    } else {
      this.getListAffaires();
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.page - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.page + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.page = val;
    if (this.filterSsearch === true) {
      this.getListAffaireSearch();
    } else {
      this.getListAffaires();
    }
  }

  isFirst(): boolean {
    return this.page === 1;
  }

  isLast(): boolean {
    return this.page === this.totalPages;
  }

  updateListAffaires() {
    this.page = 1;
    if (this.filterSsearch === true) {
      this.getListAffaireSearch();
    } else {
      this.getListAffaires();
    }
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.page = this.intervale;
      if (this.page <= this.totalPages) {
        if (this.filterSsearch === true) {
          this.getListAffaireSearch();
        } else {
          this.getListAffaires();
        }
      }
    }
  }

  changepage(number: number) {
    this.page = number;
    localStorage.setItem('page', number.toString());
    if (this.filterSsearch === true) {
      this.getListAffaireSearch();
    } else {
      this.getListAffaires();
    }
  }

  EmitFilterAffaire($event: global) {
    this.all_filtres = $event;
  }

  getPhoneList(id_prospect: string) {
    this.show_loading_add = true;
    this.apiProspectsService.getProspectTel(id_prospect).subscribe((data) => {
      this.list_tel.mobile = data.list_tel.mobile;
      this.list_tel.tel = data.list_tel.tel;
      this.list_tel.tel2 = data.list_tel.tel2;
      this.show_loading_add = false;
    });
  }

  callVoip(number) {
    this.showloader();
    this.apiVoIPService.callVoipGet(number).subscribe((data) => {
      this.hideloader();
      if (data.etat_ws === 'ERROR Merci de vérifier vos coordonnées d’accès') {
        this.alertErrorVoip();
      }
    });
  }

  add_suivi_affaire(affaire: any) {
    const affaire_id = affaire.affaire_id;
    const contrat_id = affaire.affaire_id_contrat;

    this.apiDemandeService.AddToFavorite(affaire_id, contrat_id).subscribe(
      (response) => {
        console.log('Affaire added to favorites', response);
        affaire.add_to_fav = true;
      },
      (error) => {
        console.error('Error adding affaire to favorites', error);
      }
    );
  }

  /*   add_suivi_affaire(opportunite_id: string) {
       this.apiOpportunitService.addSuiviOppGet(opportunite_id)
      .subscribe((response) => {
        this.alertSuccess(response.message)
      })
  } */

  alertErrorVoip() {
    swal
      .fire({
        title: this.alerts.Voip,
        text: this.alerts.voipNum,
        icon: 'error',
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        cancelButtonText: this.buttons.fermer,
        confirmButtonText: this.buttons.configurer,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/profile']).then(() => {
            window.location.reload();
          });
        }
      });
  }

  AddAffaire() {
    const options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'lg',
      scrollable: true,
      windowClass: 'myCustomModalClass',
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
    };
    const modeldoc = this.modalService.open(NgbdmodalContent, options);
    modeldoc.componentInstance.disabledchamp = false;
    modeldoc.componentInstance.event.subscribe((data: any) => {
      modeldoc.close();
      this.AddAffaire();
    });
  }

  updateAffaire() {
    const options: NgbModalOptions = {
      ariaLabelledBy: 'myBasicModalLabel',
      size: 'lg',
      scrollable: true,
      windowClass: 'my-class custom-modal',
      centered: true,
      animation: true,
      backdrop: 'static',
      keyboard: false,
    };

    const modeldoc = this.modalService.open(ActionsGroupeeComponent, options);
    modeldoc.componentInstance.type = 'affaire';
    modeldoc.componentInstance.listeAffaireid = this.checkedList;
    modeldoc.componentInstance.listeAffaire = this.checkedListAffaire;
    modeldoc.result.then(
      (res) => {},
      (dismiss) => {
        if (dismiss !== '' && dismiss !== undefined) {
          if (dismiss === 'success'){
            if (this.filterSsearch === true) {
              this.getListAffaireSearch();
            } else {
              this.getListAffaires();
            }
          }
          else if (dismiss !== 'error') {
            this.alertSuccesss(dismiss);
          } else {
            this.alertError(this.buttons.permissiondenied);
          }
        }
    
      }
    );
  }

  alertError(data) {
    swal.fire({
      title: this.alerts.badHappened,
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  checkUncheckAll() {
    for (let i = 0; i < this.affairesList?.aaData?.length; i++) {
      this.affairesList.aaData[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.masterSelected = this.affairesList.aaData.every(function (item: any) {
      return item.isSelected === true;
    });
    this.getCheckedItemList();
  }

  getCheckedItemList() {
    this.checkedList = [];
    this.checkedListAffaire=[]
    for (let i = 0; i < this.affairesList?.aaData?.length; i++) {
      if (this.affairesList.aaData[i].isSelected){
        this.checkedList.push(this.affairesList.aaData[i].affaire_id);
        this.checkedListAffaire.push(this.affairesList.aaData[i]);
      } 
    }
    if (this.checkedList.length > 0) {
      this.actionButton = true;
    } else {
      this.actionButton = false;
    }
  }

  alertSuccesss(data) {
    const total =
      data.nb_modified_status +
      data.nb_modified_add_tags +
      data.nb_modified_delete_tags +
      data.nb_modified_tag_prospect;
    swal
      .fire({
        html: ` <div class='row justify-content-center'>  <div class=' col-8 card-info-1 mb-3 '><p class='p-info-2' style=' padding-top: 13px;font-size: 14px; text-align: center; '>
          ${data.nb_total}  ${this.alerts.selectedElements}. <br>
          ${total}
          ${this.alerts.Modifications_effectuees} :</p></div></div> <div class='row justify-content-center '><div class='col-6'>  <div class='card-info-3 d-flex align-items-center justify-content-center' style='  width: 100%; height: 90px;border-radius: 10px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' ><p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '> <img src='assets/icons/image_2023_01_13T09_16_51_979Z.png' width='20' height='20'> <br>
          ${data.nb_modified_status}
          ${this.alerts.StatusModified}</p><p class='p-info-1' style='font-size: 15px;text-align: center;  font-weight: bold;'>
          </p></div></div><div class='col-6'><div class='card-info-3 d-flex align-items-center justify-content-center'style='  width: 100%;height: 90px;border-radius: 15px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' >  <p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '><img src='assets/icons/image_2023_01_12T14_48_35_211Z.png' width='20' height='20'> <br>
          ${data.nb_modified_add_tags}
          ${this.alerts.keywordsAdded}</p><p class='p-info-1' style='font-size: 15px;text-align: center; font-weight: bold;'>
          </p></div></div></div> <div class='row  justify-content-center' style='margin-top:10px;'><div class='col-6'><div class='card-info-3 d-flex align-items-center justify-content-center' style='  width: 100%;height: 90px;border-radius: 15px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' ><p class='p-info-2'  style=' padding-top: 13px;font-size: 14px;text-align: center; '> <img src='assets/icons/image_2023_01_12T14_47_34_190Z.png' width='20' height='20'><br>
          ${data.nb_modified_delete_tags}
          ${this.alerts.keywordsDeleted}</p><p class='p-info-1' style='font-size: 15px;text-align: center ; font-weight: bold;'>
          </p></div> </div> <div class='col-6' > <div class='card-info-3 d-flex align-items-center justify-content-center'style='  width: 100%;height: 90px;border-radius: 15px; background-color: #E2F4EC;color: #4A9D77;cursor: pointer;' >  <p class='p-info-2'  style='font-size: 14px;text-align: center; padding-top:13px; '><img src='assets/icons/image_2023_01_12T14_54_07_809Z.png' width='20' height='20'> <br>
          ${data.nb_modified_tag_prospect}
          ${this.alerts.prospectKeywordsAdded}</p><p class='p-info-1' style='font-size: 15px;text-align: center; font-weight: bold;'>
          </p></div>  </div> </div>`,
        icon: 'info',
        cancelButtonColor: '#138eb6',
        focusConfirm: true,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: this.buttons.fermer,
      })
      .then((result) => {
        this.hideloader();
        if (this.filterSsearch === true) {
          this.getListAffaireSearch();
        } else {
          this.getListAffaires();
        }
        this.masterSelected = false;
        for (let i = 0; i < this.affairesList?.aaData?.length; i++) {
          this.affairesList.aaData[i].isSelected = false;
          this.actionButton = false;
        }
      });
  }

  goToEdit(affaireId: string) {
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get('filter') !== null && queryParams.get('collection') !== null) {
        localStorage.setItem('savedFilter', queryParams.get('filter'));
        localStorage.setItem('savedCollection', queryParams.get('collection'));
      }
    });
    this.isEdit = true;

    let fromParam = '';
    if (this.router.url.includes('validee')) {
      fromParam = 'validee';
    } else if (this.router.url.includes('en-cours-traitement')) {
      fromParam = 'en-cours-traitement';
    } else if (this.router.url.includes('nouvelles-demandes')) {
      fromParam = 'nouvelles-demandes';
    } else if (this.router.url.includes('demande-modification')) {
      fromParam = 'demande-modification';
    } else if (this.router.url.includes('epf-annexes')) {
      fromParam = 'epf-annexes';
    } else if (this.router.url.includes('epf-existant')) {
      fromParam = 'epf-existant';
    } else if (this.router.url.includes('liste-EPF')) {
      fromParam = 'liste-EPF';
    } else {
      fromParam = 'affaires';
    }

    this.router.navigate(['/affaires/details', affaireId], { queryParams: { from: fromParam } });
  }
}
