import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';

import { TemplateComponent } from './template/template/template.component';

import { RestPasswordComponent } from './rest-password/rest-password.component';
import { TarificateurVeloComponent } from './pages/tarificateur-velo/tarificateur-velo.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: RestPasswordComponent },
  { path: 'tarificateur/:partenerShipid', component: TarificateurVeloComponent },
  { path: 'change-password', component: ChangePasswordComponent },

  {
    path: 'template',
    loadChildren: () => import('./template/template.module').then((m) => m.TemplateModule),
    component: TemplateComponent,
  },
  {
    path: '',
    loadChildren: () => import('./admin-layout/admin-layout.module').then((m) => m.AdminLayoutModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
