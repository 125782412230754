<div class="sidebar" [class.collapsed]="isCollapsed" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
  <div class="sidebar-header">
    <!-- Retain the Sigaf image -->
    <img *ngIf="isCollapsed" (click)="toggleSidebar()" class="mr-1" src="assets/img/sigaf/mini-logo-blanc.png" alt="logo" />
<!--     <img *ngIf="!isCollapsed" class="geoprod mr-2" src="assets/img/sigaf/logo-sigaf-blanc.png" alt="logo" />
 -->    <img *ngIf="!isCollapsed" class="geoprod mr-2" src="assets/img/sigaf/logo-sigaf-blanc.png" alt="logo" style="width: 150px; height: 120px;" />

  </div>
  <div class="toggle-icon" (click)="toggleSidebar()">
    <i class="fa-solid" [ngClass]="{ 'fa-chevron-left': !isCollapsed }"></i>
  </div>
  <ul class="sidebar-links">
    <ng-template #menuTemplate let-item="menuItem">
      <li [ngClass]="{ 'active': item.isActive }" (mouseenter)="onHover(item)" (mouseleave)="onHoverOut(item)">
        <a (click)="toggleSubMenu(item)" [routerLink]="item.path.trim()?.toString() !='' ? [item.path.trim()?.toString()] : []">
          <i style="font-size: 16px;" [class]="item.icon"></i>
          <span class="menu-title" *ngIf="!isCollapsed">{{ item.title }}</span>
          <i *ngIf="!isCollapsed && item.child.length" class="fa-solid" [ngClass]="{'fa-chevron-down': item.expanded, 'fa-chevron-right': !item.expanded}"></i>
        </a>
        <ul *ngIf="(isCollapsed && item.isHovered) || (!isCollapsed && item.child.length)" [@expandCollapse]="item.expanded ? 'expanded' : 'collapsed'">
          <ng-container *ngFor="let child of item.child">
            <ng-template [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ menuItem: child }"></ng-template>
          </ng-container>
        </ul>
      </li>
    </ng-template>
    <ng-container *ngFor="let item of routes">
      <ng-template [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ menuItem: item }"></ng-template>
    </ng-container>
  </ul>
  <hr class="divider">
  <div class="user-profile" [ngClass]="{'collapsed-profile': isCollapsed}">
    <div class="user-detail">
      <i class="fa-light fa-user iconSize"></i>
      <div *ngIf="!isCollapsed" class="d-inline-block text-left text-nowrap" id="ifoname">
        <p class="m-0">{{ expandNameUser(capitalizeFirstLetter(nom), capitalizeFirstLetter(prenom)) }}</p>
      </div>
    </div>
  </div>
</div>
