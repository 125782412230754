/* eslint-disable node/handle-callback-err */
/* eslint-disable prefer-const */
import { ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { ThemePalette } from '@angular/material/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { EtatAffaire } from 'src/app/entity/Bpm/EtatAffaire';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import { map, tap } from 'rxjs/operators';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { ApiComminucationService } from 'src/app/services/ApiComminucation/api-comminucation.service';
import { ClasseStatus } from 'src/app/entity/Bpm/Classe';
import { CommunicationModel } from 'src/app/entity/CommunicationModel';
import { EtatCycle } from 'src/app/entity/Bpm/EtatCycle';
import swal from 'sweetalert2';
import { ServiceAffaire } from 'src/app/entity/Bpm/ServicesAffaire';
import { ApiProduitsService } from 'src/app/services/ApiPoduits/api-poduits.service';
import { Observable, Subscription } from 'rxjs';
import { autocompleteObjectValidator } from 'src/app/shared/Utils/sharedFunctions';
import { TranslateService } from '@ngx-translate/core';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
export class Rules {
  id: string = '';
  label: string = '';
  rule: string = '';
  label_rule_type: string = '';
}
export class Motif {
  libelle;
  color = '';
  background = '';
  status;
  id;
  action?: boolean;
  active: any;
}
export class BodyMotif {
  status: any;
  motifs_inserted: Motif[] = [];
  motifs_updated: Motif[] = [];
}

@Component({
  selector: 'app-fiche-status-affaire',
  templateUrl: './fiche-status-affaire.component.html',
  styleUrls: ['./fiche-status-affaire.component.css'],
})
export class FicheStatusAffaireComponent implements OnInit, OnDestroy, OnChanges {
  detect_change_motif: boolean = false;
  show_loading_add: boolean = false;
  ModeAjout: boolean = true;
  listeOfUnsubscribeWebService: Subscription[] = [];
  id_statut_affaire: string = '';
  public color: ThemePalette = 'primary';
  public colorP: ThemePalette = 'primary';
  colorCtr: AbstractControl = new FormControl(null);
  colorCtrP: AbstractControl = new FormControl(null);
  statut_etat_affaire: EtatAffaire = new EtatAffaire();
  statut_etat_affaireInit: EtatAffaire = new EtatAffaire();
  disableBtn = true;
  show_Contrat_erreur: string = '';
  show_Service_erreur: string = '';
  show_Visa_erreur: string = '';
  show_Depend_erreur: string = '';
  show_Statut_erreur: string = '';
  show_Classe_erreur: string = '';
  show_Post_erreur: string = '';
  show_Mail_erreur: string = '';
  show_Sms_erreur: string = '';
  limit = 15;
  page = 1;
  search: string = '';
  sup: boolean = false;
  agenda: boolean = false;
  alert: boolean = false;
  ticket: boolean = false;
  fiche_dec: boolean = false;
  contratOb: boolean = false;
  serviceOb: boolean = false;
  colorpo: any;
  colori: any;

  list_invalid: any[] = [];

  ModelSms: CommunicationModel[] = [];
  smsData: any;

  ModelMail: CommunicationModel[] = [];
  mailData: any;

  ModelPost: CommunicationModel[] = [];
  postData: any;

  ListRule: Rules[] = [];




  selectedValidationRule: Rules;
selectedProcessingRule: Rules;
selectedRuleIdProcessing: number;
  selectedValidationRuleId: number;



  ListeValidationRules: Rules[] = [];

  ListeProcessingRules: Rules[] = [];

  visa_cat: ServiceAffaire[] = [];
  visaData: any;

  service_cat: ServiceAffaire[] = [];
  serviceData: any;

  contrats: any[] = [];
  contratData: any;

  list_invalid_string: string = '';

  classe_List: ClasseStatus[] = [];
  classeData: any;

  depand_List: EtatCycle[] = [];
  depand: any;

  statusP_List: EtatCycle[] = [];
  statusP: any;
  StatusPData: any;
  controleService: any;
  showParent: boolean = false;
  showDepand: boolean = false;
  showClasse: boolean = false;
  showPost: boolean = false;
  showMail: boolean = false;
  showSms: boolean = false;
  navbarTxt: string = '';
  affecterCycleFormControl: FormControl = new FormControl('', {
    validators: [autocompleteObjectValidator(), Validators.required],
  });

  @ViewChild('autoStatus') autoStatus;
  @ViewChild('autoDep') autoDep;
  @ViewChild('autoContrat') autoContrat;

  @ViewChild('autoService') autoService;

  @ViewChild('autoVisa') autoVisa;

  @ViewChild('autoClasse') autoClasse;

  @ViewChild('autoPost') autoPost;

  @ViewChild('autoMail') autoMail;
  @ViewChild('autoSMS') autoSMS;

  cycleVie_affecte: any;

  listCycleVieFilter: Observable<any[]> = null;
  id_cycle: EtatAffaire;
  alerts: any;
  buttons: any;

  showVisa: boolean;
  showWorkflow: boolean;
  List_motif: Motif[] = [];
  constructor(
    private route: ActivatedRoute,
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiAdminbpm: ApiAdminBpmService,

    private apiAdminBpmServices: ApiAdminBpmService,
    private apiProduitService: ApiProduitsService,
    private chRef: ChangeDetectorRef,
    private apiComminucationService: ApiComminucationService,
    private routes: Router,
    private translate: TranslateService,
    private apiTicketService: ApiTicketsService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.chRef.detectChanges();
  }


  onValidationRuleSelected(event) {
    this.selectedValidationRule = event;
    this.selectedValidationRuleId = event.id;
  }

  onProcessingRuleSelected(event) {
    this.selectedProcessingRule = event;
    this.selectedRuleIdProcessing = event.id;
  }




  ngOnDestroy(): void {
    this.listeOfUnsubscribeWebService.forEach((element) => {
      element?.unsubscribe();
    });
    const currentRoute = this.routes.url;
    if (!currentRoute.includes('gestion-status-affaire')) {
      localStorage.removeItem('search');
      localStorage.removeItem('page');
    }
  }

  async ngOnInit(): Promise<any> {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.alerts = object.alerts;
        this.buttons = object.buttons;
      });
    });

    this.translate.get('languages').subscribe((object: any) => {
      this.alerts = object.alerts;
      this.buttons = object.buttons;
    });

    this.route.paramMap.subscribe((params) => {
      if (params.get('id_Status') !== 'nouveau') {
        this.showloader();
        this.id_statut_affaire = params.get('id_Status');
        this.ModeAjout = false;
        const unsubscribeGetAffaireStatutById = this.apiAdminBpmServices
          .getAffaireStatutById(this.id_statut_affaire)
          .pipe(
            tap(({ etat_dossier }) => {
              this.statut_etat_affaire = etat_dossier;
              this.id_cycle = etat_dossier.cycle_prod_md5;
              Object.assign(this.statut_etat_affaireInit, this.statut_etat_affaire);
              this.getAll();

              this.initForm();
              this.hideloader();
            })
          )
          .subscribe();
        this.listeOfUnsubscribeWebService.push(unsubscribeGetAffaireStatutById);
        this.apiAdminBpmServices.getListMotifStatut(this.id_statut_affaire).subscribe((data) => {
          this.List_motif = data.Motifs;
        });
      } else {
        this.getAll();
        this.hideloader();
      }
    });
    if (this.ModeAjout) {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt = object.navbar.gestionStatutsAffaire + ' ➜ ' + object.navbar.Ajouter;
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
      this.translate.onLangChange.subscribe(() => {
        this.translate.get('languages').subscribe((object: any) => {
          this.navbarTxt = object.navbar.gestionStatutsAffaire + ' ➜ ' + object.navbar.Ajouter;
          this.sharedMenuObserverService.updateMenu(this.navbarTxt);
        });
      });

      this.disableBtn = false;
    } else {
      this.translate.get('languages').subscribe((object: any) => {
        this.navbarTxt = object.navbar.gestionStatutsAffaire + ' ➜ ' + object.navbar.Modifier;
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
      this.translate.onLangChange.subscribe(() => {
        this.translate.get('languages').subscribe((object: any) => {
          this.navbarTxt = object.navbar.gestionStatutsAffaire + ' ➜ ' + object.navbar.Modifier;
          this.sharedMenuObserverService.updateMenu(this.navbarTxt);
        });
      });
    }
    this.chRef.detectChanges();

   this.getListRules();
  }

  initForm() {
    this.colorpo = this.statut_etat_affaire.couleur_police;
    this.colori = this.statut_etat_affaire.color;

    // if (this.statut_etat_affaire && this.contratData && this.serviceData) {
    //   this.getStatusAffaire(this.contratData?.id, this.serviceData?.id);
    // }
    if (this.statut_etat_affaire.sup === 1) {
      this.sup = true;
    } else {
      this.sup = false;
    }

    if (this.statut_etat_affaire.alert === 1) {
      this.alert = true;
    } else {
      this.alert = false;
    }
    if (this.statut_etat_affaire.agenda === 1) {
      this.agenda = true;
    } else {
      this.agenda = false;
    }

    if (this.statut_etat_affaire.ticket === 1) {
      this.ticket = true;
    } else {
      this.ticket = false;
    }

    if (this.statut_etat_affaire.fiche_dec === 1) {
      this.fiche_dec = true;
    } else {
      this.fiche_dec = false;
    }
  }

  getAll() {
    this.getCycleVieComm();
    this.getListWorkflow();
    this.getModelPost();
    this.getModelSms();
    this.getModelEmail();
    this.getListClasse();
    this.getService();
  }

  selectContrat(event) {
    // this.getDependence(event.id, '');
    if (this.serviceData?.id) {
      this.getStatusAffaire(event.id, this.serviceData?.id);
    }

    this.statusP = null;
    this.autoStatus.query = null;
    this.depand = null;
    this.autoDep.query = null;
    this.chRef.detectChanges();
  }

  selectService(event) {
    if (this.contratData?.id) {
      this.getStatusAffaire(this.contratData?.id, event.id);
    }
    this.statusP = null;
    this.autoStatus.query = null;
    this.depand = null;
    this.autoDep.query = null;
    this.chRef.detectChanges();
  }

  getService() {
    this.showVisa = true;
    const unsubscribeGetListServiceAffaire = this.apiAdminBpmServices.getListServiceAffaire().subscribe((data) => {
      this.service_cat = data.cat_status_aff_list.filter((element) => element.id !== null && element.libele !== null);
      this.serviceData = this.service_cat.find((element) => element.id === this.statut_etat_affaire?.categorie);
      this.showVisa = false;
      this.visa_cat = data.cat_status_aff_list.filter((element) => element.id !== null && element.libele !== null);
      this.visaData = this.visa_cat.find((element) => element.id === this.statut_etat_affaire?.visa);
      this.initForm();
    });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListServiceAffaire);
  }

  Enregistrer(valid, directive) {
    this.show_loading_add = true;
    this.list_invalid = directive.filter((element) => element.control.status === 'INVALID');
    if (!valid || !this.validForm) {
      this.alertWarning(this.alerts.formulaireinvalide);
      this.show_loading_add = false;
    } else if (this.depand?.id === this.statusP?.id && this.depand) {
      this.alertError(this.alerts.statutparentdiffdepend);
      this.show_loading_add = false;
    } else {
      this.show_loading_add = true;
      this.statut_etat_affaire.cycle_prod = this.contratData?.id;
      this.statut_etat_affaire.categorie =
        this.serviceData?.id !== undefined ? this.serviceData?.id : this.statut_etat_affaire?.categorie;
      this.statut_etat_affaire.id_contrat =
        this.contratData?.id !== undefined ? this.contratData?.id : this.statut_etat_affaire?.id_contrat;

      if (this.visaData?.id !== undefined) {
        this.statut_etat_affaire.visa = this.visaData.id;
      } else {
        this.statut_etat_affaire.visa = null;
      }

      if (this.depand?.id !== undefined) {
        this.statut_etat_affaire.dependance = this.depand.id;
      } else {
        this.statut_etat_affaire.dependance = null;
      }

      if (this.statusP?.id) {
        this.statut_etat_affaire.id_p = this.statusP.id;
      } else {
        this.statut_etat_affaire.id_p = null;
      }

      if (this.classeData?.id !== undefined) {
        this.statut_etat_affaire.classe = this.classeData.id;
      } // classe can not be null

      if (this.postData?.id !== undefined) {
        this.statut_etat_affaire.post_comm = this.postData.id;
      } else {
        this.statut_etat_affaire.post_comm = null;
      }

      if (this.mailData?.id !== undefined) {
        this.statut_etat_affaire.mail_comm = this.mailData.id;
      } else {
        this.statut_etat_affaire.mail_comm = null;
      }

      if (this.smsData?.id !== undefined) {
        this.statut_etat_affaire.sms_comm = this.smsData.id;
      } else {
        this.statut_etat_affaire.sms_comm = null;
      }

      if (this.sup === true) {
        this.statut_etat_affaire.sup = 1;
      } else {
        this.statut_etat_affaire.sup = 0;
      }

      if (this.agenda === true) {
        this.statut_etat_affaire.agenda = 1;
      } else {
        this.statut_etat_affaire.agenda = 0;
      }

      if (this.alert === true) {
        this.statut_etat_affaire.alert = 1;
      } else {
        this.statut_etat_affaire.alert = 0;
      }

      if (this.ticket === true) {
        this.statut_etat_affaire.ticket = 1;
      } else {
        this.statut_etat_affaire.ticket = 0;
      }

      if (this.fiche_dec === true) {
        this.statut_etat_affaire.fiche_dec = 1;
      } else {
        this.statut_etat_affaire.fiche_dec = 0;
      }

      if (this.colorCtr.touched && this.colori?.hex) {
        this.statut_etat_affaire.color = '#' + this.colori.hex;
      } else {
        this.statut_etat_affaire.color = this.colori;
      }
      if (this.colorCtrP.touched && this.colorpo?.hex) {
        this.statut_etat_affaire.couleur_police = '#' + this.colorpo.hex;
      } else {
        this.statut_etat_affaire.couleur_police = this.colorpo;
      }
      if (!this.statut_etat_affaire.color || !this.statut_etat_affaire.couleur_police) {
        this.alertError(this.alerts.couleurOblig);
        this.show_loading_add = false;
      } else {
        if (this.ModeAjout === true) {
          const unsubscribeCreateEtatAffaire = this.apiAdminBpmServices
            .createEtatAffaire(this.statut_etat_affaire)
            .subscribe(
              (Response) => {
                if (Response.message === 'etat_dossier inséré') {
                  const realId = Response.real_id;

               this.assignRulesToStatutAffaire(realId);

                   this.show_loading_add = false;
                   this.alertSuccess('Etat dossier inséré', Response.id);
                  if (this.detect_change_motif) {
                    let body: BodyMotif = new BodyMotif();
                    body.status = Response.id;
                    body.status = this.id_statut_affaire;
                    this.List_motif.forEach((element) => {
                      if (element.action === true) {
                        body.motifs_updated.push(element);
                      } else if (element.action === false) {
                        body.motifs_inserted.push(element);
                      }
                    });
                    this.apiAdminBpmServices.insertMotif(body).subscribe(
                      (data) => {
                        this.alertSuccess(this.alerts.etatDossierInsere, Response.id);
                        this.detect_change_motif = false;
                        this.show_loading_add = false;
                      },
                      () => {
                        this.detect_change_motif = false;
                        this.show_loading_add = false;
                        this.alertError(this.alerts.badHappened);
                      }
                    );
                  } else {
                    this.show_loading_add = false;
                    this.alertSuccess(this.alerts.etatDossierInsere, Response.id);
                  }
                } else {
                  this.show_loading_add = false;
                  this.alertError(this.alerts.badHappened);
                }
                this.show_loading_add = false;
              },
              () => {
                this.show_loading_add = false;
                this.alertError(this.alerts.error);
              }
            );
          this.listeOfUnsubscribeWebService.push(unsubscribeCreateEtatAffaire);
        } else {
          const unsubscribeUpdateEtatAffaire = this.apiAdminBpmServices
            .updateEtatAffaire(this.statut_etat_affaire)
            .subscribe(
              (Response) => {
                if (
                  Response.message === 'Etat_dossier mis a jour' ||
                  Response.message === 'Etat_dossier est deja a jour'
                ) {
                  this.assignRulesToStatutAffaire(Response.id);

                  // this.show_loading_add = false;
                  // this.router.navigate(['/gestion-companies']);
                  // this.alertSuccess('Etat dossier mis a jour', Response.id);
                  if (this.detect_change_motif) {
                    let body: BodyMotif = new BodyMotif();
                    body.status = this.id_statut_affaire;
                    this.List_motif.forEach((element) => {
                      if (element.action === true) {
                        body.motifs_updated.push(element);
                      } else if (element.action === false) {
                        body.motifs_inserted.push(element);
                      }
                    });

                    this.apiAdminBpmServices.insertMotif(body).subscribe(
                      (data) => {
                        this.alertSuccess(this.alerts.etatDossierUpdated, Response.id);
                        this.detect_change_motif = false;
                      },
                      (error) => {
                        this.show_loading_add = false;
                        this.detect_change_motif = false;

                        this.alertError(this.alerts.error);
                      }
                    );
                  } else {
                    this.show_loading_add = false;
                    this.alertSuccess(this.alerts.etatDossierUpdated, Response.id);
                  }
                } else {
                  this.show_loading_add = false;
                  this.alertError(this.alerts.error);
                }
                this.show_loading_add = false;
              },
              (error) => {
                this.show_loading_add = false;
                this.alertError(error.error.message);
              }
            );
          this.listeOfUnsubscribeWebService.push(unsubscribeUpdateEtatAffaire);
        }
      }
    }
  }






  assignRulesToStatutAffaire(statutAffaireId: number) {
    let data = { rules: [] };

    if (this.selectedValidationRule?.id) {
      data.rules.push({ id_rule: this.selectedValidationRule.id, id_entity: statutAffaireId });
    }

    if (this.selectedProcessingRule?.id) {
      data.rules.push({ id_rule: this.selectedProcessingRule.id, id_entity: statutAffaireId });
    }

    if (data.rules.length > 0) {
      this.apiAdminBpmServices.assignStatusAffaire(data).subscribe(
        (assignResponse) => {
          // Rules assigned successfully
          this.alertSuccess(this.alerts.operationSuccessful, statutAffaireId);
          this.show_loading_add = false;
        },
        (assignError) => {
          // Handle error in assigning rules
          this.show_loading_add = false;
          this.alertError(this.alerts.errorAssigningRules);
        }
      );
    } else {
      // No rules to assign, proceed as normal
      this.alertSuccess(this.alerts.operationSuccessful, statutAffaireId);
      this.show_loading_add = false;
    }
  }


  selectEtat(f) {
    this.controleService = f.value.controleService;

    if (this.controleService.Service === undefined || this.controleService.type === undefined) {
      this.contratOb = true;
      this.serviceOb = true;
    }
    // if (this.controleService.Service !== undefined && this.controleService.type !== undefined) {
    //   this.getStatusAffaire(this.controleService?.type?.id, this.controleService?.Service?.id, '');
    // }
  }

  selectDepan(f) {
    this.controleService = f.value.controleService;
    if (this.controleService.Service === undefined || this.controleService.type === undefined) {
      this.contratOb = true;
    }
  }

  clearForm() {
    if (!this.ModeAjout) {
      Object.assign(this.statut_etat_affaire, this.statut_etat_affaireInit);
      this.initForm();
      this.getAll();
    } else {
      this.statut_etat_affaire = new EtatAffaire();
      this.statusP = null;

      this.depand = null;
      this.contratData = null;
      this.serviceData = null;
      this.visaData = null;
      this.classeData = null;
      this.postData = null;
      this.mailData = null;
      this.smsData = null;

      this.autoStatus.query = null;
      this.autoContrat.query = null;
      this.autoDep.query = null;
      this.autoService.query = null;
      this.autoVisa.query = null;
      this.autoClasse.query = null;
      this.autoPost.query = null;
      this.autoMail.query = null;
      this.autoSMS.query = null;

      this.getAll();
      this.initForm();
    }
  }

  retourToList() {
    this.routes.navigate(['/gestion-status-affaire']);
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  alertWarning(data) {
    this.list_invalid_string = '';
    this.list_invalid.forEach((element) => {
      if (typeof element.name === 'string')
        this.list_invalid_string =
          this.list_invalid_string + '<li style="list-style-type: none; font-size: 14px">' + element.name + '</li>';
    });
    swal.fire({
      title: data,
      icon: 'warning',
      html: `<b> ${this.alerts.fillInputs}</b><br>` + '<ul>' + this.list_invalid_string + '</ul>',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#e75e5e',
    });
  }

  alertSuccess(data, id) {
    swal
      .fire({
        title: this.alerts.Successoperation,
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: true,
        denyButtonText: this.buttons.newEtatAffaire,
        denyButtonColor: '833626',
        cancelButtonText: this.buttons.updateEtatAffaire,
        confirmButtonText: this.buttons.listEtatAffaire,
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.hideloader();
          this.routes.navigate(['gestion-status-affaire']);
        }
        if (result.isDismissed) {
          this.hideloader();
          this.routes.navigate(['/gestion-status-affaire/fiche/' + id]);
        }
        if (result.isDenied) {
          this.hideloader();
          this.routes.navigate(['/gestion-status-affaire/fiche/nouveau']).then(() => {
            this.showloader();
          });
        }
      });
  }

  alertError(data) {
    swal.fire({
      title: this.alerts.error,
      text: data,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  getModelPost() {
    this.showPost = true;
    const unsubscribeListModelWithTypeCommGet = this.apiComminucationService
      .listModelWithTypeCommGet('C', 'O')
      .subscribe(
        (data: CommunicationModel[]) => {
          this.showPost = false;
          this.ModelPost = data;
          this.postData = this.ModelPost.find((element) => element.id === this.statut_etat_affaire?.post_comm);
        },
        (err) => {
          console.error(err);
        }
      );
    this.listeOfUnsubscribeWebService.push(unsubscribeListModelWithTypeCommGet);
  }

  getModelSms() {
    this.showSms = true;
    const unsubscribeListModelWithTypeCommGet = this.apiComminucationService
      .listModelWithTypeCommGet('S', 'O')
      .subscribe((data: CommunicationModel[]) => {
        this.showSms = false;
        this.ModelSms = data;
        this.smsData = this.ModelSms.find((element) => element.id === this.statut_etat_affaire?.sms_comm);
      });
    this.listeOfUnsubscribeWebService.push(unsubscribeListModelWithTypeCommGet);
  }

  getModelEmail() {
    this.showMail = true;
    const unsubscribeListModelWithTypeCommGet = this.apiComminucationService
      .listModelWithTypeCommGet('M', 'O')
      .subscribe(
        (data: CommunicationModel[]) => {
          this.showMail = false;
          this.ModelMail = data;
          this.mailData = this.ModelMail?.find((element) => element.id === this.statut_etat_affaire?.mail_comm);
        },
        (err) => {
          console.error(err);
        }
      );
    this.listeOfUnsubscribeWebService.push(unsubscribeListModelWithTypeCommGet);
  }

  getListClasse() {
    this.showClasse = true;
    const unsubscribeGetListClasse = this.apiAdminBpmServices.getListClasse().subscribe(
      (data) => {
        this.showClasse = false;
        this.classe_List = data.class_etas_list;
        this.classeData = this.classe_List.find((element) => element.id === this.statut_etat_affaire?.classe);
      },
      (err) => {
        console.error(err);
      }
    );
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListClasse);
  }

  /*   getListContrat() {
    const unsubscribeGetListContrat = this.apiProduitService.GetListContrat().subscribe((Response: Contrat[]) => {
      this.contrats = Response;
    }); this.listeOfUnsubscribeWebService.push(unsubscribeGetListContrat);
  } */

  getListWorkflow() {
    this.showWorkflow = true;
    const unsubscribeGetListContrat = this.apiProduitService.GetListWorkflow().subscribe((Response: any[]) => {
      this.contrats = Response;
      this.contratData = this.contrats.find((element) => element.id === this.statut_etat_affaire?.cycle_prod_md5);
      this.showWorkflow = false;
      // if (this.contratData?.id && this.statut_etat_affaire?.dependance ){
      //   this.getDependence(this.contratData?.id, this.statut_etat_affaire?.dependance);
      // }
      if (!this.ModeAjout) {
        setTimeout(() => {
          this.getStatusAffaire(this.contratData?.id, this.serviceData?.id);
        }, 2000);
      }
    });
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListContrat);
  }

  private _affecterfilterCycleVie(value: string): any[] {
    const filterValue = value?.toLowerCase();
    return this.contrats.filter((option) => option.libele?.toLowerCase().indexOf(filterValue) > -1);
  }

  public displayCycleVieFn(cyclevie?: any): string | undefined {
    this.cycleVie_affecte = cyclevie?.id;
    if (typeof cyclevie === 'string') {
      return cyclevie || null;
    } else {
      return cyclevie ? cyclevie.etat : null;
    }
  }

  getCycleVieComm() {
    this.listCycleVieFilter = this.affecterCycleFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value.libele)),
      map((value) => {
        return this._affecterfilterCycleVie(value);
      })
    );
  }

  getStatusAffaire(idContrat, idService) {
    this.showParent = true;
    const unsubscribeGetListStatusAffaire = this.apiAdminBpmServices
      .getListStatusAffaire(idContrat, idService)
      .subscribe(
        (data) => {
          this.statusP_List = data?.etat_dossier;
          this.depand_List = data.etat_dossier;
          if (!this.ModeAjout) {
            this.statusP_List = this.statusP_List.filter((item) => item.id !== this.id_statut_affaire);
            // this.depand_List = this.depand_List.filter(item => item.id !== this.id_statut_affaire);
          }

          this.showParent = false;
          this.disableBtn = false;
          const foundElement = this.statusP_List.find((element) => element.id === this.statut_etat_affaire?.id_p);
          this.statusP = foundElement?.id ? foundElement : null;
          const foundElementDepand = this.depand_List.find(
            (element) => element.id === this.statut_etat_affaire?.dependance
          );
          this.depand = foundElementDepand?.id ? foundElementDepand : null;
          this.chRef.detectChanges();
        },
        (err) => {
          console.error(err);
        }
      );
    this.listeOfUnsubscribeWebService.push(unsubscribeGetListStatusAffaire);
  }

  // getDependence(id, idDep) {
  //   this.showDepand = true;
  //   const unsubscribeGetEtatAffaireByContrat = this.apiAdminBpmServices.getEtatAffaireByworkflow(id).subscribe(
  //     (data) => {
  //       this.depand_List = data.etat_dossier;
  //       this.showDepand = false;
  //       if (this.ModeAjout === false) {
  //         this.depand = this.depand_List.find((element) => element.id === idDep);
  //       }
  //     },
  //     (err) => {
  //       console.error(err);
  //     }
  //   );
  //   this.listeOfUnsubscribeWebService.push(unsubscribeGetEtatAffaireByContrat);
  // }

  addMotif() {
    this.detect_change_motif = true;
    this.List_motif.push(new Motif());
  }

  deleteMotif(index: number) {
    if (index >= 0) {
      this.List_motif.splice(index, 1);
      this.detect_change_motif = true;
    }
  }

  getMotif(event: Motif, index) {
    this.detect_change_motif = true;
    if (event.id) {
      event.action = true;
    } else {
      event.action = false;
    }
    this.List_motif[index] = event;
  }

  validForm: boolean = true;

  getValidFormvalue(event) {
    this.validForm = event;
  }

  isActive(event, index) {
    this.detect_change_motif = true;
    this.List_motif[index].action = true;
    if (event.target.checked) {
      this.List_motif[index].active = 1;
    } else {
      this.List_motif[index].active = 0;
    }
  }

 getListRules() {
    const unsubscribeListCatTicketGet = this.apiAdminbpm.getListRules(this.page, this.limit, this.search).subscribe(
      (data) => {
        console.log('Received Rules Data: ', data); // Add this line to debug the response
        this.ListRule = data.rules;
        this.ListeValidationRules = this.ListRule.filter((rule) => rule.label_rule_type === 'Validation');
        this.ListeProcessingRules = this.ListRule.filter((rule) => rule.label_rule_type === 'Post processing');
      },
      (error) => {
        console.error('Error fetching rules: ', error); // Log any errors
      }
    );

    this.listeOfUnsubscribeWebService.push(unsubscribeListCatTicketGet);
  }
}

