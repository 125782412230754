<form (ngSubmit)="EnregistrerCompanie()" [formGroup]="myForm">
    <div class="card-head" *ngIf="!ModeAjout">
        <div class="row">
            <div class="col-9" style="margin-top: 8px;">
                <p>
                      {{"languages.companies.compagnieNum" | translate}}:
                    <label class="label-card-info">
            {{initialCompagnie.siren}}
          </label>
                </p>
                <p>
                      {{"languages.companies.Raisonsociale" | translate}}:
                    <label class="label-card-info">
            {{initialCompagnie.denomination}}
          </label>
                </p>
            </div>
            <div class="col-3 text-left">
                <p> {{"languages.companies.Immatriculation" | translate}} :
                    <label class="label-card-info">
            {{initialCompagnie.matricule}}
          </label>
                </p>
                <p>  {{"languages.listing.Type" | translate}}:
                    <label class="label-card-info">
            {{initialCompagnie.types}}
          </label>
                </p>
            </div>
        </div>
    </div>
    <div class="card-head text-center">
        <div class="row details-header">
            <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
                <div class="icon-position">
                    <i class="fa-solid fa-arrow-left fa-icon-style fa-lg"></i>
                </div>
            </button>
            <div class="row">
                <div class=" row details-spinner">
                    <div class="col">
                        <ng-container *ngIf="show_loading_add">
                            <div class="col-12">
                                <div class="spinner-style">
                                    <mat-spinner [diameter]="30"></mat-spinner>
                                </div>
                            </div>

                        </ng-container>
                    </div>
                    <div class="col" style="margin-right: 10px;">
                        <button type="button" class="btn-load" (click)="clearForm()">
              <i class="fa-solid fa-rotate-right"></i>
            </button>
                    </div>
                </div>
                <button class="btn-dt-save" type="submit" [disabled]="show_loading_add">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
           {{"languages.buttons.enregistrer" | translate}}
        </button>
            </div>
        </div>
    </div>
    <div class="card">

      <div class="row">
          <div class="col-12">
              <span style="color: red;">( * ) {{"languages.affaire.champsOblig" | translate}}</span>
          </div>
      </div>
      <hr>
      <mat-tab-group mat-align-tabs="center" style="z-index: 0;">
        <mat-tab label="{{'languages.companies.infoCompagnie' | translate}}">

            <div class="row mt-4">
                <div class="col-md-4 col-sm-12">
                    <div class="form-group" *ngIf="ModeAjout">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label> {{"languages.prospect.Identifiant" | translate}} </mat-label>
                            <input type="text" matInput [(ngModel)]="Compagnie.siren" (keypress)="numberlength($event)" name="siren" placeholder="123 456 789" formControlName="siren" required>
                            <mat-error *ngIf="(myForm.get('siren').dirty || myForm.get('siren').touched)">
                              <div *ngIf="myForm.get('siren').hasError('required')">{{"languages.prospect.identifiantOblig" | translate}} </div>
                              <div *ngIf="myForm.get('siren').hasError('pattern')">{{"languages.prospect.invalidIdentifiant" | translate}} </div>
                            </mat-error>
                          </mat-form-field>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <label>{{"languages.organisme.Logo" | translate}}</label>
                    <div class="row">
                        <div class="col-2 pt-1 px-3 border-right-0 border border-primary">
                            
                            <i class="fa-solid fa-image fa fa-2x pt-3 pl-0"></i>
                        </div>
                        <div class="col-8 pt-2 px-3 border-right-0 border-left-0 border border-primary">
                            <input type="file" id="idlogo" accept="image/*" (change)="getLogo($event)" hidden />
                            <label for="idlogo" class="select-label py-3 pl-4">
                                {{"languages.organisme.selectLogo" | translate}}
                </label>
                        </div>
                        <div class="col-2 pt-3 pl-3 border-left-0 border border-primary">
                            <img class="img" data-toggle="modal" data-target="#Actions" src="{{show_image}}">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label> {{"languages.companies.Immatriculation" | translate}}</mat-label>
                            <input matInput [(ngModel)]="Compagnie.matricule" type="number" name="numo" formControlName="numo">
                            <mat-error *ngIf="(myForm.get('numo').dirty || myForm.get('numo').touched)">
                              <div *ngIf="myForm.get('numo').hasError('required')"> {{"languages.companies.immatriOblig" | translate}}</div>
                            </mat-error>
                          </mat-form-field>

                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{"languages.companies.Raisonsociale" | translate}}</mat-label>
                            <input type="text" matInput [(ngModel)]="Compagnie.denomination" name="{{'languages.companies.Raisonsociale' | translate}}" formControlName="raison" required>
                            <mat-error *ngIf="(myForm.get('raison').dirty || myForm.get('raison').touched) ">
                                <div *ngIf="myForm.get('raison').hasError('required')">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.companies.raisonSocialOblig" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>


                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{"languages.listing.Type" | translate}}</mat-label>
                            <input matInput [(ngModel)]="Compagnie.types" formControlName="type" name="type" required>
                            <mat-error *ngIf="(myForm.get('type').dirty || myForm.get('type').touched) ">
                                <div *ngIf="myForm.get('type').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.companies.typeOblig" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>

            </div>
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <div class="form-group">

                        <app-auto-complete-adresses [reset]="reset"  [isReadOnlyAdresse]="true" (onChangeAdresse)="onChangeAdresseSaisieAuto($event)">
                        </app-auto-complete-adresses>
                    </div>
                </div>


                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{"languages.prospect.CodePostal" | translate}}</mat-label>
                            <input type="number" matInput [(ngModel)]="Compagnie.cp" formControlName="cp" name="cp">
                            <mat-error *ngIf="(myForm.get('cp').dirty || myForm.get('cp').touched) ">
                                <div *ngIf="myForm.get('cp').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.prospect.checkCodePostal" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>


                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{"languages.prospect.Ville" | translate}}</mat-label>
                            <input matInput [(ngModel)]="Compagnie.ville" formControlName="ville" name="ville">
                            <mat-error *ngIf="(myForm.get('ville').dirty || myForm.get('ville').touched) ">
                                <div *ngIf="myForm.get('ville').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.prospect.checkVille" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{"languages.prospect.Rue" | translate}}</mat-label>
                            <input matInput [(ngModel)]="Compagnie.streetName" formControlName="streetName" name="streetName">
                            <mat-error *ngIf="(myForm.get('streetName').dirty || myForm.get('streetName').touched) ">
                                <div *ngIf="myForm.get('streetName').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.prospect.check_rue_Oblig" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{"languages.prospect.streetNumber" | translate}}</mat-label>
                            <input matInput [(ngModel)]="Compagnie.streetNumber" formControlName="streetNumber" name="streetNumber">
                            <mat-error *ngIf="(myForm.get('streetNumber').dirty || myForm.get('streetNumber').touched) ">
                                <div *ngIf="myForm.get('streetNumber').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.prospect.check_Num_rue_Oblig" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{"languages.opportunite.details.link" | translate}}</mat-label>
                            <input matInput [(ngModel)]="Compagnie.url" formControlName="url"  name="url">
                            <mat-error *ngIf="(myForm.get('url').dirty || myForm.get('url').touched) ">
                                <div *ngIf="myForm.get('url').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.prospect.lienOblig" | translate}}
                                </div>
                                <div *ngIf="myForm.get('url').hasError('pattern')">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.prospect.check_Urlinvalid" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>
            </div>

            <div class="row">


                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label> {{"languages.companies.emailProduction" | translate}}</mat-label>
                            <input type="email" placeholder="exemple@email.com" matInput [(ngModel)]="Compagnie.mail_production" formControlName="mail_production"  name="mail_production">
                            <mat-error *ngIf="(myForm.get('mail_production').dirty || myForm.get('mail_production').touched) ">
                                <div *ngIf="myForm.get('mail_production').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.companies.productionEmailOblig" | translate}}
                                </div>
                                <div *ngIf="myForm.get('mail_production').hasError('email')">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.alerts.formatEmail" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width">
                            <mat-label>{{"languages.companies.emailCommercial" | translate}}</mat-label>
                            <input type="email" matInput placeholder="exemple@email.com" [(ngModel)]="Compagnie.mail_commercial"  name="mail_commercial" formControlName="mail_commercial" >
                            <mat-error *ngIf="(myForm.get('mail_commercial').dirty || myForm.get('mail_commercial').touched) ">
                                <div *ngIf="myForm.get('mail_commercial').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.companies.emailCommercialOblig" | translate}}
                                </div>
                                <div *ngIf="myForm.get('mail_commercial').hasError('email')">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.alerts.formatEmail" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>
                <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field appearance="standard" class="full-width" style="margin-top: -6px;">
                            <mat-label>{{"languages.companies.emailSAV" | translate}}</mat-label>
                            <input type="email" matInput placeholder="exemple@email.com"  [(ngModel)]="Compagnie.mail_sav" name="mail_sav" formControlName="mail_sav" >
                            <mat-error *ngIf="(myForm.get('mail_sav').dirty || myForm.get('mail_sav').touched) ">
                                <div *ngIf="myForm.get('mail_sav').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.companies.emailSavOblig" | translate}}
                                </div>
                                <div *ngIf="myForm.get('mail_sav').hasError('email')">
                                    <i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.alerts.formatEmail" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div>
            </div>
            <div class="row">



            <!--     <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field class="full-width" appearance="fill">
                            <label>{{"languages.companies.productionPhone" | translate}} </label>
                            <input (keypress)="numberOnly($event)" ng2TelInput matInput [(ngModel)]="Compagnie.tel_production" name="tel_production" formControlName="tel_production"  [ng2TelInputOptions]="{initialCountry: telCountry}" (ng2TelOutput)="getNumber($event,'telProd')">
                            <i class="fa-solid fa-phone-flip float-right"></i>
                            <mat-error *ngIf="(myForm.get('tel_production').dirty || myForm.get('tel_production').touched) ">
                                <div *ngIf="myForm.get('tel_production').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.companies.productionPhoneOblig" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div> -->
    <!--             <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field class="full-width" appearance="fill">
                            <label> {{"languages.companies.phoneSAV" | translate}}</label>
                            <input (keypress)="numberOnly($event)" ng2TelInput matInput [(ngModel)]="Compagnie.tel_sav" name="tel_sav" formControlName="tel_sav"  [ng2TelInputOptions]="{initialCountry: telCountry}" (ng2TelOutput)="getNumber($event,'telsav')">
                            <i class="fa-solid fa-phone-flip float-right"></i>
                            <mat-error *ngIf="(myForm.get('tel_sav').dirty || myForm.get('tel_sav').touched) ">
                                <div *ngIf="myForm.get('tel_sav').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.companies.phoneSAVoblig" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div> -->
          <!--       <div class="col-md-4 col-sm-12">
                    <div class="form-group">
                        <mat-form-field class="full-width" appearance="fill">
                            <label>{{"languages.companies.commercialPhone" | translate}}</label>
                            <input (keypress)="numberOnly($event)" ng2TelInput matInput [(ngModel)]="Compagnie.tel_commercial" name="tel_commercial" formControlName="tel_commercial"  [ng2TelInputOptions]="{initialCountry: telCountry}" (ng2TelOutput)="getNumber($event,'telcomm')">
                            <i class="fa-solid fa-phone-flip float-right"></i>
                            <mat-error *ngIf="(myForm.get('tel_commercial').dirty || myForm.get('tel_commercial').touched) ">
                                <div *ngIf="myForm.get('tel_commercial').hasError('required')">
<i class="fa-solid fa-triangle-exclamation"></i>
                                    {{"languages.companies.phoneCommercialOblig" | translate}}
                                </div>
                        </mat-error>
                        </mat-form-field>

                    </div>
                </div> -->
            </div>

        </mat-tab>
        <mat-tab label="{{'languages.document.Formulaire' | translate}}">

            <div class="mt-3">
                <formly-form [model]="model" [fields]="fields" [options]="options" [form]="form"></formly-form>
              </div>
        </mat-tab>
      </mat-tab-group>
    </div>


    <div class="modal fade" id="Actions" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog model-lg actions-dialog" role="document">
            <div class="modal-content full-width">
                <div class="modal-header">
                    <h2 style="margin-left: 15px"> {{"languages.affaire.logoCompagnie" | translate}}</h2>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <div class="row" style="justify-content: center;">
                        <img class="img" style="width: 200px; height: 200px;" src="{{show_image}}">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"> {{"languages.buttons.fermer" | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</form>
