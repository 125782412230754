import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';
import { OpportunityAdd } from '../../entity/Opportunity/OpportunityAdd';
import { InformationsSpecifique } from '../../entity/Opportunity/InformationsSpecifique';
import { FilterOpportunite } from '../../entity/Opportunity/FilterOpportunite';
import { DocumentsOpportunite } from '../../entity/Opportunity/DocumentsOpportunite';
import { InformationsComplmentaire } from '../../entity/Opportunity/InformationsComplmentaire';
import { FormContract } from 'src/app/entity/Opportunity/FormContract';
import { OpportunityAddVelo } from 'src/app/entity/Opportunity/Opportunity-Berilish/OpportunityAddBirilish';
import { BodyAction } from 'src/app/pages/affaires/affaire-details/affaire-details.component';

@Injectable()
export class ApiOpportunitService {
  private _listners = new Subject<any>();
  private _listners$ = new Subject<any>();
  private _listnersContrat = new Subject<any>();
  messageSubject = new Subject();
  protected basePath = environment.api_url;
  protected basePathTarificateur = environment.tarificateurBerilish;
  public defaultHeaders = new HttpHeaders();
  private configuration: Configuration;
  public formdatacomp: any = null;
  public formdataspecif: any = null;
  public formdataaffaircomp: any = null;
  public formdataaffairspecif: any = null;
  accesOpportunite$ = new BehaviorSubject<boolean>(null);
  protected SirenPath = environment.SirenPath;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    this.configuration = configuration;
    if (basePath) {
      this.basePath = basePath;
    }
  }

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  listenContrat(): Observable<any> {
    return this._listnersContrat.asObservable();
  }

  listenSpecif(): Observable<any> {
    return this._listners$.asObservable();
  }

  pushInfoCompl(filterBy: any) {
    this._listners.next(filterBy);
  }

  pushInfoSpecif(filterBy: any) {
    this._listners$.next(filterBy);
  }

  pushContratActual(filterBy: string) {
    this._listnersContrat.next(filterBy);
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Permet d&#x27;ajouter plusieur action avec un commentaire , une categorie action et une url a une opportunité codé en md5
   *
   * @param idOpp Identifiant de l&#x27;opportunite codé en md5
   * @param urlAction Respectivement les urls action séparér par des point virgule
   * @param tabNomAction les url des fichier action separer des virgule (doute)
   * @param catAction &lt;p&gt; libelle de categorie de l&#x27;action &lt;p&gt;
   * @param commentaireAction &lt;p&gt; Commentaire de l&#x27;action Champs optionnel &lt;br&gt; le commentaire va étre enregistré dans la base comme suit : &lt;br&gt; Modifications fiche avec ajout action : &#x27;commentaire_action&#x27; &lt;p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addActionGlobOppGet(
    idOpp: string,
    urlAction: string,
    tabNomAction: string,
    catAction: string,
    commentaireAction?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling addActionGlobOppGet.');
    }

    if (urlAction === null || urlAction === undefined) {
      throw new Error('Required parameter urlAction was null or undefined when calling addActionGlobOppGet.');
    }

    if (tabNomAction === null || tabNomAction === undefined) {
      throw new Error('Required parameter tabNomAction was null or undefined when calling addActionGlobOppGet.');
    }

    if (catAction === null || catAction === undefined) {
      throw new Error('Required parameter catAction was null or undefined when calling addActionGlobOppGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', idOpp as any);
    }
    if (urlAction !== undefined && urlAction !== null) {
      queryParameters = queryParameters.set('url_action', urlAction as any);
    }
    if (tabNomAction !== undefined && tabNomAction !== null) {
      queryParameters = queryParameters.set('tab_nom_action', tabNomAction as any);
    }
    if (commentaireAction !== undefined && commentaireAction !== null) {
      queryParameters = queryParameters.set('commentaire_action', commentaireAction as any);
    }
    if (catAction !== undefined && catAction !== null) {
      queryParameters = queryParameters.set('cat_action', catAction as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/add_action_glob_opp`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Permet d&#x27;ajouter un document et l&#x27;affecter a une opportunnite suivant son identifant codé en md5
   *
   * @param addDocument Identifiant de l&#x27;opportubite codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addDocumentOpportuniteGet(
    addDocument: DocumentsOpportunite,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (addDocument === null || addDocument === undefined) {
      throw new Error('Required parameter docOpp was null or undefined when calling addDocumentOpportuniteGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/add_document_opportunite?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: addDocument,
    });
  }

  /**
   * Permet d&#x27;ajouter un document et l&#x27;affecter a une opportunnite suivant son identifant codé en md5
   *
   * @param formData Identifiant de l&#x27;opportubite codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public uploadDocumentOpportunite(
    uploadFile: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (uploadFile === null || uploadFile === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling addDocumentOpportuniteGet.');
    }

    if (name === null || name === undefined) {
      throw new Error('Required parameter name was null or undefined when calling addDocumentOpportuniteGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/upload_document_opportunite?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: uploadFile,
    });
  }

  /**
   * Permet d&#x27;ajouter un document et l&#x27;affecter a une opportunnite suivant son identifant codé en md5
   *
   * @param formData Identifiant de l&#x27;opportubite codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public uploadDocumentOpportuniteA(
    uploadFile: any,
    accessToken: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (uploadFile === null || uploadFile === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling addDocumentOpportuniteGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    if (accessToken) {
      headers = headers.set('idSession', accessToken);
    } else {
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/upload_document_opportunite?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: uploadFile,
    });
  }

  /**
   * Permet d&#x27;ajouter un document et l&#x27;affecter a une opportunnite suivant son identifant codé en md5
   *
   * @param idOpp Identifiant de l&#x27;id opportunite codé en md5
   * @param idDoc Identifiant de l&#x27;documumet opportunite codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public loadDocumentOpportuniteGet(
    idOpp: string,
    idDoc: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling addDocumentOpportuniteGet.');
    }

    if (idDoc === null || idDoc === undefined) {
      throw new Error('Required parameter idDoc was null or undefined when calling addDocumentOpportuniteGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', idOpp);
    }
    if (idDoc !== undefined && idDoc !== null) {
      queryParameters = queryParameters.set('id_document', idDoc);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/load_list_document_opportunite?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Ajouter une relation et une action  entre deux opportunité
   *
   * @param idOpp1 identifiant de la deuxiéme opportunité codé en md5
   * @param idOpp2 Contient la clé API utilisateur (A demander par mail a it.sodedif.fr)
   * @param type type de relation entre les deux opportunité : 1 Dérivé 2 Remplacé
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addRelationOppGet(
    idOpp1: string,
    idOpp2: string,
    type: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idOpp1 === null || idOpp1 === undefined) {
      throw new Error('Required parameter idOpp1 was null or undefined when calling addRelationOppGet.');
    }

    if (idOpp2 === null || idOpp2 === undefined) {
      throw new Error('Required parameter idOpp2 was null or undefined when calling addRelationOppGet.');
    }

    if (type === null || type === undefined) {
      throw new Error('Required parameter type was null or undefined when calling addRelationOppGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idOpp1 !== undefined && idOpp1 !== null) {
      queryParameters = queryParameters.set('id_opp1', idOpp1 as any);
    }
    if (idOpp2 !== undefined && idOpp2 !== null) {
      queryParameters = queryParameters.set('id_opp2', idOpp2 as any);
    }
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', type as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/add_relation_opp`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Sert a affecter une  opportinuté a l’utilisateur connecté si la relation n’existe pas ou de supprimer l’affectation si elle  existe
   *
   * @param idOpp Identifiant de l’opportunité codé en  md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addSuiviOppGet(idOpp: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling addSuiviOppGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/add_suivi_opp/${idOpp}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * sert a mettre a jour le  champ object de l’opportunité
   *
   * @param idOpp Identifiant de l&#x27;opportunité codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createObjectOppGet(idOpp: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling createObjectOppGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', idOpp as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/create_object_opp`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Affiché l’alert (soit 0 soit 1)d’une etat  opportunité
   *
   * @param id identifiant de l&#x27;etat opportubité codé en md5 (a demander par email it@sodedif.com)
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAlertGet(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getAlertGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', id as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_alert`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public getProspectTelByOppId(
    id_opp: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_opp === null || id_opp === undefined) {
      throw new Error('Required parameter id_opp was null or undefined when calling getAlertGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id_opp !== undefined && id_opp !== null) {
      queryParameters = queryParameters.set('id_opp', id_opp as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_prospect_tel_by_opp`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Affiche les informations spécifiques liées à une opportunité ainsi que la form et les indications du contrat
   *
   * @param idOpp Identifiant de l'opportunité codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInfoSpecifiqueOpportuniteGet(
    idOpp: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling getCommentaireOpportuniteGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', idOpp as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_info_specifique`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Affiche les informations spécifiques liées à une opportunité ainsi que la form et les indications du contrat
   *
   * @param infoSpecifique
   * @param affaire_id Identifiant de l'affaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateInformationsSpecifique(
    affaire_id: string,
    infoSpecifique: InformationsSpecifique,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (infoSpecifique === null || infoSpecifique === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling getCommentaireOpportuniteGet.');
    }

    // const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (affaire_id !== undefined && affaire_id !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>affaire_id);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_info_specifique?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: infoSpecifique,
    });
  }

  /**
   * Affiche les informations spécifiques liées à une opportunité ainsi que la form et les indications du contrat
   *
   * @param contratActuel
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAncienContrat(
    contratActuel: FormContract,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (contratActuel === null || contratActuel === undefined) {
      throw new Error('Required parameter contratActuel was null or undefined when calling updateAncienContrat.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_ancien_contrat?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: contratActuel,
    });
  }

  public updateRendezVous(
    id_opp: string,
    rendezVous: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (rendezVous === null || rendezVous === undefined) {
      throw new Error('Required parameter rendezVous was null or undefined when calling updateRendezVous.');
    }
    if (id_opp === null || id_opp === undefined) {
      throw new Error('Required parameter id_opp was null or undefined when calling updateRendezVous.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/updaterdv/${id_opp}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: rendezVous,
    });
  }

  /**
   * Affiche les informations spécifiques liées à une opportunité ainsi que la form et les indications du contrat
   *
   * @param idOpp Identifiant de l'opportunité codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getInfoComplementairesOpportuniteGet(
    idOpp: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling getCommentaireOpportuniteGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', idOpp as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_info_complementaire`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Affiche les informations spécifiques liées à une opportunité ainsi que la form et les indications du contrat
   *
   * @param infoComplementaire Identifiant de l'opportunité codé en md5
   * @param affaire_id Identifiant de l'affaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateInformationsComplementaires(
    affaire_id: string,
    infoComplementaire: InformationsComplmentaire,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (infoComplementaire === null || infoComplementaire === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling getCommentaireOpportuniteGet.');
    }

    // const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (affaire_id !== undefined && affaire_id !== null) {
      queryParameters = queryParameters.set('id_affaire', <any>affaire_id);
    }
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_info_complementaire?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: infoComplementaire,
    });
  }

  /**
   * Permet d&#x27;afficher les information sur l&#x27; action  d&#x27;une opportunité suivant l&#x27;identifiant de l&#x27;opportunité
   *
   * @param idOpp Identifiant de l&#x27;opportunité codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getHistOppGet(idOpp: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling getHistOppGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', idOpp as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys.idSession && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_hist_opp`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Afficher l&#x27;identifaint de add_prospect a le quel l&#x27;opportunité est affecté
   *
   * @param id Identifiant de l&#x27;opportunité codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getOpportuniteGet(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getOpportuniteGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', id as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_opportunite`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * get la liste des tags
   *
   * @param active [Null, 0, 1] Null: return all Tags, 0: return inactive Tags, 1: return active Tags, <optional>
   */
  public getListTagsProspect(active?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (active !== undefined && active !== null) {
      queryParameters = queryParameters.set('status', active.toString());
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_tags_prospect`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public getListTagsOpportunite(
    active?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (active !== undefined && active !== null) {
      queryParameters = queryParameters.set('status', active.toString());
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_tags_opportunite`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Afficher les identifiants et les noms des documents suivant l&#x27;identifiant de l&#x27;opportunité code en md5
   *
  //  *  @param idOpp identifiant de l&#x27;opportunité codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listDocumentOpportuniteGet(
    idOpp: string,
    type: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling listDocumentOpportuniteGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', type);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/list_document_opportunite/${idOpp}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * List des opprtunité suivant des filtres
   *
   * @param filtresEndCreation filtres[endCreation] &#x3D;&#x3D;&gt; date depot de l&#x27;oppotunite
   */
  public listOpportunitesGet(
    filtres_opportunite: FilterOpportunite,
    page: number,
    limit: number,
    sSortDir_0?: string,
    iSortCol_0?: string,
    observe: any = 'body',
    @Optional() reportProgress: boolean = false
  ): Observable<any> {
    const sort_dict = {
      default: 2,
      '': 2,
      opportunite_date_depot: 2,
      organismes_nom: 3,
      villes_postal_code: 4,
      commercial_zone: 5,
      commerciaux_code: 6,
      opportunite_type_libelle: 7,
      opportunite_start: 8,
      prospects_surname: 9,
      etat: 10,
      campagnes_libelle: 13,
      users_nom: 14,
      opportunite_users_id: 15,
    };

    if (filtres_opportunite === null || filtres_opportunite === undefined) {
      throw new Error('Required parameter filtres_opportunite is null or undefined when calling listOpportunitesGet.');
    }

    if (page === null || page === undefined) {
      throw new Error('Required parameter page is null or undefined when calling listOpportunitesGet.');
    }

    if (limit === null || limit === undefined) {
      throw new Error('Required parameter limit is null or undefined when calling listOpportunitesGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const request_body = {
      all_filtre_opp: filtres_opportunite,
      page: page,
      limit: limit,
      sSortDir_0: sSortDir_0,
      iSortCol_0: sort_dict[iSortCol_0],
    };

    return this.httpClient.request<any>('post', `${this.basePath}/list_opportunites`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: request_body,
    });
  }

  /**
   * Select add_prospect selon cette id
   *
   * @param id_pp identifiant de l&#x27;opportunité codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.

   */
  public GetOpportunite(id_opp: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id_opp === null || id_opp === undefined) {
      throw new Error('Required parameter page was null or undefined when calling listOpportunitesGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id_opp !== undefined && id_opp !== null) {
      queryParameters = queryParameters.set('id_opp', id_opp as any);
    }
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_opportunite?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * select liste des types des rendez-vous
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListRDV(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/list_type_rdv?`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public getDetailsFilter(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_details_filtre`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * select liste des campagnes
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListCampagne(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/list_campagne?`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * select liste des status autorisé
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListStatusCreationByCampgneId(
    campagne_id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (campagne_id === null || campagne_id === undefined) {
      throw new Error('Required parameter campagne_id was null or undefined when calling listOpportunitesGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (campagne_id !== undefined && campagne_id !== null) {
      queryParameters = queryParameters.set('campagne_id', campagne_id as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_status_creation_by_cycle_vie?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public GetHistorique(id_opp: string): Observable<any> {
    if (id_opp === null || id_opp === undefined) {
      throw new Error('Required parameter page was null or undefined when calling listOpportunitesGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_hist_opp?id_opp=` + id_opp, {
      withCredentials: this.configuration.withCredentials,
      headers,
    });
  }

  /**
   * afficher les fils de status passé en parametre suivant un id de status parent
   *
   * @param idEtatParent identifiant de status de l&#x27;etat parent 1 Traitement 2 Annulation 3 Perte 4 Gain 5 Evaluation
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listactionGet(idEtatParent: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (idEtatParent === null || idEtatParent === undefined) {
      throw new Error('Required parameter idEtatParent was null or undefined when calling listactionGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idEtatParent !== undefined && idEtatParent !== null) {
      queryParameters = queryParameters.set('id_etat_parent', idEtatParent as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/listaction`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   *  sert a afficher les evenement et les opportunité de collaborateur pour le nombre des jours passé en  paramétre
   *
   * @param idCollab identifiant de collaborateur codé en md5
   * @param nbJour Nombre des jours a traiter
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheOppWithPropGet(
    idCollab: string,
    nbJour: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (idCollab === null || idCollab === undefined) {
      throw new Error('Required parameter idCollab was null or undefined when calling rechercheOppWithPropGet.');
    }

    if (nbJour === null || nbJour === undefined) {
      throw new Error('Required parameter nbJour was null or undefined when calling rechercheOppWithPropGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idCollab !== undefined && idCollab !== null) {
      queryParameters = queryParameters.set('id_collab', idCollab as any);
    }
    if (nbJour !== undefined && nbJour !== null) {
      queryParameters = queryParameters.set('nb_jour', nbJour as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/recherche_opp_with_prop`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Afficher l&#x27;objet de l&#x27;opportunité (enttité qui contients tous les informations sur l&#x27;opportunités)
   *
   * @param idOpp Identifiant de l&#x27;opportunité codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public selectObjOppGet(idOpp: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (idOpp === null || idOpp === undefined) {
      throw new Error('Required parameter idOpp was null or undefined when calling selectObjOppGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (idOpp !== undefined && idOpp !== null) {
      queryParameters = queryParameters.set('id_opp', idOpp as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/select_obj_opp`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  /**
   * Modifier la date de debut , fin ou le type de rdv
   *
   * @param id Identifiant de l&#x27;opportunite codé en md5
   * @param start Date de debut de rdv
   * @param end Date de fin de rdv
   * @param type type de rdv &lt;p&gt;Sans RDV : 4 &lt;br&gt;RDV : 4 &lt;br&gt;RDV_TEL : 4 &lt;br&gt;&lt;/p&gt;
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updaterdvGet(
    id: string,
    start: string,
    end: string,
    type: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updaterdvGet.');
    }

    if (start === null || start === undefined) {
      throw new Error('Required parameter start was null or undefined when calling updaterdvGet.');
    }

    if (end === null || end === undefined) {
      throw new Error('Required parameter end was null or undefined when calling updaterdvGet.');
    }

    if (type === null || type === undefined) {
      throw new Error('Required parameter type was null or undefined when calling updaterdvGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', id as any);
    }
    if (start !== undefined && start !== null) {
      queryParameters = queryParameters.set('start', start as any);
    }
    if (end !== undefined && end !== null) {
      queryParameters = queryParameters.set('end', end as any);
    }
    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', type as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/updaterdv`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public insertOpportunite(
    opportunity: OpportunityAdd,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (opportunity === null || opportunity === undefined) {
      throw new Error('Required parameter type was null or undefined when calling updaterdvGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    /* if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', <any>type);
    } */

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/insertopportunite`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: opportunity,
    });
  }

  public insertOpportuniteVelo(
    opportunity: OpportunityAddVelo,
    accessToken: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (opportunity === null || opportunity === undefined) {
      throw new Error('Required parameter type was null or undefined when calling updaterdvGet.');
    }

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (accessToken) {
      headers = headers.set('idSession', accessToken);
    } else {
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/insertopportunite`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: opportunity,
    });
  }

  public generateDevisFlotte(
    bodyGenrate: any,
    accessToken: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (bodyGenrate === null || bodyGenrate === undefined) {
      throw new Error('Required parameter bodyGenrate  was null or undefined when calling generateDevisFlotte.');
    }
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required

    if (accessToken) {
      headers = headers.set('idSession', accessToken);
    } else {
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('post', `${this.basePath}/generate_pdf`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: bodyGenrate,
    });
  }

  public generateDevis(bodyGenrate: any, accessToken: string): Observable<any> {
    let headers = this.defaultHeaders;
    headers = headers.set('apikey', '6c404ded-cbff-2d0-050-b6a76422fa0e');
    return this.httpClient.request<any>('post', environment.tarificateurBerilish + `/generate_devis`, {
      headers,
      body: bodyGenrate,
    });
  }

  public SendDevisFlotte(
    bodysend: any,
    accessToken: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (bodysend === null || bodysend === undefined) {
      throw new Error('Required parameter body send devis  was null or undefined when calling SendDevisFlotte.');
    }
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    if (accessToken) {
      headers = headers.set('idSession', accessToken);
    } else {
      if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
        headers = headers.set('idSession', this.configuration.apiKeys.idSession);
      }
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    return this.httpClient.request<any>('post', `${this.basePathTarificateur}/send-mail`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: bodysend,
    });
  }

  updateOpportuniteAction(
    id_opp: string,
    id_statut: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id_opp === null || id_opp === undefined) {
      throw new Error('Required parameter type was null or undefined when calling updaterdvGet.');
    }

    if (id_statut === null || id_statut === undefined) {
      throw new Error('Required parameter type was null or undefined when calling updaterdvGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (id_opp !== undefined && id_opp !== null) {
      queryParameters = queryParameters.set('id_opp', id_opp as any);
    }

    if (id_statut !== undefined && id_statut !== null) {
      queryParameters = queryParameters.set('id_statut', id_statut as any);
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_opportunite_action`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: {
        id_opp,
        id_statut,
      },
    });
  }

  updateaffaireAction(
    bodyAction: BodyAction,

    // json_object: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    /*    if (id_affaire === null || id_affaire === undefined) {
      throw new Error('Required parameter type was null or undefined when calling updaterdvGet.');
    }

    if (id_statut === null || id_statut === undefined) {
      throw new Error('Required parameter type was null or undefined when calling updaterdvGet.');
    } */

    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    /*    if (id_affaire !== undefined && id_affaire !== null) {
      queryParameters = queryParameters.set('id_affaire', id_affaire as any);
    }

    if (id_statut !== undefined && id_statut !== null) {
      queryParameters = queryParameters.set('id_statut', id_statut as any);
    }
 */
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_action_affaire`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
      body: bodyAction,
    });
  }

  public getAllGammes(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_list_gammes?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public getAllGammesV1(
    limit?: any,
    page?: any,
    search?: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.append('limit', <any>limit);
    queryParameters = queryParameters.append('page', <any>page);
    queryParameters = queryParameters.append('search', <any>search);
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/v1/get_list_gammes?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public getGammeById(gamme_id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_gamme_by_id/${gamme_id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public getGarantieByGamme(gamme_id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/garantie/list_garantie_by_gamme/${gamme_id}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public getGammeAutorise(id_organisme: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    queryParameters = queryParameters.set('id_organisme', id_organisme as any);

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/convention/get_gamme_autorise`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
    });
  }

  public InsertGamme(gamme: FormData, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/insert_gamme?`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: gamme,
    });
  }

  public UpdateGamme(
    Gamme: FormData,
    idGamme: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_gamme/${idGamme}`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: Gamme,
    });
  }

  /**
   * Permet de retourner les informations d'un etablissement par numéro du SIREN
   * @param siren
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetEstablishmentInformations(
    siren: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (siren === null || siren === undefined) {
      throw new Error('Required parameter siren was null or undefined when calling GetEstablishmentInformation.');
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.SirenPath}v3/unites_legales/${siren}`, {});
  }

  onChangeAccesOpportunite(): Observable<boolean> {
    return this.accesOpportunite$.asObservable();
  }

  public multipleActionOpportunite(
    statutBody: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    const queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('post', `${this.basePath}/multiple_action_opportunite`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: statutBody,
    });
  }

  /**
   * sert a reccuperer la liste des objet assurée par compagne
   *
   * @param id Identifiant de la compagne ou ligne de produit codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getObjecttassurer(
    id: string,
    type: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter idcompagne was null or undefined when calling createObjectOppGet.');
    }
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }
    if (id !== undefined && type === 'id_campagne') {
      queryParameters = queryParameters.set('id_compagne', id as any);
    }

    if (id !== undefined && type === 'ligne_produit') {
      queryParameters = queryParameters.set('id_ligne_produit', id as any);
    }
    return this.httpClient.request<any>('get', `${this.basePath}/get_objet_assuree_by_campagne_or_lp`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }

  public update_objet_assure_by_entity(
    id: any,
    type: any,
    Body: any,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter type was null or undefined when calling updaterdvGet.');
    }

    if (type === null || type === undefined) {
      throw new Error('Required parameter type was null or undefined when calling updaterdvGet.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });

    if (id !== undefined && id !== null) {
      queryParameters = queryParameters.set('id', id as any);
    }

    if (type !== undefined && type !== null) {
      queryParameters = queryParameters.set('type', type as any);
    }
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('put', `${this.basePath}/update_objet_assure_by_entity`, {
      params: queryParameters,
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress,
      body: Body,
    });
  }

  /**
   * sert a reccuperer la liste des objet assurée par opprotunite
   *
   * @param id Identifiant de l'opportunite ou affaire codé en md5
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public get_objet_assure_by_entity(
    id: string,
    type: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling createObjectOppGet.');
    }

    if (type === null || type === undefined) {
      throw new Error('Required parameter type was null or undefined when calling createObjectOppGet.');
    }

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys.idSession) {
      headers = headers.set('idSession', this.configuration.apiKeys.idSession);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<any>('get', `${this.basePath}/get_objet_assure_by_entity?id=${id}&type=${type}`, {
      withCredentials: this.configuration.withCredentials,
      headers,
      observe,
      reportProgress,
    });
  }
}
