<div class=" shadow-box-card-body"  style="background-color: #fff;">
  <div class=" row containerTache col-md-12 mb-3 "  >
    <div class="col-md-12"  id="expand" >
        <div  >


        <div class="row  m-2">
            <div class="card-doc-title ml-1 col-md-4">
                <i class="fa-solid fa-list-check mr-2 fa-lg"></i>
               {{'languages.ticket.myCreatedTasks' | translate}}
            </div>
            <label class=" col-md-4 ">
                <input type="search" class="search "    [ngModel]="search_create" (input)="onInputChange($event.target.value , 'crees')"  placeholder="{{'languages.miniListing.search' | translate}}" >
            </label>
            <button type="button" *ngIf="!isExpand" (click)="expandTask()" class="add-btn-en btn-details-add ">
              <i class="fa fa-plus-circle fa-2x" aria-hidden="true"></i>
            </button>
          </div>
          <div class="row d-flex justify-content-center">
            <p (click)="archiveCreeFN(false)" class=" py-3 px-5" [ngClass]="{'p-notif-false': !archiveCree, 'p-notif-true': archiveCree}">{{'languages.affaire.enCours' | translate}} </p>
            <p (click)="archiveCreeFN(true)" class="py-3 px-5" [ngClass]="{'p-notif-false': archiveCree, 'p-notif-true': !archiveCree}">{{'languages.affaire.Archive' | translate}} </p>
          </div>

        <div  class="row  ">
            
            <div  class="example-list w-100 mw-100 overflow-hidden  d-block mr-3 " >
                <div *ngIf="loader" class="col-12 d-flex justify-content-center text-secondary ">
                    <div class="spinner">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                </div>
                <div class="row pt-3" *ngIf="Tasks?.length < 1 &&!loader ">
                        <div class="col-12  d-flex justify-content-center">
                          <img src="assets/icons/nodata.svg">
                        </div>
                        <div class="col-12  d-flex justify-content-center">
                          <p class="noData">{{'languages.ticket.noTasks' | translate}}</p>
                        </div>
      
               
                </div>
               <div *ngIf="Tasks?.length > 0  && !loader">
                <div class="example-box row  px-2 py-2 flex-row justify-content-between align-items-center"   [ngStyle]="{ 'background-color': task.statut==0 ? '#fff' : '#F0F0F0' }" *ngFor="let task of Tasks"  >

                  <div class="col-4 d-flex align-items-center ">
                    <button type="button" *ngIf="task.statut==1" class="btnCheck statusbtnDone "  (click)="changerStatus(task ,'cree')" [attr.data-title]="'languages.ticket.startTask' | translate">
                        <i class="fa-solid fa-check btnTerminer"></i>
                    </button>
                    <button type="button" *ngIf="task.statut==0" class="btnCheck  statusfermer  " (click)="changerStatus(task,'cree')"  [attr.data-title]="'languages.ticket.endTask' | translate">
                        <i class="fa-solid fa-check "></i>
                    </button>
                    <span class="ml-4 taskName " (click)="viewModif(task , 'cree')"   [ngClass]="{'tasksDone': task.statut==1 }" >{{expandFileName(task.titre)}}</span>
                  </div>

                    <div class="col-7">
                        <span *ngFor="let tagsAll of tagsList">
                            <span *ngFor="let tags of task.tags" >
                                <span class="badge badge-primary  ml-2" [ngStyle]="{'color': tagsAll.couleur,'background-color': tagsAll.couleur_background}"  *ngIf="tagsAll.id==tags.id">{{tagsAll.libelle}}</span>
                            </span>
                        </span>
                       </div>
                       <div class="col-1">
                        <button  type="button"  class=" float-left btn-dt-list edit "  (click)="viewModif(task , 'cree')">
                          <img src="assets/icons/edit-icon.svg">
                        </button>


      <div class="notification"  *ngIf=" task.priorite == 3">
    <div class="bell-icon__circle"></div>
    </div>



                      </div>
                </div>
            </div>
            </div>
        </div>
 
      

       

</div>
</div>
<app-task-detail class=" col-md-4 " *ngIf="isExpand" [tagsList]="tagsList" [taskDetail]="taskDetail" [loaderAjout]="loaderAjout" (taskEmit)="ajoutTaches($event)" (inexpandEmit)="cancelAddOrUpdate($event)" [typeTache]="typeTache" [priorites]="priorites" [ListUser]="ListUser" [ListCLientProspect]="ListCLientProspect" ></app-task-detail>
<div  *ngIf="totalPages> 0 &&!loader" class=" col-12 d-flex align-items-center justify-content-center containerTache " >
    <nav aria-label="Page navigation example" *ngIf="Tasks?.length > 0">
        <ul class="pagination pg-blue">
            <li (click)="first()" class="page-item" [ngClass]="{'disabled':isFirst()}">
                <a class="page-link-symbol">
                    <span aria-hidden="true">&laquo;</span>
                </a>
            </li>
            <li *ngIf="!isFirst()" (click)="prev()" class="page-item mx-2">
                <a class="page-link-symbol-chevron" aria-label="Previous">
                    <i class="fa-solid fa-chevron-left"></i>
                </a>
            </li>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
                <li *ngIf="i+page-3>0" class="page-item">
                    <a (click)="changepage(i+page-3)" class="page-link mt-2 border rounded">{{i + page -
                        3}}</a>
                </li>
            </ng-container>
            <ng-container *ngFor="let item of [].constructor(3); let i = index">
                <li *ngIf="i+page<totalPages" class="page-item">
                    <a (click)="changepage(i+page)" class="page-link mt-2 border rounded"
                        [ngClass]="{'active-page':page==(i+page)}">
                        {{i + page}}
                    </a>
                </li>
            </ng-container>
            <li *ngIf="totalPages>1 && totalPages > 3 " class="m-2"> ... </li>
            <li class="page-item">
                <a (click)="changepage(totalPages)" class="page-link mt-2 border rounded"
                    [ngClass]="{'active-page':page==totalPages}">{{totalPages}}</a>
            </li>
            <li *ngIf="!isLast()" (click)="next()" class="page-item mx-2">
                <a class="page-link-symbol-chevron" aria-label="Next">
                    <i class="fa-solid fa-chevron-right"></i>
                </a>
            </li>
            <li (click)="last()" class="page-item" [ngClass]="{'disabled':isLast()}">
                <a class="page-link-symbol">
                    <span aria-hidden="true">&raquo;</span>
                </a>
            </li>
        </ul>
    </nav>
    </div>
</div>

<div class=" row containerTache col-md-12 "  style="border-radius: 10px 10px 0px 0px;">
    <div class="col-md-12"  id="expand2" >
        <div class="row m-2 ">
            <div class="card-doc-title ml-1 col-md-4  ">
                <i class="fa-solid fa-list-check mr-2 fa-lg"></i>
                 {{'languages.ticket.myAffectedTasks' | translate}}
              </div>
              <label class=" col-md-4 ">
                <input type="search" class="search "  [ngModel]="search_affect" (input)="onInputChange($event.target.value , 'affectees')" placeholder="{{'languages.miniListing.search' | translate}}" >
            </label>
        </div>
        <div class="row d-flex justify-content-center">
            <p (click)="archiveAffecteFN(false)" class=" py-3 px-5" [ngClass]="{'p-notif-false': !archiveAffecte, 'p-notif-true': archiveAffecte}">{{'languages.affaire.enCours' | translate}}</p>
            <p (click)="archiveAffecteFN(true)" class="py-3   px-5" [ngClass]="{'p-notif-false': archiveAffecte, 'p-notif-true': !archiveAffecte}">{{'languages.affaire.Archive' | translate}}</p>
          </div>

           
                    <div class="row">
            <div   class="example-list  w-100 mw-100 overflow-hidden d-block mr-3 "  >
                <div *ngIf="loaderAffect" class="col-12 d-flex justify-content-center text-secondary ">
                    <div  class="spinner " >
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </div>
                </div>
                <div class="row pt-3" *ngIf="Tasksaffect?.length < 1  &&!loaderAffect">
                    <div class="col-12  d-flex justify-content-center">
                        <img src="assets/icons/nodata.svg">
                      </div>
                      <div class="col-12  d-flex justify-content-center">
                        <p class="noData">{{'languages.ticket.noTasks' | translate}}</p>
                      </div>
                </div>
                <div *ngIf="Tasksaffect?.length > 0  &&!loaderAffect">
                <div class="example-box row  px-2 py-2 flex-row justify-content-between align-items-center"   [ngStyle]="{ 'background-color': task.statut==0 ? '#fff' : '#F0F0F0'  }" *ngFor="let task of Tasksaffect" >
                  <div class="col-4 d-flex align-items-center">
                    <button type="button" *ngIf="task.statut==1" class="btnCheck statusbtnDone "     (click)="changerStatus(task,'affect')"  [attr.data-title]="'languages.ticket.startTask' | translate">
                        <i class="fa-solid fa-check btnTerminer" ></i>
                    </button>
                    <button type="button" *ngIf="task.statut==0" class="btnCheck statusfermer  " (click)="changerStatus(task,'affect')"   [attr.data-title]="'languages.ticket.endTask' | translate">
                        <i class="fa-solid fa-check"></i>
                    </button>
                    <span class=" taskName ml-4" (click)="viewModif(task,'affecte')" [ngClass]="{'tasksDone': task.statut==1 }">{{expandFileName(task.titre)}}</span>
                     </div>
                     <div class="col-7" >
                      <span *ngFor="let tagsAll of tagsList">
                        <span *ngFor="let tags of task.tags" >
                            <span class="badge badge-primary  ml-2" [ngStyle]="{'color': tagsAll.couleur,'background-color': tagsAll.couleur_background}"  *ngIf="tagsAll.id==tags.id">{{tagsAll.libelle}}</span>
                    </span>
                </span>
                    </div>
                    <div class="col-1" >
                     
                      <button  type="button" class="  float-left btn-dt-list edit " (click)="viewModif(task,'affecte')" >
                        <img src="assets/icons/edit-icon.svg">
                      </button>
                      <div *ngIf=" task.priorite == 3">
                        <div class="notification"></div>
                      <div class="bell-icon__circle"></div>
                      </div>
                    </div>



                </div>
            </div>
            </div></div>
        
      
     
       
      
    </div>
    <app-task-detail class="col-md-4 " *ngIf="isExpandAffect" [tagsList]="tagsList" [taskDetail]="taskDetail" [loaderAjout]="loaderAjout" (taskEmit)="ajoutTaches($event)" (inexpandEmit)="cancelAddOrUpdate($event)" [typeTache]="typeTache" [priorites]="priorites" [ListUser]="ListUser" [ListCLientProspect]="ListCLientProspect" ></app-task-detail>
    
    </div>  
    <div  *ngIf="totalPagesAffect> 0 &&!loaderAffect" class="d-flex align-items-center justify-content-center containerTache" style="border-radius: 0px 0px 10px 10px;">

        <nav aria-label="Page navigation example" *ngIf="Tasksaffect?.length > 0" >
          <ul class="pagination pg-blue">
              <li (click)="firstAffect()" class="page-item" [ngClass]="{'disabled':isFirstAffect()}">
                  <a class="page-link-symbol">
                      <span aria-hidden="true">&laquo;</span>
                  </a>
              </li>
              <li *ngIf="!isFirstAffect()" (click)="prevAffect()" class="page-item mx-2">
                  <a class="page-link-symbol-chevron" aria-label="Previous">
                      <i class="fa-solid fa-chevron-left"></i>
                  </a>
              </li>
              <ng-container *ngFor="let item of [].constructor(3); let i = index">
                  <li *ngIf="i+pageAffect-3>0" class="page-item">
                      <a (click)="changepageAffect(i+pageAffect-3)" class="page-link mt-2 border rounded">{{i + pageAffect -
                          3}}</a>
                  </li>
              </ng-container>
              <ng-container *ngFor="let item of [].constructor(3); let i = index">
                  <li *ngIf="i+pageAffect<totalPagesAffect" class="page-item">
                      <a (click)="changepageAffect(i+pageAffect)" class="page-link mt-2 border rounded"
                          [ngClass]="{'active-page':pageAffect==(i+pageAffect)}">
                          {{i + pageAffect}}
                      </a>
                  </li>
              </ng-container>
              <li *ngIf="totalPagesAffect>1 && totalPagesAffect > 3 " class="m-2"> ... </li>
              <li class="page-item">
                  <a (click)="changepageAffect(totalPagesAffect)" class="page-link mt-2 border rounded"
                      [ngClass]="{'active-page':pageAffect==totalPagesAffect}">{{totalPagesAffect}}</a>
              </li>
              <li *ngIf="!isLastAffect()" (click)="nextAffect()" class="page-item mx-2">
                  <a class="page-link-symbol-chevron" aria-label="Next">
                      <i class="fa-solid fa-chevron-right"></i>
                  </a>
              </li>
              <li (click)="lastAffect()" class="page-item" [ngClass]="{'disabled':isLastAffect()}">
                  <a class="page-link-symbol">
                      <span aria-hidden="true">&raquo;</span>
                  </a>
              </li>
          </ul>
      </nav>
      </div>
</div>


